import { RxCross1 } from "react-icons/rx";
import { UsersList } from "../../../../components/usersList.js";
import { Combobox, Transition } from "@headlessui/react";
import { useRef, useState } from "react";

export const CompanyCategories = ({
	categories,
	data,
	handleChange,
	setData,
}) => {
	const [value, setValue] = useState("");
	const handleCategoryDelete = (category) => {
		setValue("");
        setData({
            ...data,
            categories: data?.categories?.filter((cat) => category?.id !== cat?.id),
        });
	};
	return (
		<div className="flex flex-col gap-y-5 w-1/2">
			<div className="flex flex-col gap-y-2 z-20 w-1/2">
				<label className="text-orange-500 text-sm font-light">
					Select Multiple Categories
				</label>
				<Combobox value={value} onChange={(e) => handleChange(e, true)}>
					<div className="relative">
						<div className="flex justify-center">
							<Combobox.Input className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"></Combobox.Input>
						</div>
						<Transition
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Combobox.Options className="absolute mt-1 max-h-40 w-full overflow-auto ounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
								{categories
									?.filter((cat) => {
										return !data?.categories
											?.map((c) => c?.name)
											?.includes(cat?.name);
									})
									?.map((person, personIdx) => (
										<Combobox.Option
											key={personIdx}
											className={({ active }) =>
												`relative cursor-default select-none py-2 pl-10 pr-4 ${
													active ? "bg-orange-600 text-white" : "text-gray-900"
												}`
											}
											value={person?.name}
										>
											{({ selected }) => (
												<>
													<span
														className={`block truncate ${
															selected ? "font-medium" : "font-normal"
														}`}
													>
														{person.name}
													</span>
													{selected ? (
														<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
													) : null}
												</>
											)}
										</Combobox.Option>
									))}
							</Combobox.Options>
						</Transition>
					</div>
				</Combobox>
			</div>
			{(data?.categories || [])?.length > 0 && (
				<div className="gap-y-3 flex flex-col">
					<label className="text-orange-500 font-light">
						Selected Categories :{" "}
					</label>
					<div className="flex flex-wrap flex-start gap-3">
						{(data?.categories || [])?.map((cat) => {
							return (
								<div
									className={`text-xs flex justify-between gap-x-2 cursor-pointer shrink-0 border rounded-xl shadow-md p-2 text-center`}
								>
									{cat?.name}
									<button
										onClick={() => {
											handleCategoryDelete(cat);
										}}
										className="text-red-600"
										type="button"
									>
										<RxCross1 color={"red"} size={12} />
									</button>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};
