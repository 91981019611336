import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../../redux/users.ts";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../../components/loader/index.js";
import { Pagination } from "../../../components/pagination/index.js";
import { FilterBtn } from "../../../components/filterBtn/index.js";
import { FilterField } from "../../../components/filterField/index.js";
import { Unavailable } from "../../../components/noData/index.js";
import { convertDateToWords } from "../../../helper/dateFormat.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const OverallUsers = () => {
  const [params, setParams] = useState({ status: 1 });
  const navigate = useNavigate();
  const { questions, isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (questions?.current_page) {
      dispatch(fetchTransactions({ params: { ...params, page: questions?.current_page } }));
    } else {
      dispatch(fetchTransactions({ params: params }));
    }
  }, []);

  function pageChange(page) {
    dispatch(fetchTransactions({ params: { ...params, page: page } }));
  }

  const handleClearDate = () => {
    let param = { ...params, endDate: "", startDate: "" };
    setParams(param);
    dispatch(fetchTransactions({ params: param }));
  }

  const handleClear = () => {
    let param = {};
    setParams(param);
    dispatch(fetchTransactions({ params: param }));
  }

  const handleSearch = () => dispatch(fetchTransactions({ params: params }));

  return (
    <>
    <Breadcrumbs breadcrumbs={[{name: "Questions List", link: ""}]} />
    <div className="relative mx-12">
      <div className="flex justify-start items-center space-x-5 my-2">
        <FilterField
          param={params}
          setParam={setParams}
          type={"date range"}
        />
        <div className="mt-7">
          <FilterBtn 
            func={handleClearDate}
            disabled={!params?.startDate && !params?.endDate}
            text={"Clear"}
            color={"red"}
          />
        </div>
        <FilterField
          param={params}
          setParam={setParams}
          type={"name"}
        />
        <div className="mt-7">
          <FilterBtn 
              func={handleClear}
              disabled={!params?.name && !params?.startDate && !params?.endDate}
              text={"Clear"}
              color={"red"}
            />
        </div>
        <div className="mt-7">
          <FilterBtn 
            func={handleSearch}
            disabled={!params?.name && (!params?.startDate || !params?.endDate)}
          />
        </div>
      </div>
      <div className="h-[calc(100vh-300px)] overflow-auto">
        <table className="w-full text-sm text-left text-gray-500 border border-black">
				  <thead className="text-sm text-orange-500 uppercase bg-gray-100 border border-black">
            <tr>
              <th scope="col" className="px-6 py-3 text-center">
                #
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Questioner
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Submission Date
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Action
              </th>
            </tr>
          </thead>
          {isLoading?.transactions ? (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Loader w="20" h="20" color={"orange"} />
            </div>
          ) : (
            <tbody>
              <Unavailable data={questions} />
              {questions?.data?.map((question, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 text-center">
                      {questions?.from + index}
                    </td>
                    <td className="px-6 py-4 text-center">
                      <button
                        onClick={() => navigate(`/users/question-details/${question?.id}`)}
                        className="font-[700]"
                      >
                        {question?.users?.firstname +
                          " " +
                          question?.users?.lastname}
                      </button>{" "}
                      has posted a question{" "}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {convertDateToWords(question?.created_at?.slice(0, 10))}
                    </td>
                    <td className="px-6 py-4 text-center hover:text-blue-600">
                      <button
                        onClick={() =>
                          navigate(`/users/question-details/${question?.id}`)
                        }
                        className="py-2 text-blue-600 border-2 rounded-lg px-2 border-blue-600 bg-red hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <Pagination
        total={questions?.last_page}
        current={questions?.current_page}
        func={pageChange}
      />
    </div>
    </>
  );
};
