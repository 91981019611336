import { useEffect, useRef, useState } from "react";
import { AddBtn } from "../../../components/addBtn";
import { Breadcrumbs } from "../../../components/breadcrumbs";
import DraftEditor from "../../../components/DraftEditor";
import { FaCrown } from "react-icons/fa";
import { UsersList } from "../../../components/usersList.js";
import { RxCross1 } from "react-icons/rx";
import { toastNotification } from "../../../services/notification-service";
import { useDispatch, useSelector } from "react-redux";
import { editUpdateCompany, fetchCompanies } from "../../../redux/jobsSlice.ts";
import { isEmpty } from "../../../helper/join.js";
import { Loader } from "../../../components/loader/index.js";
import { slugify } from "../../../helper/paramString.js";
import back from "../../../assets/svg/back.svg";
import { CompanyTitle } from "./companyTitle/index.jsx";
import DeleteModal from "../../../components/deleteModal/index.js";
import { CompanyProfile } from "./companyProfile/index.jsx";
import { removeEmptyString } from "../../../helper/removeEmpty.js";
import { CompanyCategories } from "./companyCategories/index.jsx";
import { FileAdd } from "./fileAdd/index.jsx";
import AttachmentModal from "./AttachmentModal/index.jsx";
import { IoMdCopy } from "react-icons/io";
import { encryptfunction } from "../../../helper/join.js";
import { useLocation } from "react-router-dom";
import { CiExport } from "react-icons/ci";

export const Companies = () => {
	const [draft, setDraft] = useState(null);
	const [register, setRegister] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const [userList, setUserList] = useState([]);
	const [owner, setOwner] = useState([]);
	const [data, setData] = useState({});
	const [val, setVal] = useState();
	const [selectedCompany, handleSelectedCompany] = useState(null);
	const dispatch = useDispatch();
	const { companies, companyDetail, isLoading, companyCategories } =
		useSelector((store) => store.jobs);
	const deleteRef = useRef(null);
	const popupRef = useRef(null);
	const location = useLocation();
	const is_original = location.pathname?.includes("/companies/all");

	useEffect(() => {
		if (companyDetail?.id) {
			setData({ ...companyDetail, profile_image: companyDetail?.logo_url });
			setDraft(companyDetail?.history);
			setUserList(
				(companyDetail?.users || [])?.map((use) => {
					return {
						id: use?.id,
						userName: `${use?.firstname} ${use?.lastname}`,
					};
				})
			);
			setOwner(companyDetail?.owners);
		}
	}, [companyDetail?.id, isLoading?.companyInfo]);

	const handleChange = (e, category) => {
		if (category) {
			const cat_obj = companyCategories?.find((cat) => cat?.name === e);
			if (cat_obj) {
				setData({
					...data,
					categories: [...(data?.categories || []), cat_obj],
				});
			}
			return;
		}
		if (e.target?.type === "file") {
			const files = e.target.files;
			if (!files?.[0]?.type?.includes("image")) {
				toastNotification({ status: "error", message: "Upload image only!" });
			} else if (files?.[0]?.size > 1024000) {
				toastNotification({
					status: "error",
					message: "Image must be less then 1mb!",
				});
			} else {
				setData({ ...data, [e.target.name]: files[0] });
			}
			return;
		}
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const entries = [
		{ type: "text", name: "address", text: "Company Address" },
		{ type: "text", name: "postal_code", text: "Postal Code" },
		{ type: "text", name: "city", text: "City" },
		{ type: "text", name: "state", text: "State" },
		{ type: "text", name: "country", text: "Country" },
		{ type: "url", name: "linked_in_profile", text: "LinkedIn Profile Url" },
	];

	useEffect(() => {
		if (userInfo && userInfo?.userId) {
			setUserList([
				...userList,
				{ id: userInfo?.userId, userName: userInfo?.userName },
			]);
		}
	}, [userInfo]);

	useEffect(() => {
		ClearAll();
	}, [is_original]);

	useEffect(() => {
		dispatch(fetchCompanies());
		ClearAll();
	}, []);

	const handleOwner = (id) => {
		if (owner?.includes?.(id)) {
			setOwner(owner?.filter((own) => own !== id));
		} else {
			setOwner([...owner, id]);
		}
	};

	const ClearAll = () => {
		setData({});
		setOwner([]);
		setUserList([]);
		setDraft(null);
		setRegister(false);
		handleSelectedCompany(null);
	};

	const handleSubmit = async () => {
		const employees = userList
			?.filter((use) => !owner?.includes(use?.id))
			?.map((use) => use?.id);
		let dataToSend = {
			...data,
			owners: owner,
			employees: employees,
			history: draft,
		};
		if (dataToSend?.profile_image) {
			if (typeof dataToSend?.profile_image === "string") {
				delete dataToSend["profile_image"];
			}
		}
		if ((dataToSend?.title || "")?.length < 3) {
			toastNotification({status: "error", message: "Company Title must be atleast three letters!"})
			return;
		}
		if (register) {
			dataToSend = {
				...dataToSend,
				slug: slugify(dataToSend?.title),
				is_deleted: false,
			};
		}
		if (!dataToSend?.slug) {
			dataToSend = { ...dataToSend, slug: slugify(dataToSend?.title) };
		}
		setVal("add");
		dataToSend = removeEmptyString(dataToSend);
		const formData = new FormData();
		Object.keys(dataToSend).forEach((key) => {
			if (key === "owners" || key === "employees") {
				(dataToSend[key] || [])?.map((dat, index) => {
					formData.append(`${key}[${index}]`, dat);
				});
				return;
			}
			if (key === "categories") {
				(dataToSend[key] || [])?.map((dat, index) => {
					formData.append(`${key}[${index}]`, dat?.id);
				});
				return;
			}
			formData.append(key, dataToSend[key]);
		});
		if (is_original) {
			formData.append("is_original", true);
		}
		dispatch(editUpdateCompany({ formData }, () => ClearAll()));
	};

	const handleDeleteCompany = (id) => (dispatch) => {
		const formData = new FormData();
		formData.append("id", id);
		formData.append("is_deleted", true);
		dispatch(editUpdateCompany({ formData }, () => ClearAll(), id));
	};

	const handleDelete = (id) => {
		deleteRef?.current?.open({
			id: id,
			deleteFun: handleDeleteCompany,
			type: "Company",
		});
	};

	const copyTextToClipboard = async (text) => {
		try {
			await navigator.clipboard.writeText(text);
			toastNotification({ status: "success", message: "Url Copied!" });
		} catch (err) {
			console.error("Failed to copy text: ", err);
		}
	};

	return (
		<>
			<Breadcrumbs
				breadcrumbs={
					is_original
						? [{ name: "Companies", link: "" }]
						: [
								{ name: "Jobs", link: "/jobs/view-all" },
								{ name: "Companies", link: "" },
						  ]
				}
			/>
			<AttachmentModal ref={popupRef} setData={setData} data={data} />
			<DeleteModal ref={deleteRef} />
			{isEmpty(companies) ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader w="20" h="20" color="orange" />
				</div>
			) : (
				<div
					className="relative mx-12 flex flex-col rounded-lg mb-20 pb-20 shadow-lg p-5"
				>
					{register ? (
						<>
							<button
								onClick={() => setRegister(false)}
								className="flex justify-start gap-x-3 text-orange-500 items-center"
							>
								<img src={back} className="w-6" /> Back
							</button>
							<div className="text-orange-600 text-lg mt-8">
								Register Company :
							</div>
						</>
					) : (
						<div className="flex justify-between items-center w-full">
							<AddBtn
								text={"Register Company"}
								func={() => {
									ClearAll();
									setRegister(!register);
								}}
							/>
							{is_original && (
								<a
									href={`${process.env.REACT_APP_SERVER_URL}/api/export-companies?url_key=${process.env.REACT_APP_URL}`}
									target="_blank"
									rel="noreferrer"
									className={`shrink-0 py-2 transition-all duration-200 ease-in-out text-sm gap-x-3 px-3 text-white bg-orange-600 rounded-full flex justify-center hover:opacity-70 hover:scale-105`}
								>
									<CiExport size={20} />
									Export
								</a>
							)}
						</div>
					)}
					<div className="mt-12 flex flex-col gap-y-10">
						{!register && (
							<CompanyTitle
								selectedCompany={selectedCompany}
								handleSelectedCompany={handleSelectedCompany}
								companies={companies}
								is_original={is_original}
							/>
						)}
						{isLoading?.companyInfo ? (
							<div className="flex justify-center items-center">
								<Loader w="20" h="20" color="orange" />
							</div>
						) : (
							(data?.id || register) && (
								<>
									<div className="flex justify-between w-full">
										<div className="space-y-3">
											<label className="font-light">Company Name</label>
											<input
												type="text"
												name="title"
												onChange={handleChange}
												value={data?.title || ""}
												placeholder="Type Company Name.."
												required
												className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
											/>
										</div>
										{(data?.attachments || [])?.length < 10 &&
											data?.id &&
											is_original && (
												<AddBtn
													text={"Add Attachment"}
													func={() =>
														popupRef.current.open({
															data: { company_id: data?.id },
															status: "add",
														})
													}
												/>
											)}
									</div>
									{data?.id && is_original && (
										<div className="border w-max rounded-md py-1 px-2 flex justify-center items-center divide-x-2 gap-x-2 divide-gray-200">
											<a href={`${
												process.env.REACT_APP_URL
											}/company-detail/${encryptfunction(data?.id)}`} 
											target="_blank"
											rel="noopener noreferrer"
											className="text-sm text-gray-600">{`${
												process.env.REACT_APP_URL
											}/company-detail/${encryptfunction(data?.id)}`}</a>
											<IoMdCopy
												onClick={() =>
													copyTextToClipboard(
														`${
															process.env.REACT_APP_URL
														}/company-detail/${encryptfunction(data?.id)}`
													)
												}
												className="w-7 ml-2 cursor-pointer"
												color="gray"
											/>
										</div>
									)}
									<CompanyProfile data={data} handleChange={handleChange} />
									<div className="flex justify-between items-start w-full">
										{!is_original && (
											<div className="flex flex-col gap-5 w-1/2">
												<div className="flex flex-col gap-y-2 z-20">
													<label className="text-orange-500 text-sm font-light">
														Select Multiple Users
													</label>
													<UsersList
														setUserInfo={setUserInfo}
														userList={userList}
													/>
												</div>
												{userList?.length > 0 && (
													<div className="gap-y-3 flex flex-col">
														<label className="text-orange-500 font-light">
															Associated Users :{" "}
															<span className="text-[10px] ml-2">
																(Note: Click the user to make him owner)
															</span>
														</label>
														<div className="flex flex-wrap flex-start gap-3">
															{(userList || [])?.map((user) => {
																return (
																	<div
																		onClick={() => handleOwner(user?.id)}
																		className={`text-xs flex justify-between gap-x-2 cursor-pointer shrink-0 border ${
																			owner?.includes?.(user?.id) &&
																			"bg-orange-500 text-white"
																		} rounded-xl shadow-md p-2 text-center`}
																	>
																		{owner?.includes?.(user?.id) && (
																			<FaCrown color="white" size={12} />
																		)}
																		{user?.userName ??
																			`${user?.firstname} ${user?.lastname}`}
																		<button
																			onClick={(e) => {
																				e.stopPropagation();
																				setUserList(
																					userList?.filter((users) => {
																						return users?.id !== user?.id;
																					})
																				);
																				setOwner(
																					owner?.filter(
																						(own) => own !== user?.id
																					)
																				);
																			}}
																			className="text-red-600"
																			type="button"
																		>
																			<RxCross1
																				color={
																					owner?.includes?.(user?.id)
																						? "white"
																						: "red"
																				}
																				size={12}
																			/>
																		</button>
																	</div>
																);
															})}
														</div>
													</div>
												)}
											</div>
										)}
										{is_original && (
											<CompanyCategories
												handleChange={handleChange}
												data={data}
												categories={companyCategories}
												setData={setData}
											/>
										)}
									</div>
									{!is_original && (
										<div className="grid grid-cols-3 gap-10 gap-x-20 p-4 z-0">
											{entries.map((entry, index) => {
												return (
													<div className="relative w-full mb-5 group">
														<input
															type={entry?.type}
															name={entry?.name}
															value={data?.[entry?.name] || ""}
															onChange={handleChange}
															id={entry?.name}
															className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
															placeholder=" "
														/>
														<label
															htmlFor={entry?.name}
															className="peer-focus:font-medium cursor-text absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
														>
															{entry?.text}
														</label>
													</div>
												);
											})}
										</div>
									)}
									<FileAdd
										attachments={data?.attachments}
										data={data}
										setData={setData}
									/>
									<div className="relative w-full group col-span-3 border-2 rounded-sm border-black mt-5">
										<label className="absolute text-lg text-orange-600 duration-300 transform -translate-y-8 scale-75 top-0  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
											Company Description
										</label>
										<DraftEditor title={data?.history} onChange={setDraft} />
									</div>
									<div className="flex justify-between items-center">
										<div className="flex justify-start items-center gap-x-7">
											<button
												type="button"
												onClick={() => {
													ClearAll();
												}}
												className="border-red-600 capitalize text-red-600 hover:bg-red-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
											>
												Cancel
											</button>
											<button
												type="submit"
												onClick={() => handleSubmit()}
												className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
											>
												{isLoading?.editUpdateCompany && val === "add" ? (
													<Loader w={"6"} h={"6"} color={"blue"} />
												) : (
													"Save"
												)}
											</button>
										</div>
										{!register && !!data?.title && (
											<button
												type="button"
												onClick={() => {
													setVal("del");
													handleDelete(data?.id);
												}}
												className="border-red-600 capitalize text-red-600 hover:bg-red-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
											>
												{isLoading?.editUpdateCompany && val === "del" ? (
													<Loader w={"6"} h={"6"} color={"red"} />
												) : (
													"Delete"
												)}
											</button>
										)}
									</div>
								</>
							)
						)}
					</div>
				</div>
			)}
		</>
	);
};
