import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const UserStatisticsChart = ({ stats }) => {
    const currentDate = new Date();
    
    const options = {
        theme: "light",
        animationEnabled: true,
        exportFileName: "New Year Resolutions",
        title: {
            text: `Users Summary ${currentDate.getFullYear()}`,
			fontSize: 24,
			fontFamily: "Arial",
            fontWeight: "normal",
            fontColor: "#EE5E1B",
        },
        data: [{
            type: "pie",
            showInLegend: true,
            legendText: "{label}",
            toolTipContent: "{label}: <strong>{y}</strong>",
            indexLabel: "{y}",
            indexLabelPlacement: "inside",
            dataPoints: [
                { y: stats?.logged_in_users_count, label: "Users Logged In", color: "#f1c40f" },
                { y: stats?.new_users_count, label: "Registered Users", color: '#FF6600' },
            ]?.filter(dataPoint => dataPoint.y !== 0)
        }]
    };

    return (
        <div>
            <CanvasJSChart options={options} />
        </div>
    );
};