import { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../components/breadcrumbs";
import { Loader } from "../../../components/loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
	addUpdateTrainer,
	fetchTrainerInfo,
	setTrainerInfo,
} from "../../../redux/trainerSlice.ts";
import { Upload } from "../../../components/upload/index.jsx";
import { toastNotification } from "../../../services/notification-service.js";

export const AddUpdateTrainers = () => {
	const [data, setData] = useState({});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id, type } = useParams();
	const { isLoading, trainer } = useSelector((store) => store.trainer);

	useEffect(() => {
		if (id) {
			dispatch(fetchTrainerInfo(id));
		} else {
			dispatch(setTrainerInfo({}));
		}
	}, [dispatch, id]);

	useEffect(() => {
		setData(trainer);
	}, [trainer]);

	const handleSubmit = (e) => {
		e.preventDefault();
		const combinedData = { ...data };
		// if (!combinedData?.image_path) {
		// 	toastNotification({
		// 		status: "warning",
		// 		message: "Profile Picture is required!",
		// 	});
		// 	return;
		// }
		const formData = new FormData();

		if (typeof combinedData?.image_path === "string") {
			delete combinedData?.image_path;
		}

		Object.keys(combinedData).forEach((key) => {
			formData.append(key, combinedData[key]);
		});
		dispatch(addUpdateTrainer({ formData: { formData } }, navigate));
	};

	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleFileChange = async (e) => {
		const files = e.target.files;
		if (files?.[0]?.type?.includes("image") && files?.[0]?.size > 1024000) {
			if (files?.[0]?.size > 1024000)
				toastNotification({
					status: "warning",
					message: "Please upload profile picture of less than 1 Mb",
				});
			return;
		}
		if (!files?.[0]?.type?.includes("image")) {
			toastNotification({
				status: "warning",
				message: "Please upload image only!",
			});
			const inputThumb = document.getElementById(e.target.id);
			if (inputThumb) {
				inputThumb.value = "";
			}
			return;
		}
		setData({ ...data, [e.target.name]: files[0] });
	};

	const inputFields = [
		{ name: "name", text: "Name", type: "text" },
		{ name: "title", text: "Title", type: "text" },
		{ name: "company", text: "Company Name", type: "text" },
		{ name: "bio", text: "Biography", type: "textarea" },
	];

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Trainers", link: "/contents/trainers" },
					{ name: `${type === "add" ? "Add" : "Edit"} Trainer`, link: "" },
				]}
			/>
			{isLoading?.trainerInfo ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader w="20" h="20" color="orange" />
				</div>
			) : (
				<form onSubmit={handleSubmit} className="mb-1 relative mx-12">
					<div className="grid grid-cols-2 mt-6 gap-10 gap-x-20 p-4 rounded-lg shadow-xl">
						{(inputFields || []).map((inputField, index) => {
							return inputField.type === "textarea" ? (
								<div className="relative w-full mb-5 group col-span-2">
									<label className="text-orange-600" htmlFor={inputField?.name}>
										{inputField?.text}*
									</label>
									<textarea
										type={inputField?.type}
										id={inputField?.name}
										name={inputField?.name}
										onChange={handleChange}
										value={data?.[inputField?.name]}
										rows={5}
										className="block mt-2 p-2 w-full text-sm text-gray-900 bg-transparent border-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-orange-600"
										placeholder=" "
										required
									/>
								</div>
							) : (
								<div className="relative w-full group">
									<input
										type={inputField?.type}
										id={inputField?.name}
										value={data?.[inputField?.name]}
										name={inputField?.name}
										onChange={handleChange}
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=" "
										required
									/>
									<label
										htmlFor={inputField?.name}
										className="peer-focus:font-medium cursor-text absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										{inputField?.text} *
									</label>
								</div>
							);
						})}
					</div>
					<div className="grid grid-cols-3 mt-3">
						<div className="relative w-full group col-start-1 col-span-1">
							<label className="text-sm text-orange-500">
								Profile Picture
							</label>
							<Upload id={"thumbnail"}>
								<input
									id="thumbnail"
									type="file"
									accept="image/*"
									hidden="true"
									name="image_path"
									onChange={handleFileChange}
									max={1}
								/>
							</Upload>
						</div>
					</div>
					{data?.image_path &&
						(typeof data?.image_path === "string" ? (
							<img
								src={data?.image_path}
								alt="Uploaded Preview"
								className="ml-4 mt-3 w-20 h-20 rounded"
							/>
						) : (
							<img
								src={URL.createObjectURL(data?.image_path)}
								alt="Uploaded Preview"
								className="ml-4 mt-3 w-20 h-20 rounded"
							/>
						))}
					<button className="border-blue-600 mt-3 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out">
						{isLoading?.addUpdateTrainer ? (
							<div>
								<Loader h="6" w="6" color={"blue"} />
							</div>
						) : (
							"Save"
						)}
					</button>
				</form>
			)}
		</>
	);
};
