import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "../../../../components/modal";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { changeProfileStatus } from "../../../../redux/influencerProfileSlice.ts";
import { Loader } from "../../../../components/loader";

const Individual = (props, ref) => {
	const [show, setShow] = useState(false);
	const [data, setData] = useState({});
	const [status, setStatus] = useState(false);
	const dispatch = useDispatch();
	const { isLoading } = useSelector((store) => store.influencerprofile);

	useImperativeHandle(ref, () => ({
		open(data) {
			setShow(true);
			setData(data);
			setStatus(data?.is_creator);
		},
	}));

	return (
		<Modal size={"lg"} show={show} setShow={setShow}>
			<div className="flex justify-start flex-col items-start gap-y-2">
				{data?.creator?.picture_s3 && (
					<div className="w-full flex justify-center items-center">
						<img
							src={data?.creator?.picture_s3}
							alt="profile_picture"
							style={{ height: 100, width: 100 }}
							className={"object-cover mx-auto rounded-full"}
						/>
					</div>
				)}
				<h1 className="text-xl font-semibold">Profile Info</h1>
				<h1 className="text-lg font-light">
					Profile Name: {data?.creator?.creator_name}
				</h1>
				<h1 className="text-lg font-light">
					Profile Type:{" "}
					<span className="">
						{data?.creator?.is_company === 0 ? "Individual" : "Company"}
					</span>
				</h1>
				<h1 className="text-lg font-semibold">About:</h1>
				<div className="text-lg font-light">
					{data?.creator?.about || "No info provided!"}
				</div>
				<h1 className="text-lg font-semibold">Links:</h1>
				<div className="flex justify-start items-start gap-2 flex-wrap">
					{data?.creator?.links?.map((li) => {
						return (
							<div className="flex justify-center items-center p-2 border gap-x-2">
								<div className="text-sm ">{li?.link}</div>
								<a
									className="text-sm"
									target="_blank"
									rel="noreferrer"
									href={li?.link}
								>
									<FaExternalLinkAlt color="orange" />
								</a>
							</div>
						);
					})}
				</div>
				<h1 className="text-lg font-semibold">Tags:</h1>
				<div className="flex justify-start items-start gap-2 flex-wrap">
					{data?.creator?.tags?.map((tag) => {
						return <div className="text-sm p-2 border">{tag?.title}</div>;
					})}
				</div>
				{status === 1 ? (
					<div className="flex justify-start items-center gap-x-2 mt-3">
						<button
							onClick={() =>
								dispatch(
									changeProfileStatus({ user_id: data?.id, status: 2 }, setShow)
								)
							}
							className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
						>
							{isLoading?.changeProfileStatus === 2 ? (
								<Loader w={"6"} h={"6"} color={"blue"} />
							) : (
								"Approve"
							)}
						</button>
						<button
							onClick={() =>
								dispatch(
									changeProfileStatus({ user_id: data?.id, status: 3 }, setShow)
								)
							}
							className="border-red-600 capitalize text-red-600 hover:bg-red-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
						>
							{isLoading?.changeProfileStatus === 3 ? (
								<Loader w={"6"} h={"6"} color={"red"} />
							) : (
								"Reject"
							)}
						</button>
					</div>
				) : status === 2 ? (
					<div className="text-green-600 font-light text-sm mt-4">
						Accepted ✓
					</div>
				) : (
					<div className="text-red-600 font-light text-sm mt-4">Rejected 𐩃</div>
				)}
			</div>
		</Modal>
	);
};

export default forwardRef(Individual);
