import React from "react";
import { useSelector } from "react-redux";
import { Loader } from "../../../components/loader/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";

export const CaseStudyDetails = () => {
  const { caseStudyInfo, isLoading } = useSelector((store) => store.caseStudies);

  if (isLoading?.caseStudyInfo) {
    return (
      <div className="flex justify-center">
          <Loader w="20" h="20" color="orange" />
      </div>
    );
  }

  return (
    <div className="relative overflow-x-auto h-full w-full p-5">
      <div className="flex justify-between pb-5">
        <AddBtn text={'Edit Case Study'} to={`/contents/case-studies/edit/${(caseStudyInfo?.id)}`} />
        </div>
      <table className="w-full text-sm text-left rtl:text-right text-black shadow-lg rounded-lg">
        <tbody>
              <tr
                className="border-b"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Title
                </th>
                <td className="px-6 py-4">{caseStudyInfo?.title}</td>
              </tr>
              <tr
                className="border-b"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Sub Title
                </th>
                <td className="px-6 py-4">{caseStudyInfo?.sub_title}</td>
              </tr>
              <tr
                className="border-b"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Conclusion
                </th>
                <td dangerouslySetInnerHTML={{ __html: caseStudyInfo?.conclusion }}
                 className="px-6 py-4"></td>
              </tr>
              <tr
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Created At
                </th>
                <td className="px-6 py-4">{caseStudyInfo?.created_at?.slice(0, 10)}</td>
              </tr>
        </tbody>
      </table>
    </div>
  );
};