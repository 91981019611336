import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries, fetchReports } from "../../redux/others.ts";
import { FilterReport } from "../../containers/reports/filterReport/index.js";
import { TableReport } from "../../containers/reports/tableReport/index.js";
import { Pagination } from "../../components/pagination/index.js";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Transition,
} from "@headlessui/react";

export const Reports = () => {
	const [param, setParam] = useState({ search_option: 1 });
	const { reports } = useSelector((store) => store.other);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchReports({ params: { ...param, page: 1 } }));
		dispatch(fetchCountries({ params: { type: "countries" } }));
	}, []);

	function handlePage(page) {
		dispatch(fetchReports({ params: { ...param, page: page } }));
	}

	return (
		<>
			<Breadcrumbs breadcrumbs={[{ name: "Reports", link: "" }]} />
			<div className="mx-12">
				<div className="flex flex-col gap-y-3">
					<Disclosure>
						<DisclosureButton
							as="div"
							className="flex cursor-pointer items-center rounded-md shadow-md justify-between w-full p-5 font-medium rtl:text-right text-orange-500 border border-gray-200 focus:ring-0 hover:bg-gray-100 gap-3"
						>
							<span>Select Filters</span>
							<svg
								data-accordion-icon
								className="w-3 h-3 rotate-180 shrink-0"
								ariaHidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 10 6"
							>
								<path
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M9 5 5 1 1 5"
								/>
							</svg>
						</DisclosureButton>
						<Transition
							enter="duration-200 ease-out"
							enterFrom="opacity-0 -translate-y-6"
							enterTo="opacity-100 translate-y-0"
							leave="duration-300 ease-out"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 -translate-y-6"
						>
							<DisclosurePanel>
								<FilterReport param={param} setParam={setParam} />
							</DisclosurePanel>
						</Transition>
					</Disclosure>

					<Disclosure defaultOpen={true}>
						<DisclosureButton
							type="button"
							className="flex cursor-pointer items-center mb-2 rounded-md shadow-md justify-between w-full p-5 font-medium rtl:text-right text-orange-500 border border-gray-200 focus:ring-0 hover:bg-gray-100 gap-3"
						>
							<div>
								Search Result ({reports?.current_page} / {reports?.last_page})
							</div>
							<svg
								data-accordion-icon
								className="w-3 h-3 rotate-180 shrink-0"
								ariaHidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 10 6"
							>
								<path
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M9 5 5 1 1 5"
								/>
							</svg>
						</DisclosureButton>
						<Transition
							enter="duration-200 ease-out"
							enterFrom="opacity-0 -translate-y-6"
							enterTo="opacity-100 translate-y-0"
							leave="duration-300 ease-out"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 -translate-y-6"
						>
							<DisclosurePanel>
								<TableReport />
								<Pagination
									total={reports?.last_page}
									current={reports?.current_page}
									func={handlePage}
								/>
							</DisclosurePanel>
						</Transition>
					</Disclosure>
				</div>
			</div>
		</>
	);
};
