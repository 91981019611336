import { BsFileEarmarkTextFill } from "react-icons/bs";
import { Loader } from "../../../../components/loader";
import Api from "../../../../services/api";
import { useState } from "react";
import { toastNotification } from "../../../../services/notification-service";

export const FileAdd = ({ attachments, data, setData }) => {
	const [loading, setLoading] = useState(null);

	const handleDelete = async (payload) => {
		setLoading(payload);
		const json = await Api.deleteCompanyAttachment({
			id: payload?.id,
			company_id: payload?.company_id,
		});
		setLoading(null);
		if (!!json) {
			if (json?.status === 200) {
				setData({
					...data,
					attachments: data?.attachments?.filter((attach) => {
						return attach?.id !== payload?.id;
					}),
				});
				toastNotification({
					status: "success",
					message: "Attachment Deleted Successfully!",
				});
			}
		}
	};

	if ((attachments || [])?.length === 0) {
		return null;
	}

	return (
		<div>
			<label className="text-orange-500 text-sm font-light">Attachments:</label>
			<div className="grid grid-cols-2 w-full gap-3">
				{(attachments || [])?.map((attach) => {
					return (
						<div className="flex justify-between rounded-md p-3 shadow-md items-center border-gray-200 focus:ring-0 border ">
							<div className="flex flex-col items-start w-full font-medium rtl:text-right text-orange-500  gap-3">
								{attach?.name}
								<div className="flex justify-center items-center gap-x-4">
									<button
										onClick={() => {
											handleDelete(attach);
										}}
										type="button"
										className="text-red-600"
									>
										{loading?.id === attach?.id ? (
											<Loader w={"5"} h={"5"} color={"red"} />
										) : (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth="1.5"
												stroke="currentColor"
												className="w-5 h-5"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
												/>
											</svg>
										)}
									</button>
								</div>
							</div>
							<a
								href={attach?.url || ""}
								target="_blank"
								rel="noopener noreferrer"
							>
								{attach?.mime?.includes("image") ? (
									<img src={attach?.url} className="w-10 h-12 object-cover" />
								) : attach?.thumbnail?.path ? (
									<img
										src={attach?.thumbnail?.path}
										className="w-10 h-12 object-cover"
									/>
								) : (
									<BsFileEarmarkTextFill color="orange" size={40} />
								)}
							</a>
						</div>
					);
				})}
			</div>
		</div>
	);
};
