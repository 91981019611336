import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlog } from "../../../redux/blogsSlice.ts";
import { Loader } from "../../../components/loader/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const BlogDetails = () => {
	const { blogInfo, isLoading } = useSelector((store) => store.blogs);
	const { id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getBlog(id));
	}, [dispatch, id]);

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Blogs", link: "/contents/blogs" },
					{ name: "Blog Info", link: "" },
				]}
			/>
			{isLoading?.getBlog ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader w="20" h="20" color="orange" />
				</div>
			) : (
				<div className="relative overflow-x-auto px-[55px] h-full">
					<table className="w-full text-sm mb-5 text-left rtl:text-right text-black shadow-lg rounded-lg">
						<tbody>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									ID
								</th>
								<td className="px-6 py-4">{blogInfo?.id}</td>
								<td className="w-[180px] px-3">
									<AddBtn
										text={"Edit Blog"}
										to={`/contents/blog/edit/${blogInfo?.id}`}
									/>
								</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Title
								</th>
								<td className="px-6 py-4">{blogInfo?.title}</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Active
								</th>
								<td className="px-6 py-4">
									{blogInfo?.is_active === 1 ? "True" : "False"}
								</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Content
								</th>
								<td
									dangerouslySetInnerHTML={{ __html: blogInfo?.content }}
									className="px-6 py-4 leading-8"
								></td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Categories
								</th>
								<td className="px-6 py-4">
									{(blogInfo?.categories || []).map((cate, index) => {
										return (
											<ul className="list-disc" key={index}>
												<li>{cate.name}</li>
											</ul>
										);
									})}
								</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Images
								</th>
								<td className="px-6 py-4">
									<div>
										{(blogInfo?.image || []).map((img, index) => {
											return (
												<div key={index} className="flex justify-start">
													<img
														src={img.path}
														alt="casestudy"
														className="w-[100px] h-[100px]"
													/>
												</div>
											);
										})}
									</div>
								</td>
							</tr>
							<tr>
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Created At
								</th>
								<td className="px-6 py-4">
									{blogInfo?.created_at?.slice(0, 10)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}
		</>
	);
};
