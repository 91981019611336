import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategory, editCategory } from "../../../redux/faqsSlice.ts";
import { Loader } from "../../../components/loader/index.js";
import AddFormModel from "../../../components/addFormModal/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { toastNotification } from "../../../services/notification-service.js";

export const FaqCategoryInfo = () => {
	const { categoryInfo, isLoading } = useSelector((store) => store.faqs);
	const { id } = useParams();
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState();
	const [imagePreview, setImagePreview] = useState(null);

	function closeModal() {
		setIsOpen(false);
	}

	function openModal() {
		setIsOpen(true);
	}

	useEffect(() => {
		const updatedData = {
			...categoryInfo,
			old_image: categoryInfo?.image?.path,
			id: categoryInfo?.faq_category_id,
		};
		delete updatedData.image;
		setData(updatedData);
	}, [categoryInfo]);

	useEffect(() => {
		dispatch(getCategory(id));
	}, [dispatch, id]);

	const handleFileChange = async (e) => {
		const files = e.target.files;
		const file = files[0];
		if (!file?.type?.includes("image")) {
			return toastNotification({
				status: "warning",
				message: "Please upload image only!",
			});
		}
		setData({ ...data, image: file });

		const reader = new FileReader();
		reader.onloadend = () => {
			// Set the image preview using the data URL
			setImagePreview(reader.result);
		};
		reader.readAsDataURL(file);
	};

	const handleAdd = (e) => {
		e.preventDefault();
		const formData = new FormData();

		delete data.created_at;
		delete data.updated_at;
		delete data.old_image;
		delete data.faq_category_id;
		if (data?.name !== "") {
			Object.keys(data).forEach((key) => {
				formData.append(key, data[key]);
			});
		}

		dispatch(editCategory({ formData: { formData } }, closeModal));
	};

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Categories", link: "/contents/faqs/categories" },
					{ name: "Details", link: `` },
				]}
			/>
			{isLoading?.getCategory ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader w="20" h="20" color="orange" />
				</div>
			) : (
				<div className="w-full px-12">
					<table className="w-full text-sm text-left rtl:text-right px-12">
						<tbody className="mx-12 rounded-lg shadow-lg">
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Category ID
								</th>
								<td className="px-6 py-4">{categoryInfo?.faq_category_id}</td>
								<td className="px-3 w-[180px]">
									<AddBtn func={openModal} mt={5} text={"Edit Category"} />
								</td>
							</tr>
							<tr className="bg-white border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Name
								</th>
								<td className="px-6 py-4">{categoryInfo?.name}</td>
							</tr>
							<tr className="bg-white border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Created At
								</th>
								<td className="px-6 py-4">
									{categoryInfo?.created_at?.slice(0, 10)}
								</td>
							</tr>
							{categoryInfo?.image?.path && (
								<tr className="bg-white border-b">
									<th
										scope="row"
										className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
									>
										Image
									</th>
									<td className="px-6 py-4">
										<div className="mt-2">
											<img
												src={`${process.env.REACT_APP_SERVER_URL}/${categoryInfo?.image?.path}`}
												alt="category"
												className="w-[100px] h-[100px]"
											/>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			)}
			<AddFormModel
				isOpen={isOpen}
				closeModal={closeModal}
				handleAdd={handleAdd}
				data={data}
				setData={setData}
				isLoading={isLoading?.editCategory}
				name={true}
				image={true}
				imagePreview={imagePreview}
				handleFileChange={handleFileChange}
			/>
		</>
	);
};
