import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "../../../components/modal";
import { GiCrossMark } from "react-icons/gi";

const ErrorModal = (props, ref) => {
	const [show, setShow] = useState(false);
	const [messages, setMessages] = useState([]);
	const [error, setError] = useState(false);

	useImperativeHandle(ref, () => ({
		open(data, error) {
			setShow(true);
			setMessages(data);
			setError(error);
		},
	}));

	return (
		<Modal
			size={(messages || [])?.length > 5 && error ? "lg" : "md"}
			show={show}
			setShow={setShow}
		>
			<h1
				className={`text-center text-xl font-bold my-5 ${
					error ? "text-red-600" : "text-green-600"
				}`}
			>
				{error ? "Uploading Failed!" : "Upload Successful!"}
			</h1>
			<div
				className={`grid ${
					(messages || [])?.length > 5 && error ? "grid-cols-2" : "grid-cols-1"
				} justify-start items-start mx-5 gap-y-2`}
			>
				{error ? (
					messages?.map((mes) => {
						return (
							<div className="flex flex-col justify-start items-start">
								{mes?.row && (
									<div className="text-lg font-bold text-red-600">
										Row {mes?.row}:
									</div>
								)}
								<div className="flex flex-col justify-start items-start">
									{mes?.errors?.map((err) => {
										return (
											<div className="flex justify-start items-center gap-x-2">
												<GiCrossMark size={10} color="red" />
												{err?.replace(
													"SQLSTATE[HY000]: General error: 1267 Illegal mix of collations (latin1_swedish_ci,IMPLICIT) and (utf8mb4_unicode_ci,COERCIBLE) for operation '='",
													""
												)}
											</div>
										);
									})}
								</div>
							</div>
						);
					})
				) : (
					<div className="flex justify-center items-center flex-col">
						<div className="font-semibold">
							New Companies Created: {messages?.new_companies || 0}
						</div>
						<div className="font-semibold">
							New Jobs Created: {messages?.jobs_created || 0}
						</div>
						<div className="font-semibold">
							Jobs Updated: {messages?.jobs_updated || 0}
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default forwardRef(ErrorModal);
