import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchContactUs } from "../../redux/others.ts";
import { Pagination } from "../../components/pagination/index.js";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import View from "./View/index.jsx";

export const ContactUs = ({ rfq }) => {
	const dispatch = useDispatch();
	const modalRef = useRef(null);
	const { contactUs, isLoading } = useSelector((store) => store.other);

	useEffect(() => {
		let query = { page: 1 };
		if (rfq) {
			query.includes = "rfq";
		} else {
			query.excludes = "rfq";
		}
		dispatch(fetchContactUs(query));
	}, [rfq]);

	function handlePage(page) {
		let query = { page: page };
		if (rfq) {
			query.includes = "rfq";
		} else {
			query.excludes = "rfq";
		}
		dispatch(fetchContactUs(query));
	}

	let tableData = [
		{ value: "user_name", to: `` },
		{ key: "Email", value: "user_email" },
		{ key: "Ticket Type", value: "ticket_type" },
		{ key: "Reason", value: "reason" },
		{ key: "Created At", value: "created_at", type: "date" },
	];

	if (rfq) {
		tableData = [
			{ value: "user_name", to: `` },
			{ key: "Email", value: "user_email" },
			{ key: "Company", value: "reason" },
			{ key: "Created At", value: "created_at", type: "date" },
		];
	}

	const handleView = (a, b, tab) => {
		modalRef.current.open(tab);
	};

	const actions = [{ type: "view", func: handleView, isLoading: false }];

	return (
		<>
			<View rfq={rfq} ref={modalRef} />
			<Breadcrumbs
				breadcrumbs={[
					{ name: rfq ? "Quote Requests" : "Action List", link: "" },
				]}
			/>
			<div className="mx-10 relative">
				<div className="h-[calc(100vh-190px)] overflow-auto my-2 hide-scroll">
					<Table
						data={tableData}
						table={contactUs}
						isLoading={isLoading?.actions}
						actions={actions}
					/>
				</div>
				<Pagination
					current={contactUs?.current_page}
					func={handlePage}
					total={contactUs?.last_page}
				/>
			</div>
		</>
	);
};
