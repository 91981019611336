import { Link, useParams } from "react-router-dom";
import { convertDateToWords } from "../../helper/dateFormat";
import { slugify } from "../../helper/paramString";
import { Loader } from "../loader";
import { Unavailable } from "../noData";
import pointer from "../../assets/svg/pointer.svg";

export const VerticalTable = ({
	data,
	table,
	actions,
	id,
	isLoading,
	list,
	tabs_two,
}) => {
	const { id: paramId } = useParams();

	return (
		<table className="w-full text-sm text-left text-black border border-black">
			<thead className="text-sm text-orange-500 uppercase bg-gray-100">
				<tr>
					<th scope="col" className="px-6 py-3 text-center border border-black">
						#
					</th>
					{data?.map((dat, index) => {
						return (
							<th
								key={index}
								scope="col"
								className="px-6 py-3 text-center border border-black"
							>
								{dat?.key}
							</th>
						);
					})}
					{actions && (
						<th
							scope="col"
							className="px-6 py-3 border border-black text-center"
						>
							Actions
						</th>
					)}
				</tr>
			</thead>
			{isLoading ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader h="20" w="20" color={"orange"} />
				</div>
			) : (
				<tbody>
					<Unavailable data={table} />
					{table?.data?.map((tab, index) => {
						return (
							<tr
								key={index}
								className={`bg-white hover:bg-gray-50 ${
									(table?.data || [])?.length !== index + 1 && "border-b"
								}`}
							>
								<td className="px-6 py-4 text-center border-r border-black">
									{table?.from + index}
								</td>
								{data?.map((dat, ind) => {
									return (
										<td
											key={ind}
											className="px-6 py-4 text-center border-r border-black"
										>
											<div className="font-light">
												{dat?.key === "Report About" ? (
													<div className="flex justify-start gap-x-2 items-start">
														{!!tab?.answer
															? "Answer Details:"
															: !!tab?.question
															? "Question Details:"
															: "Comment Details:"}{" "}
														<img src={pointer} className="w-4" />
													</div>
												) : null}
												{dat?.key === "Created" || dat?.type === "date" ? (
													<span>
														{convertDateToWords(
															tab?.[dat?.value]?.slice(0, 10)
														) || "Not login yet"}
													</span>
												) : dat?.type === "fullName" ? (
													<span>
														{tab?.user?.firstname} {tab?.user?.lastname}
													</span>
												) : (
													<span
														className={`${
															tab?.[dat?.value] === "Draft"
																? "text-orange-600"
																: tab?.[dat?.value] === "Publish"
																? "text-green-600"
																: tab?.[dat?.value1]?.[dat?.value2] ===
																		"Super Admin" && dat?.nested
																? "text-green-700 font-normal"
																: tab?.[dat?.value1]?.[dat?.value2] ===
																		"Admin" && dat?.nested
																? "text-blue-600 font-normal"
																: null
														}`}
													>
														{dat?.key === "Report Against" ? (
															!!tab?.answer ? (
																"Answer"
															) : !!tab?.question ? (
																"Question"
															) : (
																"Comment"
															)
														) : dat?.key === "Report About" ? (
															<div
																className="my-1 text-orange-500"
																dangerouslySetInnerHTML={{
																	__html: !!tab?.answer
																		? tab?.answer
																		: !!tab?.question
																		? tab?.question
																		: tab?.comment,
																}}
															/>
														) : dat?.nested ? (
															tab?.[dat?.value1]?.[dat?.value2] ||
															tab?.[dat?.value11]?.[dat?.value2] ||
															(tab?.[dat?.value1]?.[dat?.value2] == 0 && "0")
														) : (
															tab?.[dat?.value] ||
															dat?.text ||
															(tab?.[dat?.value] == 0 && "0")
														)}
													</span>
												)}
											</div>
										</td>
									);
								})}
								<td className="px-6 py-4 text-center gap-x-2 justify-center border-black flex items-center">
									{actions?.map((action) => {
										if (action?.type === "status") {
											return (
												<button
													onClick={() =>
														action?.func(tab?.id, tab?.is_published)
													}
													className={`py-1 border-2 rounded-lg ${
														tab?.[action?.value] === "Publish" ||
														tab?.[action?.value] === 1
															? "px-2 text-orange-600 border-orange-600 hover:bg-orange-600"
															: "px-1 text-blue-600 border-blue-600 hover:bg-blue-600"
													} hover:text-white text-sm transition-all duration-200 ease-in-out flex justify-center items-center`}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-3 h-3 mr-1"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
														/>
													</svg>
													{action?.isLoading && id === tab?.id ? (
														<Loader
															w="5"
															h="5"
															color={
																tab?.[action?.value] === "Publish" ||
																tab?.[action?.value] === 1
																	? "orange"
																	: "blue"
															}
														/>
													) : tab?.[action?.value] === "Publish" ||
													  tab?.[action?.value] === 1 ? (
														"Draft"
													) : (
														"Publish"
													)}
												</button>
											);
										}
										if (
											action?.type === "edit" ||
											action?.type === "view" ||
											action?.type === "setting"
										) {
											if (action?.link) {
												return (
													<Link
														to={action?.link?.replace(
															`{id}`,
															tab?.[action?.id]
														)}
														className="py-1 text-blue-600 border-2 rounded-lg
                      px-2 border-blue-600 bg-red hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out"
													>
														{action?.type === "edit" ? (
															<svg
																xmlns="http://www.w3.org/2000/svg"
																fill="none"
																viewBox="0 0 24 24"
																strokeWidth="1.5"
																stroke="currentColor"
																className="w-5 h-5"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
																/>
															</svg>
														) : action?.type === "setting" ? (
															<svg
																xmlns="http://www.w3.org/2000/svg"
																fill="none"
																viewBox="0 0 24 24"
																strokeWidth="1.5"
																stroke="currentColor"
																className="w-5 h-5"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
																/>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
																/>
															</svg>
														) : (
															<svg
																xmlns="http://www.w3.org/2000/svg"
																fill="none"
																viewBox="0 0 24 24"
																strokeWidth="1.5"
																stroke="currentColor"
																className="w-5 h-5"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
																/>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
																/>
															</svg>
														)}
													</Link>
												);
											}
											return (
												<button
													onClick={() => {
														if (action?.type === "setting") {
															action?.func(tab);
															return;
														}
														action?.func(
															tab?.id || tab?.faq_id || tab?.faq_category_id,
															tab?.course_name || paramId
														);
													}}
													className="py-1 text-blue-600 border-2 rounded-lg
                      px-2 border-blue-600 bg-red hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out"
												>
													{action?.type === "edit" ? (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="none"
															viewBox="0 0 24 24"
															strokeWidth="1.5"
															stroke="currentColor"
															className="w-5 h-5"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
															/>
														</svg>
													) : action?.type === "setting" ? (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="none"
															viewBox="0 0 24 24"
															strokeWidth="1.5"
															stroke="currentColor"
															className="w-5 h-5"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
															/>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
															/>
														</svg>
													) : (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="none"
															viewBox="0 0 24 24"
															strokeWidth="1.5"
															stroke="currentColor"
															className="w-5 h-5"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
															/>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
															/>
														</svg>
													)}
												</button>
											);
										}
										if (action?.type === "toggle") {
											return (
												<>
													{action?.isLoading && tab?.id === id ? (
														<Loader w={"5"} h={"5"} color={"green"} />
													) : (
														<label className="inline-flex items-center cursor-pointer">
															<input
																onClick={() =>
																	action?.func(tab?.id, tab?.[action?.value])
																}
																type="checkbox"
																checked={tab?.[action?.value]}
																value=""
																className="sr-only peer"
															/>
															<div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full outline-none after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
														</label>
													)}
												</>
											);
										}
										if (action?.type === "delete") {
											return (
												<button
													onClick={() =>
														action?.func(
															tab?.id || tab?.faq_id || tab?.faq_category_id
														)
													}
													className="py-1 text-red-600 border-2 rounded-lg
                      px-2 border-red-600 bg-red hover:bg-red-600 hover:text-white transition-all duration-200 ease-in-out"
												>
													{action?.isLoading &&
													(id === tab?.id ||
														id === tab?.faq_id ||
														id === tab?.faq_category_id) ? (
														<Loader w={"5"} h={"5"} color={"red"} />
													) : (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="none"
															viewBox="0 0 24 24"
															strokeWidth="1.5"
															stroke="currentColor"
															className="w-5 h-5"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
															/>
														</svg>
													)}
												</button>
											);
										}
										if (action?.type === "checkbox") {
											return (
												<input
													id="checked-checkbox"
													checked={tab?.status}
													onClick={() => action?.func(tab?.id, tab?.status)}
													type="checkbox"
													className="w-7 h-7 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
												/>
											);
										}
										if (action?.type === "activeStatus") {
											return (
												<button
													onClick={() => action?.func(tab?.id, tab?.is_active)}
													className="py-1 text-orange-600 border-2 text-sm rounded-lg px-2 border-orange-600 bg-red hover:bg-orange-600 hover:text-white transition-all duration-200 ease-in-out"
												>
													{action?.isLoading && tab?.id === id ? (
														<Loader w="6" h="6" color={"orange"} />
													) : !tab?.[action?.value] ? (
														"Activate"
													) : (
														"Deactivate"
													)}
												</button>
											);
										}
										if (action?.type === "adminStatus") {
											return (
												<button
													onClick={() => action?.func(tab?.id, tab?.role)}
													className={`${
														tab?.role?.id === 1
															? "text-blue-700 border-blue-700 hover:bg-blue-700"
															: "text-green-700 border-green-700 hover:bg-green-700"
													} py-1 border-2 rounded-lg px-2 text-sm bg-red flex justify-center hover:text-white transition-all duration-200 ease-in-out`}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-4 h-4 mr-1 mt-0.5"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
														/>
													</svg>
													<div>
														{action?.isLoading && tab?.id === id ? (
															<Loader
																w="6"
																h="6"
																color={tab?.role?.id === 2 ? "green" : "blue"}
															/>
														) : tab?.role?.id === 2 ? (
															"Super Admin"
														) : (
															"Admin"
														)}
													</div>
												</button>
											);
										}
										if (action?.type === "read") {
											return (
												<button
													onClick={() => action?.func(tab?.id)}
													className={`text-blue-600 border-2 ${
														action?.isLoading && tab?.id === id && "py-1"
													} rounded-lg px-2 border-blue-600 bg-red hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out`}
												>
													{action?.isLoading && tab?.id === id ? (
														<Loader w="4" h="4" color={"blue"} />
													) : (
														<div>
															✓ <span className="text-sm">Mark as read</span>
														</div>
													)}
												</button>
											);
										}
										if (action?.type === "people") {
											return (
												<Link
													to={action?.link?.replace(`{id}`, tab?.[action?.id])}
													onClick={() => action?.func(tab?.id)}
													className={`text-blue-600 border-2 rounded-lg px-2 py-1 border-blue-600 bg-red hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out`}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-5 h-5"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
														/>
													</svg>
												</Link>
											);
										}
										if (action?.type === "email") {
											return (
												<a
													href={`mailto:${tab?.[action?.to]}`}
													subject={encodeURIComponent(tab?.[action?.subject])}
													className={`text-green-600 border-2 rounded-lg px-2 py-1 border-green-600 bg-red hover:bg-green-600 hover:text-white transition-all duration-200 ease-in-out`}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-4 h-4"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
														/>
													</svg>
												</a>
											);
										}
										if (action?.type === "processCheck") {
											return (
												<div className="flex justify-center items-center text-sm text-green-500">
													<input
														id="checked-checkbox"
														type="checkbox"
														checked={list?.includes(tab?.id)}
														onClick={(e) => action?.func(e, tab?.id)}
														className="w-6 h-6 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
													/>
													{action?.text}
												</div>
											);
										}
										if (action?.type === "download") {
											return (
												<a
													href={tab?.resume_path}
													download={`${tab?.name}.csv`}
													target="_blank"
													rel="noreferrer"
													className="py-1 text-orange-600 border-2 rounded-lg
                      px-2 border-orange-600 cursor-pointer bg-red hover:bg-orange-600 hover:text-white transition-all duration-200 ease-in-out"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														stroke-width="1.5"
														stroke="currentColor"
														className="w-5 h-5"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
														/>
													</svg>
												</a>
											);
										}
									})}
								</td>
							</tr>
						);
					})}
				</tbody>
			)}
		</table>
	);
};
