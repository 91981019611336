import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface InfluencerProfile {
	profiles: any;
	isLoading: any;
}

const initialState: InfluencerProfile = {
	profiles: {},
	isLoading: {},
};

export const influencerProfileSlice = createSlice({
	name: "influencerprofile",
	initialState,
	reducers: {
		setProfiles: (
			state: InfluencerProfile,
			{ payload }: PayloadAction<any>
		) => {
			state.profiles = payload;
		},
		setStatusChange: (
			state: InfluencerProfile,
			{ payload }: PayloadAction<any>
		) => {
			state.profiles = {
				...state.profiles,
				data: state.profiles.data.map((profile) => {
					return payload?.user_id === profile?.id
						? { ...profile, is_creator: payload?.status }
						: profile;
				}),
			};
		},
		setLoader: (state: InfluencerProfile, { payload }: PayloadAction<any>) => {
			state.isLoading = { ...state.isLoading, ...payload };
		},
		reset: (state: InfluencerProfile) => initialState,
	},
});

export const fetchProfiles = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ profiles: true }));
	const json = await Api.getInfluencerProfiles(data);
	dispatch(setLoader({ profiles: false }));
	if (json !== undefined) {
		if (200 === json?.status) {
			dispatch(setProfiles(json?.data?.data));
		}
	}
};

export const changeProfileStatus = (data: any, setShow: any) => async (dispatch: any) => {
	dispatch(setLoader({ changeProfileStatus: data?.status }));
	const json = await Api.changeProfileStatus(data);
	dispatch(setLoader({ changeProfileStatus: false }));
	if (json !== undefined) {
		if (200 === json?.status) {
			setShow(false);
			toastNotification({status: "success", message: data?.status === 2 ? "Approved!": "Rejected"})
			dispatch(setStatusChange(data));
		}
	}
};

export const {
	setProfiles,
	setStatusChange,
	setLoader,
	reset: resetInfuencerProfile,
} = influencerProfileSlice.actions;
export default influencerProfileSlice.reducer;
