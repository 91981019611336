import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface team {
  team: any;
  memberInfo: any;
  isLoading: any;
  errorMsg: any;
}

const initialState: team = {
  team: [],
  memberInfo: {},
  isLoading: {},
  errorMsg: ""
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeam: (state: team, { payload }: PayloadAction<any>) => {
      state.team = payload;
    },
    setMemberInfo: (state: team, { payload }: PayloadAction<any>) => {
        state.memberInfo = payload;
    },
    setStatusUpdate: (state: team, {payload}: PayloadAction<any>) => {
        state.team = {...state.team, data: state.team?.data?.map((member) => {
          return payload === member?.id ? {...member, is_active: member?.is_active === 1?0:1} : member;
        })}
    },
    setLoading: (state: team, { payload }: PayloadAction<any>) => {
        state.isLoading = { ...state.isLoading, ...payload };
    },
    setDeleteTeam: (state: team, { payload }: PayloadAction<any>) => {
      const idToDelete = payload;
      state.team.data = state.team.data.filter((team) => team.id !== idToDelete);
    },
    setErrorMsg: (state: team, { payload }: PayloadAction<any>) => {
        state.errorMsg = payload;
    },
    reset: (state: team) => initialState,
  },
});

export const fetchTeam = (data: any) => async (dispatch: any) => {
  dispatch(setLoading({ fetchTeam: true }));
  const json = await Api.ourTeam(data);
    if (json?.status === 200) {
      dispatch(setTeam(json?.data?.data));
      dispatch(setLoading({ fetchTeam: false }));
    } else {
        dispatch(setLoading({ fetchTeam: false }));
      }
  return json;
};

export const teamMemberInfo = (id: any) => async (dispatch: any) => {
    dispatch(setLoading({ memberInfo: true }));
    const json = await Api.teamMemberInfo(id);
      if (json?.status === 200) {
        dispatch(setMemberInfo(json?.data?.data?.data[0]));
        dispatch(setLoading({ memberInfo: false }));
      } else {
        dispatch(setLoading({ memberInfo: false }));
      }
    return json;
  };

export const addTeamMember = (data: any, navigate: any) => async (dispatch: any) => {
    dispatch(setErrorMsg(""))
    dispatch(setLoading({ addTeam: true }));
    const json = await Api.addTeamMember(data);
      if (json?.status === 200) {
        toastNotification({status: "success", message: json?.data?.message})
        dispatch(setLoading({ addTeam: false }));
        navigate('/contents/team')
      } else {
        dispatch(setErrorMsg(json?.response?.data?.error))
        dispatch(setLoading({ addTeam: false }));
        }
    return json;
  };

  export const updateTeamStatus = (id: any) => async (dispatch: any) => {
    dispatch(setLoading({updateTeamStatus: true}))
    const json = await Api.updateTeamStatus(id);
    dispatch(setLoading({updateTeamStatus: false}))
      if (json?.status === 200) {
        dispatch(setStatusUpdate(id));
        toastNotification({status: "success", message: json?.data?.message || "Team Updated Successfully!"})
      } else {
        toastNotification({status: "error", message: "Please try again"})
      }
    return json;
  };

export const deleteTeam = (id: any) => async (dispatch: any) => {
    dispatch(setLoading({deleteTeam: true}))
    const json = await Api.deleteTeam(id);
    dispatch(setLoading({deleteTeam: false}))
      if (json?.status === 200) {
        dispatch(setDeleteTeam(id));
        toastNotification({status: "success", message: json?.data?.message || "Team Deleted Successfully!"})
      } else {
        toastNotification({status: "error", message: "Please try again"})
      }
    return json;
  };

export const {
  setTeam,
  setMemberInfo,
  setLoading,
  setErrorMsg,
  setStatusUpdate,
  setDeleteTeam,
  reset: resetTeam,
} = teamSlice.actions;
export default teamSlice.reducer;
