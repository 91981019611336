import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface blogs {
  blogs: any;
  blogInfo: any;
  categories: any;
  categoryInfo: any;
  isLoading: any;
  errorMsg: any;
}

const initialState: blogs = {
  blogs: [],
  blogInfo: {},
  categories: [],
  categoryInfo: {},
  isLoading: {},
  errorMsg: ""
};

export const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    setBlogs: (state: blogs, { payload }: PayloadAction<any>) => {
      state.blogs = payload;
    },
    setBlogInfo: (state: blogs, { payload }: PayloadAction<any>) => {
      state.blogInfo = payload;
    },
    setDeleteBlog: (state: blogs, { payload }: PayloadAction<any>) => {
      const idToDelete = payload;
      state.blogs.data = state.blogs.data.filter((blog) => blog.id !== idToDelete);
    },
    setCategories: (state: blogs, { payload }: PayloadAction<any>) => {
      state.categories = payload;
    },
    setCategoryInfo: (state: blogs, { payload }: PayloadAction<any>) => {
      state.categoryInfo = payload;
    },
    setDeleteCategory: (state: blogs, { payload }: PayloadAction<any>) => {
      const idToDelete = payload;
      state.categories = state.categories.filter((cate) => cate.id !== idToDelete);
    },
    setAddCategory: (state: blogs, { payload }: PayloadAction<any>) => {
      state.categories.push(payload);
    },
    setLoading: (state: blogs, { payload }: PayloadAction<any>) => {
      state.isLoading = { ...state.isLoading, ...payload };
    },
    setErrorMsg: (state: blogs, { payload }: PayloadAction<any>) => {
        state.errorMsg = payload;
    },
    reset: (state: blogs) => initialState,
  },
});

export const fetchBlogs = (data: any) => async (dispatch: any) => {
  dispatch(setLoading({ fetchBlogs: true }));
  const json = await Api.blogs(data);
    if (json?.status === 200) {
      dispatch(setBlogs(json?.data?.data));
      dispatch(setLoading({ fetchBlogs: false }));
    } else {
        dispatch(setLoading({ fetchBlogs: false }));
      }
  return json;
};

export const getBlog = (id: any) => async (dispatch: any) => {
    dispatch(setLoading({ getBlog: true }));
    const json = await Api.blogInfo(id);
      if (json?.status === 200) {
        dispatch(setBlogInfo(json?.data?.data));
        dispatch(setLoading({ getBlog: false }));
      } else {
        dispatch(setLoading({ getBlog: false }));
      }
    return json;
  };

  export const addBlog = (data: any, navigate: any) => async (dispatch: any) => {
  dispatch(setLoading({ addBlog: true }));
  const json = await Api.addBlog(data);
    if (json?.status === 200 || json?.status === 201) {
      toastNotification({status: "success", message: json?.data?.message});
      dispatch(setLoading({ addBlog: false }));
      navigate('/contents/blogs');
    } else {
      toastNotification({status: "error", message: json?.data?.message || "Please try again"})
      dispatch(setLoading({ addBlog: false }));
      }
  return json;
};

export const deleteBlog = (id: any) => async (dispatch: any) => {
  dispatch(setLoading({ deleteBlog: true }));
  const json = await Api.deleteBlog(id);
  if (json?.status === 200) {
    dispatch(setLoading({ deleteBlog: false }));
      dispatch(setDeleteBlog(id));
      toastNotification({status: "success", message: json?.data?.message || "Blog Deleted Successfully!"})
    } else {
      dispatch(setLoading({ deleteBlog: false }));
      toastNotification({status: "error", message: "Please try again"})
    }
  return json;
};

  export const fetchCategories = (data: any) => async (dispatch: any) => {
  dispatch(setLoading({ fetchCategories: true }));
  const json = await Api.categories(data);
    if (json?.status === 200) {
      dispatch(setCategories(json?.data?.data));
      dispatch(setLoading({ fetchCategories: false }));
    } else {
        dispatch(setLoading({ fetchCategories: false }));
      }
  return json;
};

export const getCategory = (id: any) => async (dispatch: any) => {
  dispatch(setLoading({ getCategory: true }));
  const json = await Api.categoryInfo(id);
    if (json?.status === 200) {
      dispatch(setCategoryInfo(json?.data?.data));
      dispatch(setLoading({ getCategory: false }));
    } else {
      dispatch(setLoading({ getCategory: false }));
    }
  return json;
};

export const addCategory = (data: any, closeModal: any) => async (dispatch: any) => {
  dispatch(setLoading({ addCategory: true }));
  const json = await Api.addCategory(data);
    if (json?.status === 200 || json?.status === 201) {
      dispatch(setAddCategory(json?.data?.data));
      toastNotification({status: "success", message: json?.data?.message});
      dispatch(setLoading({ addCategory: false }));
      closeModal();
    } else {
      toastNotification({status: "error", message: json?.data?.message || "Please try again"})
      dispatch(setLoading({ addCategory: false }));
      }
  return json;
};

export const editCategory = (data: any, closeModal: any) => async (dispatch: any) => {
  dispatch(setLoading({ editCategory: true }));
  const json = await Api.addCategory(data);
    if (json?.status === 200 || json?.status === 201) {
      dispatch(setCategoryInfo(json?.data?.data));
      toastNotification({status: "success", message: json?.data?.message});
      dispatch(setLoading({ editCategory: false }));
      closeModal();
    } else {
      toastNotification({status: "error", message: json?.data?.message || "Please try again"})
      dispatch(setLoading({ editCategory: false }));
      }
  return json;
};

  export const deleteCategory = (id: any) => async (dispatch: any) => {
    dispatch(setLoading({ deleteCategory: true }));
    const json = await Api.deleteCategory(id);
    if (json?.status === 200) {
        dispatch(setLoading({ deleteCategory: false }));
        dispatch(setDeleteCategory(id));
        toastNotification({status: "success", message: json?.data?.message || "Category Deleted Successfully!"})
      } else {
        dispatch(setLoading({ deleteCategory: false }));
        toastNotification({status: "error", message: "Please try again"})
      }
    return json;
  };

export const {
  setBlogs,
  setBlogInfo,
  setCategories,
  setLoading,
  setErrorMsg,
  setCategoryInfo,
  setDeleteBlog,
  setDeleteCategory,
  setAddCategory,
  reset: resetBlogs
} = blogsSlice.actions;
export default blogsSlice.reducer;
