import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentList } from "../../../redux/vouchers.ts";
import { Pagination } from "../../../components/pagination/index.js";
import { FilterBtn } from "../../../components/filterBtn/index.js";
import { FilterField } from "../../../components/filterField/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { Table } from "../../../components/table/index.jsx";

export const Paid = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useState({ status: "paid" });
  const { paymentList, isLoading } = useSelector((store) => store.voucher);

  useEffect(() => {
    dispatch(fetchPaymentList({ params: params }));
  }, []);

  function handlePage(page) {
    dispatch(fetchPaymentList({ params: { ...params, page: page } }));
  }

  const handleClearDate = () => {
    let param = { ...params, endDate: "", startDate: "" };
    setParams(param);
    dispatch(fetchPaymentList({ params: param }));
  };

  const tableData = [
		{type: "fullName", to: ``},
		{key: "Requested Amount", value: "pending_amount"},
		{key: "Paypal Email", value: "paypal_email"},
		{key: "Created", value: "created_at", type:"date"},
    {key: 'Status', text:'Paid'}
	];

  const handleSearch = () => dispatch(fetchPaymentList({ params: params }));

  return (
    <>
      <Breadcrumbs breadcrumbs={[{name: "Paid Vouchers", link: ""}]} />
      <div className="mx-12">
        <div className="flex justify-start items-center space-x-10 my-2">
          <FilterField
            param={params}
            setParam={setParams}
            type={"date range"}
          />
          <div className="mt-7">
            <FilterBtn
              func={handleClearDate}
              disabled={!params?.startDate && !params?.endDate}
              text={"Clear Date"}
              color={"red"}
            />
          </div>
          <div className="mt-7">
            <FilterBtn 
              func={handleSearch}
              disabled={!params?.startDate || !params?.endDate}
            />
          </div>
        </div>
        <div className="h-[calc(100vh-270px)] overflow-auto hide-scroll">
          <Table data={tableData} table={paymentList} isLoading={isLoading?.paymentList} />
        </div>
        <Pagination
          total={paymentList?.last_page}
          current={paymentList?.current_page}
          func={handlePage}
        />
      </div>
    </>
  );
};
