import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../../../components/breadcrumbs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchPostedContent,
	handleStatusChange,
} from "../../../redux/influencersSlice.ts";
import { Loader } from "../../../components/loader";
import { IoMdSend } from "react-icons/io";

export const SingleContent = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [review, setReview] = useState(false);
	const [message, setMessage] = useState("");
	const { content: data, isLoading } = useSelector((store) => store.influencer);

	useEffect(() => {
		dispatch(fetchPostedContent(id));
	}, [id]);

	const handleApprove = () => {
		dispatch(handleStatusChange({ id, status: 1 }));
	};

	const handleSend = async () => {
		const json = await dispatch(handleStatusChange({ id, remarks: message }));
		if (json?.status === 200) {
			setReview(false);
			setMessage("");
		}
	};

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Posted Content", link: "/influencers/posted-content" },
					{ name: "Details", link: "" },
				]}
			/>
			{isLoading?.content ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader w="20" h="20" color="orange" />
				</div>
			) : (
				<div className="mx-20 mb-10">
					<div className="flex flex-col justify-center items-center w-full gap-10">
						<div className="text-3xl text-start text-gray-600 w-full font-bold">
							{data?.type}
						</div>
						<h1 className="text-4xl w-full text-start font-semibold tracking-wide">
							{data?.title}
						</h1>
						<div className="flex justify-between items-center w-full">
							<button
								className={`flex items-center max-w-40 ${
									data?.paid
										? "border-orange-600 border-2 text-orange-600"
										: "border-green-600 border-2 text-green-600"
								} justify-center hover:opacity-80 flex-grow w-full h-11 px-5 border-0 shadow-none Regular rounded-lg`}
							>
								{data?.paid
									? `Buy ${data?.tokens ? `${data?.tokens} Ȼ` : ""}`
									: `Free`}
							</button>
						</div>
						<div className="flex flex-wrap justify-start w-full items-center gap-2">
							<div className="font-semibold w-full text-start text-lg text-gray-600">
								Keywords:
							</div>
							{(data?.tags || [])?.map((tag) => {
								return (
									<div className="px-4 py-1.5 bg-orange-600 text-white opacity-85 text-sm rounded-full">
										{tag?.tag_title}
									</div>
								);
							})}
						</div>
						<div
							className={`flex flex-col justify-between items-start gap-y-5 w-full`}
						>
							{(data?.content || [])?.map((cont) => {
								return (
									<div className="flex justify-start items-start flex-col sm:flex-row gap-3 w-full">
										{(cont?.cols || [])?.map((c, i) => {
											if (c?.type === "video" && c?.data) {
												return (
													<div className="flex flex-col w-full items-center gap-y-2">
														<video controls className="w-max">
															<source
																src={
																	typeof c?.data === "string"
																		? c?.data
																		: URL.createObjectURL(c?.data)
																}
																type={c?.data?.type}
															/>
															{c?.data?.name}
														</video>
														<div className="text-sm">{c?.caption}</div>
													</div>
												);
											}
											if (c?.type === "image" && c?.data) {
												return (
													<div className="flex flex-col w-full justify-center items-center gap-y-2">
														<img
															src={
																typeof c?.data === "string"
																	? c?.data
																	: URL.createObjectURL(c?.data)
															}
															type={c?.data?.type}
															className="w-max"
														/>
														<div className="text-sm">{c?.caption}</div>
													</div>
												);
											}

											const renderHtml = c?.data?.replace(/\n/g, "<br />");
											return (
												<div className="flex justify-start items-start">
													<div
														dangerouslySetInnerHTML={{ __html: renderHtml }}
													/>
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
					</div>
					{data?.status === 1 ? (
						<div className="text-green-600 mt-10">✓ Approved</div>
					) : !!data?.remarks ? (
						<div className="mt-10 bg-orange-50 p-3 w-max max-w-full text-orange-700">
							Admin Remarks: {data?.remarks}
						</div>
					) : review ? (
						<div className="flex flex-col justify-center items-start w-full gap-y-4 mt-5">
							<textarea
								onChange={(e) => setMessage(e.target.value)}
								maxLength={1000}
								value={message || ""}
								rows={5}
								className={`p-2.5 resize-none border-orange-500 w-full text-sm text-gray-900 bg-transparent border-2 appearance-none focus:outline-none focus:ring-0 peer`}
								placeholder=" "
							/>

							<div className="flex justify-center items-start gap-x-3">
								<button
									onClick={() => setReview(false)}
									className={`shrink-0 text-sm text-white bg-red-600 px-2 py-1 rounded-lg hover:opacity-80 transition-all duration-200 ease-in-out`}
								>
									Cancel
								</button>
								<button
									onClick={() => handleSend()}
									className={`shrink-0 text-orange-600 bg-orange-600 px-3 py-1 rounded-lg hover:opacity-80 transition-all duration-200 ease-in-out`}
								>
									{isLoading?.statusChange ? (
										<Loader w={"5"} h={"5"} color={"orange"} />
									) : (
										<IoMdSend size={20} color="white" />
									)}
								</button>
							</div>
						</div>
					) : (
						<div className="flex justify-start items-center gap-x-2">
							<button
								onClick={() => handleApprove()}
								className="mt-9 border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
							>
								{isLoading?.statusChange ? (
									<Loader w={"6"} h={"6"} color={"blue"} />
								) : (
									"Approve"
								)}
							</button>
							<button
								onClick={() => setReview(true)}
								className="mt-9 border-orange-600 capitalize text-orange-600 hover:bg-orange-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
							>
								Ask Changes
							</button>
						</div>
					)}
				</div>
			)}
		</>
	);
};
