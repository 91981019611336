import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { fetchBlogs, deleteBlog } from "../../redux/blogsSlice.ts";
import { Pagination } from "../../components/pagination/index.js";
import { AddBtn } from "../../components/addBtn/index.js";
import { FilterBtn } from "../../components/filterBtn/index.js";
import DeleteModal from "../../components/deleteModal/index.js";
import { FilterField } from "../../components/filterField/index.js";
import { Table } from "../../components/table/index.jsx";

export const Blogs = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { blogs, isLoading } = useSelector((store) => store.blogs);
	const [id, setId] = useState("");
	const [params, setParams] = useState({ page: 1 });
	const deleteRef = useRef(null);

	useEffect(() => {
		dispatch(fetchBlogs({ params: params }));
	}, []);

	function handlePage(page) {
		dispatch(fetchBlogs({ params: { ...params, page: page } }));
	}

	const handleSearch = () => {
		dispatch(fetchBlogs({ params: params }));
	};

	const handleClear = () => {
		setParams({});
		dispatch(fetchBlogs({ params: { page: 1 } }));
	};

	const handleView = (id) => navigate(`/contents/blog/${id}`);

	const handleDelete = (id) => {
		setId(id);
		deleteRef?.current?.open({
			id: id,
			deleteFun: deleteBlog,
			type: "Blog",
		});
	};

	const tableData = [
		{ value: "title", to: "/contents/blog/{id}" },
		{ key: "Status", value: "is_active", type: "status" },
		{ key: "Created", value: "created_at", type: "date" },
	];

	const actions = [
		{ type: "view", func: handleView, isLoading: false },
		{
			type: "delete",
			func: handleDelete,
			isLoading: isLoading?.deleteBlog,
		},
	];

	return (
		<div className="relative">
			<DeleteModal ref={deleteRef} />
			<div className="text-xl text-orange-500">Blogs</div>
			<div className="flex justify-between items-center space-x-10">
				<AddBtn to={"/contents/blog/add"} mt={5} text={"Add Blog"} />
				<div className="flex">
					<div className="flex flex-col justify-center pr-3 mt-2">
						<FilterField param={params} setParam={setParams} type={"title"} />
					</div>
					<div className="flex flex-col">
						<FilterField
							param={params}
							setParam={setParams}
							type={"date range"}
						/>
					</div>
					<div className="flex justify-center items-center px-2 mt-7 gap-2">
						<FilterBtn
							func={handleSearch}
							disabled={
								!params?.title && (!params?.startDate || !params?.endDate)
							}
						/>
						<FilterBtn
							func={handleClear}
							disabled={
								!params?.title && !params?.startDate && !params?.endDate
							}
							text={"Clear"}
							color={"red"}
						/>
					</div>
				</div>
			</div>
			<div className={`h-[calc(100vh-375px)] overflow-auto mt-5 hide-scroll`}>
        <Table data={tableData} table={blogs} actions={actions} id={id} isLoading={isLoading?.fetchBlogs} />
			</div>
			<Pagination
				total={blogs?.last_page}
				current={blogs?.current_page}
				func={handlePage}
			/>
		</div>
	);
};
