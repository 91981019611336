import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const DraftEditor = ({
  title,
  onChange,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => setValue((title || '').replace(/\n/g, '<br />')), [title])

  useEffect(() => onChange(value), [value]); // Update onChange without an empty string check

  return (
    <ReactQuill theme="snow" value={value} onChange={setValue} />
  );
};

export default DraftEditor;


// import React, { useEffect, useState } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import {
// 	EditorState,
// 	convertToRaw,
// 	ContentState
// } from "draft-js";
// import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";

// const DraftEditor = ({
// 	title,
// 	className,
// 	placeholder,
// 	onChange,
// 	maxTextLimit,
// 	customize,
// }) => {
// 	const [editorState, setEditorState] = useState();

// 	useEffect(() => {
// 		if (title) {
// 			const contentBlock = htmlToDraft(title);
// 			if (contentBlock) {
// 				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
// 				setEditorState(EditorState.createWithContent(contentState));
// 			}
// 		} else {
// 			setEditorState(EditorState.createEmpty());
// 		}
// 	}, [title]);

// 	const onEditorStateChange = (editorState) => {
// 		const contentState = editorState.getCurrentContent();
// 		const contentText = contentState.getPlainText();
// 		const maxLimit = maxTextLimit || 5000;

// 		setEditorState(editorState);
// 		onChange(draftToHtml(convertToRaw(contentState)));

// 		if (contentText.length > maxLimit) {
// 			const limitedContentState = ContentState.createFromText(
// 				contentText.substring(0, maxLimit)
// 			);
// 			const limitedEditorState = EditorState.push(
// 				editorState,
// 				limitedContentState,
// 				"insert-characters"
// 			);
// 			setEditorState(limitedEditorState);
// 		}
// 	};

// 	return (
// 		<div className={className}>
// 			{customize ? (
// 				<Editor
// 					editorState={editorState}
// 					onEditorStateChange={onEditorStateChange}
// 					placeholder={placeholder}
// 					wrapperClassName="wrapper-class"
// 					editorClassName="h-44 px-4"
// 					toolbarClassName="toolbar-class justify-content-center p-2 mb-0 border-left-0 border-right-0 border-top-0 border-bottom-0 w-max"
// 					toolbar={{
// 						options: ["inline", "list", "blockType", "fontSize", "colorPicker", "remove"],
// 						inline: {
// 							className: "toolbar-icon",
// 							inDropdown: false,
// 							options: ["bold", "italic", "underline"],
// 						},
// 						list: {
// 							className: "toolbar-icon",
// 							inDropdown: false,
// 							options: ["unordered", "ordered"],
// 						},
// 						blockType: {
// 							inDropdown: true,
// 							options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
// 							className: "toolbar-icon"
// 						},
// 						fontSize: {
// 							className: "toolbar-icon",
// 							options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
// 						},
// 						colorPicker: {
// 							className: "toolbar-icon",
// 							colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
// 								'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
// 								'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
// 								'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
// 								'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
// 								'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
// 						},
// 						remove: { className: "toolbar-icon" }
// 					}}
// 				/>
// 			) : (
// 				<Editor
// 					editorState={editorState}
// 					onEditorStateChange={onEditorStateChange}
// 					placeholder={placeholder}
// 					wrapperClassName="wrapper-class"
// 					editorClassName="h-44 px-4"
// 					toolbarClassName="toolbar-class justify-content-center p-2 mb-0 border-left-0 border-right-0 border-top-0 border-bottom-0 w-max"
// 				/>
// 			)}
// 		</div>
// 	);
// };

//export default DraftEditor;
