import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeCourseStatus, deleteCourse } from "../../../redux/courses.ts";
import DeleteModal from "../../../components/deleteModal/index.js";
import { Table } from "../../../components/table/index.jsx";
import { capitalize } from "../../../helper/join.js";

export const CoursesTable = ({ courses, isLoading, type }) => {
	const [id, setId] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const popupRef = useRef();

	const handleStatusChange = (id) => {
		dispatch(changeCourseStatus(id));
		setId(id);
	}

	const handleEdit = (id) => {
		navigate(`/offerings/${type}/action/edit/${id}`)
	}

	const handleView = (tab) => {
		navigate(`/offerings/${type}/${tab?.course_slug}/settings`)
	}

	const handleDelete = (id) => {
		setId(id);
		popupRef?.current?.open({
			id: {id: id, type: capitalize(type?.slice(0, -1))},
			deleteFun: deleteCourse,
			type: capitalize(type?.slice(0, -1)),
		});
	}

	const tableData = [
		{value: "course_name", to: `/offerings/${type}/{course_slug}`},
		//{key: "Primary Id", value: "id"},
		{key: "Status", value: "course_status"},
		{key: "Type", value: "data_type"},
		{key: "Publish Date", value: "published_at", type: "date"},
		{key: "Created Date", value: "created_at", type: 'date'},
	];

	const actions = [
		{type: 'status', func:handleStatusChange, isLoading: isLoading?.courseStatus, value:'course_status'},
		{type: 'edit', func: handleEdit, isLoading: false},
		{type: 'setting', func: handleView, isLoading: false},
		{type: 'delete', func: handleDelete, isLoading: isLoading?.deleteCourse}
	];

	return (
		<div className={`h-[calc(100vh-270px)] overflow-auto mt-5 hide-scroll`}>
			<DeleteModal ref={popupRef} />
			<Table data={tableData} table={courses} actions={actions} id={id} isLoading={isLoading?.courses} />
		</div>
	)
};
