import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface Influencer {
	contents: any;
  content: any;
	priceList: any;
	isLoading: any;
}

const initialState: Influencer = {
	contents: {},
  content: {},
	priceList: {},
	isLoading: {},
};

export const influencerSlice = createSlice({
	name: "influencer",
	initialState,
	reducers: {
		setInfluencersPrice: (
			state: Influencer,
			{ payload }: PayloadAction<any>
		) => {
			state.priceList = payload;
		},
		setContents: (state: Influencer, { payload }: PayloadAction<any>) => {
			state.contents = payload;
		},
    setContent: (state: Influencer, { payload }: PayloadAction<any>) => {
			state.content = payload;
		},
    setChangedStatus: (state: Influencer, { payload }: PayloadAction<any>) => {
			state.content = {...state.content, ...(payload?.data || {})};
		},
		setLoader: (state: Influencer, { payload }: PayloadAction<any>) => {
			state.isLoading = { ...state.isLoading, ...payload };
		},
		reset: (state: Influencer) => initialState,
	},
});

export const fetchPriceList = () => async (dispatch: any) => {
	dispatch(setLoader({ priceList: true }));
	const json = await Api.getPriceListInfluencers();
	dispatch(setLoader({ priceList: false }));
	if (json !== undefined) {
		if (200 === json?.status) {
			dispatch(setInfluencersPrice(json?.data));
		}
	}
};

export const fetchPostedContents = (query: object) => async (dispatch: any) => {
	dispatch(setLoader({ contents: true }));
	const json = await Api.getUploadedContent(query);
	dispatch(setLoader({ contents: false }));
	if (json !== undefined) {
		if (200 === json?.status) {
			dispatch(setContents(json?.data));
		}
	}
};

export const fetchPostedContent = (id: string) => async (dispatch: any) => {
	dispatch(setLoader({ content: true }));
	const json = await Api.getSingleContent(id);
	dispatch(setLoader({ content: false }));
	if (json !== undefined) {
		if (200 === json?.status) {
			dispatch(setContent(json?.data));
		}
	}
};

export const handleStatusChange = (query: object) => async (dispatch: any) => {
	dispatch(setLoader({ statusChange: true }));
	const json = await Api.changeContentStatus(query);
	dispatch(setLoader({ statusChange: false }));
	if (json !== undefined) {
		if (200 === json?.status) {
      toastNotification({status: "success" , message:"Status Updated!"})
			dispatch(setChangedStatus(json?.data));
		}
	}

  return json;
};


export const {
	setInfluencersPrice,
	setContents,
  setChangedStatus,
  setContent,
	setLoader,
	reset: resetInfluencer,
} = influencerSlice.actions;
export default influencerSlice.reducer;
