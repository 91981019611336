import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface Vouchers {
  voucherList: any;
  voucherInfo: any;
  voucherParam: any;
  plans: [];
  paymentList: any;
  emails: any
  isLoading: any;
}

const initialState: Vouchers = {
  voucherList: {},
  voucherInfo: {},
  voucherParam: {},
  plans: [],
  paymentList: {},
  emails: {},
  isLoading: {},
};

export const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    setVoucherList: (state: Vouchers, { payload }: PayloadAction<any>) => {
      state.voucherList = payload;
    },
    setDeleteVoucher: (state: Vouchers, { payload }: PayloadAction<any>) => {
      state.voucherList = {
        ...state.voucherList,
        data: state.voucherList.data.filter((voucher) => {
          return voucher.id !== payload;
        }),
      };
    },
    setStatusChange : (state: Vouchers, {payload}: PayloadAction<any>) => {
      state.voucherInfo = {...state.voucherInfo, status: payload?.status === 0? 1:0};
    },
    setVoucherParams: (state: Vouchers, { payload }: PayloadAction<any>) => {
			state.voucherParam = payload;
		},

    setVoucherPlans: (state: Vouchers, { payload }: PayloadAction<any>) => {
      state.plans = payload?.plans;
      state.voucherInfo = payload?.voucher;
    },
    setPaymentList: (state: Vouchers, {payload}: PayloadAction<any>) => {
      state.paymentList = payload;
    },
    setPaymentResponse: (state: Vouchers, {payload}: PayloadAction<any>) => {
      state.paymentList = {...state.paymentList, data: state.paymentList.data?.filter((list) => {
        return !payload.includes(list?.id)
      })}
    },
    setEmails: (state: Vouchers, {payload}: PayloadAction<any>) => {
      state.emails = payload;
    },
    setDeletedEmail: (state: Vouchers, {payload}: PayloadAction<any>) => {
      state.emails = {
        ...state.emails,
        data: state.emails.data.filter((email) => {
          return email.id !== payload;
        }),
      };
    },
    setLoader: (state: Vouchers, { payload }: PayloadAction<any>) => {
      state.isLoading = { ...state.isLoading, ...payload };
    },
    reset: (state: Vouchers) => initialState,
  },
});

export const fetchVouchers = (param: any, noload) => async (dispatch: any) => {
  dispatch(setLoader({ vouchers: noload ? false: true }));
  const json = await Api.vouchers(param);
  dispatch(setLoader({ vouchers: false }));
  if (json !== undefined) {
    if (200 === json.status) {
      dispatch(setVoucherList(json?.data));
    }
  }
};

export const deleteVoucher = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ deleteVoucher: true }));
  const json = await Api.deleteVoucher(data);
  dispatch(setLoader({ deleteVoucher: false }));
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setDeleteVoucher(data));
      toastNotification({status: "success", message: "Voucher deleted successfully!"})
    }
  }
};

export const fetchVoucherPlans = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ voucherInfo: true }));
  const json = await Api.voucherPlans(data);
  dispatch(setLoader({ voucherInfo: false }));
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setVoucherPlans(json?.data));
    }
  }
};

export const addVoucher = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ addVoucher: true }));
  const json = await Api.addVoucher(data);
  dispatch(setLoader({ addVoucher: false }));
  if (json !== undefined) {
    if (200 === json?.status) {
      toastNotification({status: "success", message: json?.data?.message || "Success!"})
      data.navigate("/payment-request/view-voucher")
    } else {
      toastNotification({status: "error", message: json?.response?.data?.[0]?.replace("earlier", "later") || "Error!"})
    }
  }
};

export const voucherStatusUpdate = (data: any) => async (dispatch: any) => {
  const json = await Api.voucherStatus(data);
  if (json !== undefined) {
    if (200 === json?.status) {
      if (json?.data?.result === 1) {
        dispatch(setStatusChange({status: data?.checkboxval, id: data?.id}));
        dispatch(fetchVouchers(data?.param, true));
        toastNotification({status: "success", message: "Status updated successfully!"})
      } else {
        toastNotification({status: "warning", message: "Must match with current discount offer!"})
      }
    }
  }
};

export const fetchPaymentList = (data: any) => async (dispatch: any) => {
  dispatch(setPaymentList({}));
  dispatch(setLoader({ paymentList: true }));
  const json = await Api.paymentList(data);
  dispatch(setLoader({ paymentList: false }));
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setPaymentList(json?.data))
    }
  }
}
export const fetchPaymentResponse = (data: any) => async (dispatch: any) => {
  const json = await Api.paymentProcess(data);
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setPaymentResponse(data));
      toastNotification({status: "success", message: "Marked as processing!"})
    } else {
      toastNotification({status: "error", message: "Error!"})
    }
  }
}

export const fetchCompleteResponse = (data: any) => async (dispatch: any) => {
  const json = await Api.paymentComplete(data);
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setPaymentResponse(data))
      toastNotification({status: "success", message: "Marked as Complete!"})
    } else {
      toastNotification({status: "error", message: "Error!"})
    }
    
  }
}

export const fetchEmails = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({emails: true}))
  const json = await Api.emails(data);
  dispatch(setLoader({emails: false}))
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setEmails(json?.data?.data))
    }
  }
}

export const generateEmails = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ generateEmails: true }));
  const json = await Api.generateEmail(data);
  dispatch(setLoader({ generateEmails: false }));
  if (json !== undefined) {
    if (201 === json?.status) {
      if (json?.data?.data?.is_draft === "1") {
        toastNotification({status: "warning", message: "E-mail saved in draft!"})
      } else {
        toastNotification({status: "success", message: "E-mail Generated Successfully!"})
        data.navigate("/payment-request/all-emails")
      }
    } else if (422 === json?.response?.status) {
      const error = json?.response?.data?.error[Object.keys(json?.response?.data?.error)?.[0]]?.[0];
      toastNotification({status: "warning", message: error || "Error generating email"})
    }
  }
}

export const deleteEmail = (data: any) => async (dispatch: any) => {
  const json = await Api.deleteEmail(data);
  if (json !== undefined) {
    if (201 === json?.status) {
      dispatch(setDeletedEmail(data));
      toastNotification({status: "success", message: "Email Deleted Successfully!"})
    }
  }
};

export const {
  setVoucherList,
  setVoucherPlans,
  setVoucherParams,
  setStatusChange,
  setDeleteVoucher,
  setPaymentList,
  setPaymentResponse,
  setEmails,
  setDeletedEmail,
  setLoader,
  reset: resetVoucher,
} = voucherSlice.actions;
export default voucherSlice.reducer;
