import upload from '../../assets/svg/upload.svg';

export const Upload = ({id, children}) => {
	return (
		<div className="relative max-w-40 mt-4">
			<label
				title="Click to upload"
				htmlFor={id}
				className="cursor-pointer flex items-center gap-4 px-3 py-2 before:border-gray-400/60 hover:before:border-gray-300 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
			>
				<div className="w-max relative">
					<img
						className="w-5"
						src={upload}
						alt="file upload icon"
						width="300"
						height="300"
					/>
				</div>
				<div className="relative">
					<span className="block text-sm relative text-orange-600 group-hover:text-orange-500">
						Upload a file
					</span>
				</div>
			</label>
            {children}
			{/* <input hidden="true" type="file" name="button2" id="button2" /> */}
		</div>
	);
};
