import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const CoursesChart = ({ courseData }) => {
    const options = {
        theme: "light",
        animationEnabled: true,
        exportFileName: "New Year Resolutions",
        title: {
            text: "Offerings Summary",
			fontSize: 24,
			fontFamily: "Arial",
            fontWeight: "normal",
            fontColor: "#EE5E1B",
        },
        data: [{
            type: "pie",
            showInLegend: true,
            legendText: "{label}",
            toolTipContent: "{label}: <strong>{y}</strong>",
            indexLabel: "{y}",
            indexLabelPlacement: "inside",
            dataPoints: [
                { y: courseData?.published_course, label: "Courses (P)", color: "#FF6600" },
                { y: courseData?.draft_course, label: "Courses (D)", color: '#e67e22' },
                { y: courseData?.published_event, label: "Events (P)", color: '#007aff' },
                { y: courseData?.draft_event, label: "Events (D)", color: '#3498db' },
                { y: courseData?.published_product, label: "Products (P)", color: '#ffd700' },
				{ y: courseData?.draft_product, label: 'Products (D)', color: '#f1c40f' }
            ]?.filter(dataPoint => dataPoint.y !== 0)
        }]
    };

    return (
        <div>
            <CanvasJSChart options={options} />
        </div>
    );
};