export const termData = {
    "Terms of Use": [
      { title: "Introduction", description: "Welcome to our platform. Read these terms before using our services." },
      { title: "Acceptance of Terms", description: "By using our services, you agree to comply with these terms and conditions." },
      { title: "User Conduct", description: "Maintain respectful conduct while using our platform. Any violation may result in account suspension." },
      { title: "Privacy Policy", description: "Learn how we collect, use, and protect your personal information by reviewing our Privacy Policy." },
      { title: "Termination", description: "We reserve the right to terminate your access to our services if you violate our terms." },
      { title: "Disclaimer", description: "Our platform is provided 'as is,' and we make no warranties regarding its accuracy or reliability." },
      { title: "Changes to Terms", description: "We may update these terms. Check regularly for changes, and your continued use implies acceptance." },
      { title: "Contact Information", description: "For questions or concerns regarding these terms, contact us at support@example.com." },
      { title: "Governing Law", description: "These terms are governed by the laws of the jurisdiction in which the company is registered." },
      { title: "Effective Date", description: "These terms are effective as of [Date]. Review the effective date for the latest version." },
      { title: "Feedback and Suggestions", description: "We welcome your feedback and suggestions to improve our services. Contact us to share your thoughts." },
    ],
    "Payment Terms": [
      { title: "Payment Options", description: "Explore the available payment methods for transactions on our platform." },
      { title: "Billing Cycle", description: "Understand the billing cycle and when payments are due for your subscription or purchases." },
      { title: "Late Payment", description: "Late payments may incur fees. Review our policy on late payments to avoid additional charges." },
      { title: "Refunds", description: "Learn about our refund policy and the conditions under which refunds are issued." },
      { title: "Subscription Plans", description: "Explore the different subscription plans available, each with its features and pricing." },
      { title: "Cancellations", description: "Understand the process and conditions for canceling subscriptions or orders on our platform." },
      { title: "Payment Security", description: "We prioritize the security of your payment information. Learn about our payment security measures." },
      { title: "Currency and Pricing", description: "Information about the supported currencies and pricing details for our products and services." },
      { title: "Jobs and Careers", description: "Check for job openings and career opportunities at our company. Join our growing team." },
      { title: "Billing Support", description: "Contact our billing support team for assistance with billing-related inquiries." },
      { title: "Promotions and Discounts", description: "Stay informed about ongoing promotions, discounts, and special offers on our platform." },
      { title: "Invoicing", description: "Details on how invoices are generated and provided for your transactions." },
      { title: "Payment Confirmation", description: "Receive confirmation and receipts for successful payments made on our platform." },
    ],
    "Company Terms": [
      { title: "About Us", description: "Learn about our company's mission, values, and the team behind our products and services." },
      { title: "Mission and Vision", description: "Understand our mission and vision for providing value and innovation to our users." },
      { title: "Contact Information", description: "Find contact details for reaching out to our support, sales, and general inquiries." },
      { title: "Customer Support", description: "Explore the ways our customer support team can assist you with inquiries and issues." },
      { title: "Partnerships", description: "Information on potential partnerships and collaborations with our company." },
      { title: "Jobs and Careers", description: "Check for job openings and career opportunities at our company. Join our growing team." },
      { title: "Social Responsibility", description: "Discover our commitment to social responsibility and community engagement." },
      { title: "Press and Media", description: "Access press releases, media resources, and the latest news about our company." },
      { title: "Accessibility", description: "Our commitment to providing accessible products and services for all users." },
      { title: "Data Security", description: "Details on how we prioritize and ensure the security of user data on our platform." },
      { title: "Community Guidelines", description: "Guidelines for fostering a positive and respectful community within our platform." },
      { title: "Events and Sponsorships", description: "Information about company-sponsored events, sponsorships, and community involvement." },
    ],
    "Yearly Terms": [
      { title: "Annual Review", description: "An overview of our annual review process, summarizing achievements and goals." },
      { title: "Renewal Process", description: "Details on the renewal process for subscriptions and continued access to our services." },
      { title: "Changes and Updates", description: "Stay informed about changes, updates, and new features introduced throughout the year." },
      { title: "User Feedback", description: "How user feedback is collected, analyzed, and used to improve our products and services." },
      { title: "Quality Assurance", description: "Our commitment to maintaining high-quality standards in our products and services." },
      { title: "Technology Updates", description: "Information about technology updates, improvements, and advancements applied." },
      { title: "User Surveys", description: "Participate in user surveys to share insights and contribute to our ongoing improvements." },
      { title: "Product Roadmap", description: "A glimpse into the planned development and features on our product roadmap." },
      { title: "Collaboration Opportunities", description: "Explore opportunities for collaboration with our company in the upcoming year." },
      { title: "User Education", description: "Initiatives to educate users about new features, best practices, and industry trends." },
      { title: "Community Events", description: "Participate in community events and initiatives organized by our company." },
      { title: "Recognition and Awards", description: "Highlights and recognitions received by our company over the past year." },
    ],
    "Last Term": [
      { title: "Conclusion", description: "A concluding statement summarizing the key points and thanking users for their engagement." },
      { title: "Feedback and Suggestions", description: "Encouragement for users to provide feedback and suggestions for improvement." },
      { title: "Effective Date", description: "The effective date of the last term, indicating the start date of the current terms." },
      { title: "Future Updates", description: "Anticipation of future updates and improvements to enhance user experience." },
      { title: "Community Engagement", description: "The importance of community engagement and participation in discussions." },
      { title: "Acknowledgments", description: "Acknowledgment of contributions from the community, users, and partners." },
      { title: "Support Channels", description: "Reminders about available support channels for addressing user inquiries and issues." },
      { title: "Stay Connected", description: "Encouragement to stay connected through newsletters, social media, and community forums." },
      { title: "User Stories", description: "Highlighting user stories and experiences that showcase the impact of our products." },
      { title: "Parting Words", description: "Closing remarks expressing gratitude and best wishes for the user community." },
      { title: "Legal Notice", description: "A legal notice covering any additional legal considerations or disclaimers." },
      { title: "Farewell", description: "A farewell message expressing appreciation for the user community's support and participation." },
      { title: "Intellectual Property", description: "Respect intellectual property rights. Do not reproduce or distribute content without permission." },
    ],
  };
  