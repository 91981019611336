import { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux"
import { addReview } from "../../../redux/courses.ts";
import { Loader } from "../../../components/loader/index.js";
import { UsersList } from "../../../components/usersList.js/index.js";

export const ReviewModal = ({ show, setShow, title }) => {
  const dispatch = useDispatch();
  const {isLoading} = useSelector(store => store.course);
  const [userInfo, setUserInfo] = useState({})

  const [data, setData] = useState({slug: title});
  
  useEffect(() => {
    setData({...data, user: userInfo?.userId})
  }, [userInfo])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addReview(data, setShow))
  }

  return (
    <>
      {show && (
        <div
          className="relative z-50"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <form 
              onSubmit={handleSubmit}
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex flex-col justify-start items-start">
                    <div className="relative md:col-start-1 md:col-span-2 z-20 w-full mb-5 group">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Review by
                      </label>
                      <UsersList setUserInfo={setUserInfo} />
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Rating
                      </label>
                      <select
                      onChange={(e) => setData({...data, rating: e.target.value})}
                      placeholder="Out of 5"
                        type="number"
                        className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5 "
                                        required
                      >
                        {[0, 1, 2, 3, 4, 5].map(rat => {
                            return <option>{rat}</option>
                        })}
                      </select>
                    </div>
                    <div className="w-full mt-3">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Review
                      </label>
                      <textarea
                      onChange={(e) => setData({...data, review: e.target.value})}
                      placeholder="Write here...."
                        cols={20}
                        rows={5}
                        type="text"
                        className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5 "
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-transparent px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                  >
                    {isLoading?.addReview ? <Loader w="6" h="6" color="orange" />: "Add"}
                  </button>
                  <button
                    onClick={() => setShow(false)}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-orange-600 shadow-lg border border-orange-600 hover:opacity-90 sm:ml-3 sm:w-auto"
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
