import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReview,
  fetchCourseReview,
  reviewStatus,
} from "../../../redux/courses.ts";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components/loader/index.js";
import { ReviewModal } from "./reviewModal.js";
import { Pagination } from "../../../components/pagination/index.js";
import { FilterBtn } from "../../../components/filterBtn/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import { FilterField } from "../../../components/filterField/index.js";
import { Unavailable } from "../../../components/noData/index.js";
import DeleteModal from "../../../components/deleteModal/index.js";
import { removeEmptyString } from "../../../helper/removeEmpty.js";

export const Reviews = () => {
  const dispatch = useDispatch();
  const { title } = useParams();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ page: 1, slug: title });
  const [id, setId] = useState();
  const { reviews, isLoading } = useSelector((store) => store.course);
  const popupRef = useRef();

  useEffect(() => {
    dispatch(fetchCourseReview({ params: data }));
  }, []);

  function handlePage(page) {
    dispatch(fetchCourseReview({ params: removeEmptyString({ ...data, page: page }) }));
  }
  
  const handleSearch = () => {
    dispatch(fetchCourseReview({ params: removeEmptyString(data) }));
  }

  const handleClear = () => {
    setData({page: 1, slug: title, status: ''})
    dispatch(fetchCourseReview({ params: {page: 1, slug: title} }));
  }

  return (
    <div className="relative">
      <ReviewModal show={show} setShow={setShow} title={title} />
      <DeleteModal ref={popupRef} />
      <div className="flex justify-start items-center space-x-10 ml-20">
        <FilterField
          param={data}
          setParam={setData}
          type={"title"}
        />
        <FilterField
          param={data}
          setParam={setData}
          type={"created"}
          format={true}
        />
        <div className="flex flex-col">
          <label className="font-light">Status</label>
          <select
            type="select"
            value={data?.status}
            onChange={(e) =>
              setData({ ...data, status: e.target.value })
            }
            className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
          >
            <option value={""}>Select</option>
            <option value={1}>Publish</option>
            <option value={0}>Draft</option>
          </select>
        </div>
        <div className="flex justify-center items-center space-x-5 mt-6">
          <FilterBtn 
            func={() => handleSearch()}
          />
          <FilterBtn 
            func={() => handleClear()}
            text={"Clear"}
            color={"red"}
          />
        </div>
        <AddBtn 
          func={() => setShow(true)}
          text={"Add"}
          mt={5}
        />
      </div>
      <div
        className="h-[calc(100vh-350px)] overflow-auto mt-5"
      >
        <table className="w-full text-sm text-left text-gray-500 border border-black">
				  <thead className="text-sm text-orange-500 uppercase bg-gray-100 border border-black">
            <tr>
              <th scope="col" className="px-6 py-3 text-center">
                Rated By
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Rating
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Title
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Created
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Action
              </th>
            </tr>
          </thead>
          {isLoading?.reviews ? (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Loader h="20" w="20" color={"orange"} />
            </div>
          ) : (
            <tbody>
              <Unavailable data={reviews?.reviews} />
              {reviews?.reviews?.data?.map((review) => {
                return (
                  <tr>
                    <th scope="col" className="px-6 py-3 text-center">
                      {review?.username}
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      <div className="flex items-center">
                        <svg
                          className={`w-4 h-4 ${
                            review?.rating >= 1
                              ? "text-yellow-300"
                              : "text-gray-300"
                          } me-1`}
                          ariaHidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 22 20"
                        >
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                        </svg>
                        <svg
                          className={`w-4 h-4 ${
                            review?.rating >= 2
                              ? "text-yellow-300"
                              : "text-gray-300"
                          } me-1`}
                          ariaHidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 22 20"
                        >
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                        </svg>
                        <svg
                          className={`w-4 h-4 ${
                            review?.rating >= 3
                              ? "text-yellow-300"
                              : "text-gray-300"
                          } me-1`}
                          ariaHidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 22 20"
                        >
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                        </svg>
                        <svg
                          className={`w-4 h-4 ${
                            review?.rating >= 4
                              ? "text-yellow-300"
                              : "text-gray-300"
                          } me-1`}
                          ariaHidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 22 20"
                        >
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                        </svg>
                        <svg
                          className={`w-4 h-4 ${
                            review?.rating >= 5
                              ? "text-yellow-300"
                              : "text-gray-300"
                          } me-1`}
                          ariaHidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 22 20"
                        >
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                        </svg>
                        <p className="ms-1 text-sm font-medium text-gray-500">
                          ({review?.rating})
                        </p>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      {review?.review}
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      {review?.created_at?.slice(0, 10)}
                    </th>
                    <td className="flex justify-center items-center space-x-3 px-6 py-3">
                      <button
                        onClick={() => {
                          dispatch(reviewStatus(review?.id));
                          setId(review?.id);
                        }}
                        className={`py-2 border-2 rounded-lg ${
                          review?.is_published === 0
                            ? "px-4 text-blue-600 border-blue-600 bg-white hover:bg-blue-600 hover:text-white"
                            : "px-6 text-orange-600 border-orange-600 bg-white hover:bg-orange-600 hover:text-white"
                        } transition-all flex justify-center items-center duration-200 ease-in-out`}
                      >
                        <svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-3 h-3 mr-1"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
													/>
												</svg>
                        {isLoading.reviewStatus && id === review?.id ? (
                          <Loader w="6" h="6" color="red" />
                        ) : review?.is_published === 1 ? (
                          "Draft"
                        ) : (
                          "Publish"
                        )}
                      </button>
                      <button
                        onClick={() => {
                            setId(review?.id)
                            popupRef?.current?.open({
                              id: review?.id,
                              deleteFun: deleteReview,
                              type: "Course Review",
                            })
                        }}
                        className="py-2 text-red-600 border-2 rounded-lg
                      px-2 border-red-600 bg-red hover:bg-red-600 hover:text-white transition-all duration-200 ease-in-out"
                      >
                        {isLoading?.deleteReview && id === review?.id ? (
                          <Loader w="6" h="6" color="red" />
                        ) : (
                          <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                        )}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <Pagination
        total={reviews?.reviews?.last_page}
        current={reviews?.reviews?.current_page}
        func={handlePage}
      />
    </div>
  );
};
