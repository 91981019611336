import { useEffect, useState } from "react";

export const Pagination = ({ current, total, func }) => {
  const pageArray = [];
  const [showPage, setShowPage] = useState([]);
  const [selected, setSelected] = useState(null);

  for (let i = 1; i <= total; i++) {
    pageArray.push(i);
  };

  useEffect(() => {
    setSelected(null);
    setShowPage(pageArray?.slice(current - (current-1)%5 -1, current - (current-1)%5 + 4))
  }, [current, total])

  const handlePageBarForward = () => {
    // setShowPage(pageArray?.slice(current - (current)%5, current - (current)%5 + 5))
    setShowPage(
      pageArray?.slice(
        showPage[showPage.length - 1],
        showPage[showPage.length - 1] + 5
      )
    );
  };

  const handlePageBarBack = () => {
    //setShowPage(pageArray?.slice(current - (current-2)%5 -2, current - (current-2)%5 + 3))
    setShowPage(pageArray?.slice(showPage[0] - 6, showPage[0] -1))
  }

  if ((total || 1) === 1) {
    return null
  }

  return (
    <div className="flex justify-center items-center space-x-3 my-2 shadow-sm border border-orange-500 w-max m-auto rounded-lg py-[1px] px-2">
      {(!!total) && (
        <button
          disabled={showPage[0] === 1 || !!selected}
          onClick={() => handlePageBarBack()}
          className="rounded-full w-10 h-10 text-orange-600 transition-all duration-300 ease-in-out disabled:text-gray-400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5"
            />
          </svg>
        </button>
      )}
      {total && (
        <button
          disabled={current === 1  || !!selected}
          onClick={() => {
            setShowPage(pageArray?.slice(current - (current-2)%5 -2, current - (current-2)%5 + 3))
              setSelected(current-1);
              func(current-1);
          }}
          className="rounded-full w-10 h-10 text-orange-600 transition-all duration-300 ease-in-out disabled:text-gray-400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </button>
      )}
      {showPage?.map((num) => {
        return (
          <button
            disabled={!!selected}
            onClick={() => {
              if (current === num) {
                return;
              }
              setSelected(num);
              func(num);
            }}
            className={`rounded-full  ${
              (selected ?? current) === num
                ? "bg-orange-600 disabled:bg-gray-400 text-white w-8 h-8"
                : "hover:text-orange-600 disabled:text-gray-400 w-10 h-10"
            } transition-all duration-300 ease-in-out`}
          >
            {num}
          </button>
        );
      })}
      {total && (
        <button
          disabled={current === total || !!selected}
          onClick={() => {
              setShowPage(pageArray?.slice(current - (current)%5, current - (current)%5 + 5))
              setSelected(current+1);
              func(current+1)
          }}
          className="rounded-full w-10 h-10 text-orange-600 transition-all duration-300 ease-in-out disabled:text-gray-400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 ml-1.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      )}
      {(!!total) && (
        <button
          disabled={showPage[showPage.length - 1] === total || !!selected}
          onClick={() => handlePageBarForward()}
          className="rounded-full w-10 h-10 text-orange-600 transition-all duration-300 ease-in-out disabled:text-gray-400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 ml-1.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      )}
    </div>
  );
};
