import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchJobCategories, getJob } from "../../../redux/jobsSlice.ts";
import Api from "../../../services/api.js";
import { entries } from "./entries.js";
import { toastNotification } from "../../../services/notification-service.js";
import { Loader } from "../../../components/loader/index.js";
import { fetchCountries } from "../../../redux/others.ts";
import DraftEditor from "../../../components/DraftEditor/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { isEmpty } from "../../../helper/join.js";
import { RxCross1 } from "react-icons/rx";

export const AddUpdateJobs = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { type, id } = useParams();
	const [data, setData] = useState({});
	const [loader, setLoader] = useState(false);
	const { jobInfo, categories, isLoading } = useSelector((store) => store.jobs);
	const [inputFields, setInputFields] = useState(entries);
	const [draft, setDraft] = useState();
	const { countries } = useSelector((store) => store.other);

	useEffect(() => {
		if (id) {
			dispatch(getJob(id));
		}
		dispatch(fetchJobCategories());
		dispatch(fetchCountries({ params: { type: "countries" } }));
	}, []);

	useEffect(() => {
		if (id && jobInfo) {
			let tempData = { ...jobInfo };
			tempData["country"] = countries?.countries?.data.find((dat) => {
				return dat?.name == jobInfo?.country;
			})?.id;
			tempData["skills"] = jobInfo?.skills?.map((skill) => {
				return skill?.id;
			});
			tempData["categories"] = jobInfo?.categories?.map((category) => {
				return category?.id;
			});
			tempData["additional_questions"] = jobInfo?.questions?.map((question) => {
				return { ...question, type: question?.answer_type };
			});
			if (jobInfo?.country) {
				const count = (countries?.countries?.data || [])?.filter((country) => {
					return country?.name === jobInfo?.country;
				})?.[0]?.id;
				dispatch(
					fetchCountries({
						params: {
							type: "states",
							country_id: parseInt(count),
						},
					})
				);
			}
			setData(tempData);
			setDraft(jobInfo?.description);
		}
	}, [jobInfo, countries?.countries]);

	const handleInputChange = (e) => {
		if (e.target.name === "employment_id") {
			if (
				e.target.value === "4" &&
				inputFields[inputFields?.length - 1]?.name !== "contract_duration"
			) {
				setInputFields([
					...inputFields,
					{
						type: "text",
						name: "contract_duration",
						text: "Contract Duration",
					},
				]);
			} else if (
				inputFields[inputFields?.length - 1]?.name === "contract_duration"
			) {
				setInputFields(inputFields?.slice(0, -1));
			}
		}
		if (e.target.name === "country") {
			let temp = { ...data };
			delete temp?.state;
			dispatch(
				fetchCountries({
					params: {
						type: "states",
						country_id: parseInt(e.target.value),
					},
				})
			);
			setData({ ...temp, [e.target.name]: e.target.value });
			return;
		}
		if (["skills", "categories"]?.includes(e.target.name)) {
			if (data?.[e.target.name]?.includes(e.target.value) || !e.target.value) {
				return;
			}
			setData({
				...data,
				[e.target.name]: [...(data[e.target.name] || []), e.target.value],
			});
		} else if (["expertise"]?.includes(e.target.name)) {
			setData({
				...data,
				[e.target.name]: [e.target.value],
			});
		} else if (["external_link"]?.includes(e.target.name)) {
			let temp = { ...data };
			delete temp["external_link"];
			setData({ ...temp, [e.target.name]: e.target.value });
		} else {
			setData({ ...data, [e.target.name]: e.target.value });
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let updatedData = { ...data };
		updatedData["description"] = draft;
		updatedData["country"] = countries?.countries?.data.find((dat) => {
			return dat?.id == data?.country;
		})?.name;
		if (!draft || draft === "<p><br></p>") {
			toastNotification({
				status: "warning",
				message: "Job Description is required!",
			});
			return;
		}
		if (
			(updatedData?.skills || "").length === 0 ||
			(updatedData?.categories || "").length === 0
		) {
			toastNotification({
				status: "warning",
				message: "Atleast one skill/category is required!",
			});
		} else {
			setLoader(true);
			const json = await Api.addJob(updatedData);
			setLoader(false);
			if (!!json && json?.status === 200) {
				toastNotification({
					status: "success",
					message: "Job Edited Successfully!",
				});
				navigate(`/jobs/detail/${id}`);
			}
			if (!!json && json?.status === 201) {
				toastNotification({
					status: "success",
					message: "Job Created Successfully!",
				});
				navigate("/jobs/view-all");
			} else if (!!json && json?.response?.status === 422) {
				const message =
					json?.response?.data?.errors?.[
						Object.keys(json?.response?.data?.errors)[0]
					][0];
				toastNotification({
					status: "error",
					message: message || "Error!",
				});
			}
		}
	};

	const handleQuestionAdd = (e) => {
		setData({
			...data,
			additional_questions: (data?.additional_questions || [])?.map(
				(question, index) =>
					e.target.id == index
						? { ...question, [e.target.name]: e.target.value }
						: question
			),
		});
	};

	const AddQuestion = () => {
		if ((data?.additional_questions || [])?.length < 4) {
			setData({
				...data,
				additional_questions: [...(data?.additional_questions || []), {}],
			});
		}
	};

	const DeleteQuestion = (index) => {
		const newData = { ...data };
		const additionalQuestions = [...newData.additional_questions];
		additionalQuestions.splice(index, 1);
		newData.additional_questions = additionalQuestions;
		setData(newData);
	};

	const RemoveEntry = (id, name) => {
		setData({
			...data,
			[name]: data?.[name]?.filter((n) => {
				return n !== id;
			}),
		});
	};

	return (
		<>
			{type === "add" ? (
				<Breadcrumbs
					breadcrumbs={[
						{ name: "Jobs", link: "/jobs/view-all" },
						{ name: "Add Job", link: "" },
					]}
				/>
			) : (
				<Breadcrumbs
					breadcrumbs={[
						{ name: "Jobs", link: "/jobs/view-all" },
						{ name: data?.title, link: `/jobs/detail/${id}` },
						{ name: "Edit Job", link: `` },
					]}
				/>
			)}
			{isEmpty(categories) ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader w="20" h="20" color="orange" />
				</div>
			) : (
				<form onSubmit={handleSubmit} className="mb-1 relative mx-12">
					<div className="grid grid-cols-3 mt-6 gap-10 gap-x-20 p-4 rounded-lg shadow-xl">
						<>
							{(inputFields || []).map((inputField, index) => {
								return inputField.isSelect ? (
									<div className="relative w-full mb-5 group">
										<select
											type={inputField?.type}
											id={inputField?.name}
											name={inputField?.name}
											onChange={handleInputChange}
											value={
												data?.[inputField?.name] ||
												data?.[inputField?.name]?.[0] ||
												data?.[inputField?.name2]
											}
											className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
											placeholder=" "
											required={
												!["skills", "categories"]?.includes(inputField?.name)
											}
										>
											<option value={""}>Select option</option>
											{(categories?.[inputField?.tname] || [])
												?.slice()
												.sort((a, b) =>
													a?.[inputField?.ename]?.localeCompare(
														b?.[inputField?.ename]
													)
												)
												.map((subcat) => (
													<option key={subcat?.id} value={subcat?.id}>
														{subcat?.[inputField?.ename]}
													</option>
												))}
										</select>
										<label
											htmlFor={inputField?.name}
											className="peer-focus:font-medium cursor-text absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
										>
											{inputField?.text}*
										</label>
									</div>
								) : (
									<div className="relative w-full mb-5 group">
										<input
											type={inputField?.type}
											id={inputField?.name}
											value={
												inputField?.name === "start_date" ||
												inputField?.name === "end_date"
													? data?.[inputField?.name]?.slice(0, 10)
													: data?.[inputField?.name]
											}
											name={inputField?.name}
											onChange={handleInputChange}
											className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
											placeholder=" "
											required={!inputField?.optional}
										/>
										<label
											htmlFor={inputField?.name}
											className="peer-focus:font-medium cursor-text absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
										>
											{inputField?.text}
											{!inputField?.optional && "*"}
										</label>
									</div>
								);
							})}
							<div className="relative w-full mb-5 group">
								<select
									type="text"
									id="country"
									name="country"
									onChange={handleInputChange}
									value={data?.country || ""}
									className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
									placeholder=" "
									required
								>
									<option value={""}>Select option</option>
									{(countries?.countries?.data || [])?.map((country) => {
										return <option value={country?.id}>{country?.name}</option>;
									})}
								</select>
								<label
									htmlFor="country"
									className="peer-focus:font-medium cursor-text absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									Select Country*
								</label>
							</div>
							<div className="relative w-full mb-5 group">
								<select
									type="text"
									id="state"
									name="state"
									onChange={handleInputChange}
									value={data?.state || ""}
									className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
									placeholder=" "
								>
									<option value={""}>Select option</option>
									{(countries?.states?.data || [])?.map((state) => {
										return <option value={state?.name}>{state?.name}</option>;
									})}
								</select>
								<label
									htmlFor="state"
									className="peer-focus:font-medium absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									Select State
								</label>
							</div>
							<div className="flex flex-col justify-start items-start col-span-3 -mt-3">
								Skills:
								<div className="flex justify-start items-center gap-3 flex-wrap w-full mt-2">
									{data?.skills?.map((id) => {
										const name = categories?.skills?.find((skill) => {
											return skill?.id == id;
										})?.title;
										return (
											<div className="px-2 py-1 rounded-full border border-orange-500 text-sm flex justify-center gap-2">
												{name}
												<button
													type="button"
													onClick={() => RemoveEntry(id, "skills")}
												>
													<RxCross1 size={12} color="red" />
												</button>
											</div>
										);
									})}
								</div>
							</div>
							<div className="flex flex-col justify-start items-start col-span-3 -mt-3">
								Categories:
								<div className="flex justify-start flex-wrap w-full items-center gap-3 mt-2">
									{data?.categories?.map((id) => {
										const name = categories?.job_categories?.find(
											(category) => {
												return category?.id == id;
											}
										)?.name;
										return (
											<div className="px-2 py-1 rounded-full border border-orange-500 text-sm flex justify-center gap-2">
												{name}
												<button
													type="button"
													onClick={() => RemoveEntry(id, "categories")}
												>
													<RxCross1 size={12} color="red" />
												</button>
											</div>
										);
									})}
								</div>
							</div>
							<div className="flex flex-col justify-start items-start col-span-3 -mt-3">
								<div className="text-md text-orange-600 gap-x-2 mb-2 flex justify-center items-center">
									Add Questions
									<button type="button" onClick={AddQuestion}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
											className="w-6 h-6"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
											/>
										</svg>
									</button>
								</div>
								{(data?.additional_questions || [])?.map((question, index) => {
									return (
										<div className="grid grid-cols-12 w-full gap-6">
											<div className="relative w-full mb-5 group col-span-9">
												<input
													id={index}
													name="question"
													type="text"
													value={question?.question || ""}
													onChange={handleQuestionAdd}
													className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
													placeholder=" "
													required
												/>
												<label
													htmlFor="floating_email"
													className="peer-focus:font-medium absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
												>
													Question {index + 1}*
												</label>
											</div>
											<div className="relative w-full mb-5 group col-span-2">
												<select
													id={index}
													name="type"
													type="text"
													value={question?.type || ""}
													onChange={handleQuestionAdd}
													className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
													placeholder=" "
													required
												>
													<option value={""}>Select Type</option>
													<option value={"boolean"}>Choose Yes/No</option>
													<option value={"varchar"}>Short Question</option>
												</select>
												<label
													htmlFor="floating_email"
													className="peer-focus:font-medium absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
												>
													Question Type *
												</label>
											</div>
											<button
												type="button"
												onClick={() => {
													DeleteQuestion(index);
												}}
												className="text-red-700 text-lg"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-6 h-6"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
													/>
												</svg>
											</button>
										</div>
									);
								})}
							</div>

							<div className="relative w-full mb-5 group col-span-3 border-2 rounded-sm border-black">
								<label className="absolute text-lg text-orange-600 duration-300 transform -translate-y-8 scale-75 top-0  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
									Job Description*
								</label>
								<DraftEditor title={data?.description} onChange={setDraft} />
							</div>
						</>
					</div>
					<button
						type="submit"
						className="mt-9 border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
					>
						{loader ? (
							<Loader w={"6"} h={"6"} color={"blue"} />
						) : type === "edit" ? (
							"Save"
						) : (
							type
						)}
					</button>
				</form>
			)}
		</>
	);
};
