import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { joinString } from "../../helper/join.js";

export const TermAside = ({ Terms, activeTab, setItem, setCurrentTab }) => {
	const navigate = useNavigate();
	const { tab } = useSelector((store) => store.term);
	return (
		<aside
			id="default-sidebar"
			className="shrink-0 w-64 overflow-hidden h-[calc(100vh-170px)]"
			aria-label="Sidebar"
		>
			<div className="h-full px-3 py-4 overflow-y-auto bg-gray-50">
				<ul className="space-y-0 font-medium">
					<div
						className={`bg-orange-500 text-white justify-center flex items-center rounded-lg h-16 group text-lg`}
					>
						Terms and Conditions
					</div>
					{Terms.map((term, index) => {
						return (
							<li key={index}>
								<div
									className={`${
										activeTab === index
											? "bg-blue-600 text-white"
											: tab > index
											? "bg-blue-500 text-white"
											: "bg-gray-300 text-black"
									} justify-center flex items-center rounded-full h-16 group text-base mt-1`}
								>
									<button
										disabled={tab < index}
										onClick={() => {
											setCurrentTab(index);
											setItem(0);
											navigate(`/policy/${joinString(term)}`);
										}}
									>
										{term}
									</button>
									{tab > index && (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
											className="w-5 h-5 ml-2"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
											/>
										</svg>
									)}
									{tab === index && (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
											className="w-5 h-5 ml-2"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
											/>
										</svg>
									)}
									{tab < index && (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
											className="w-5 h-5 ml-2"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
											/>
										</svg>
									)}
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</aside>
	);
};
