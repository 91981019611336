import { useState } from "react";
import { Loader } from "../../../../components/loader";
import { deleteChapter } from "../../../../redux/courses.ts";
import { useSelector } from "react-redux";

export const TopicTable = ({ topics, popupRef, modalRef, course_id, title }) => {
	const [id, setId] = useState(null);
	const { isLoading } = useSelector((store) => store.course);

	const addUpdatePopUp = (data) => {
		popupRef?.current?.open(data);
	};

	const handleDeleteTopic = (data) => {
		setId(data?.module_id);
		modalRef?.current?.open({
			id: data,
			deleteFun: deleteChapter,
			type: "Topic",
		});
	};

	if ((topics || [])?.length > 0) {
		return (
			<>
				<table className="w-full text-sm text-left text-gray-500">
					<tbody>
						{topics?.map((topic, index) => {
							return (
								<tr key={index} className="bg-white hover:bg-gray-50">
									<th
										onClick={() => addUpdatePopUp({ status: "Update", topic: topic, course_slug: title })}
										scope="row"
										className="px-6 py-4 cursor-pointer text-[16px] font-medium text-gray-700 whitespace-nowrap text-start"
									>
										{index + 1}
										{")"} {topic?.title}
									</th>
									<td className="px-6 py-4 text-start">
										<div className="flex justify-start items-start gap-x-4">
											<button
												onClick={() =>
													addUpdatePopUp({ status: "Update", topic: topic, course_slug: title })
												}
												className="text-blue-600"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-5 h-5"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
													/>
												</svg>
											</button>
											<button
												onClick={(e) => {
													e.stopPropagation();
													handleDeleteTopic({
														course_id: course_id,
														content_id: topic?.content_id,
														module_id: topic?.id,
														type: "topic",
													});
												}}
												className="text-red-600"
											>
												{isLoading?.deleteChapter && topic?.id === id ? (
													<Loader w={"5"} h={"5"} color={"red"} />
												) : (
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-5 h-5"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
														/>
													</svg>
												)}
											</button>
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</>
		);
	}
};
