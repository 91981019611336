import { useState, useImperativeHandle, forwardRef } from "react";
import { IoPersonSharp } from "react-icons/io5";
import { Modal } from "../../../components/modal";
import { useSelector } from "react-redux";

const BsUsersModal = ({ addNewChild }, ref) => {
	const [payload, setPayload] = useState(null);
	const [id, setId] = useState(null);
	const [show, setShow] = useState(false);
	const { users } = useSelector((store) => store.hierarchy);

	useImperativeHandle(ref, () => ({
		open(id, payload) {
			setId(id);
			setPayload(payload);
			setShow(true);
		},
	}));

	return (
		<Modal show={show} setShow={setShow}>
			<div className="flex justify-start flex-col w-full items-start gap-y-4">
				<div className="flex justify-center items-center w-full font-semibold text-lg">Buying Simplified Users</div>
				<div className="text-lg">Select a User</div>
				<div className="grid grid-cols-2 justify-center items-center gap-2">
					{users?.map((user) => {
						return (
							<button className="flex justify-start items-center gap-x-4 p-3 border">
								<IoPersonSharp
									color="orange"
									className={`w-6 h-6 rounded-full`}
								/>
								<span className="font-light">
									{user?.firstname} {user?.lastname}
								</span>
							</button>
						);
					})}
				</div>
			</div>
		</Modal>
	);
};

export default forwardRef(BsUsersModal);
