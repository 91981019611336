import { useDispatch, useSelector } from "react-redux";
import { fetchVoucherPlans, addVoucher } from "../../../redux/vouchers.ts";
import { useEffect, useState } from "react";
import { UsersList } from "../../../components/usersList.js/index.js";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "../../../components/loader/index.js";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { RxCross1 } from "react-icons/rx";

export const AddVoucher = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { plans, voucherInfo, isLoading } = useSelector(
		(store) => store.voucher
	);
	const [userInfo, setUserInfo] = useState();
	const [data, setData] = useState(
		location?.pathname.includes("/add") ? { id: null } : {}
	);
	const { id } = useParams();

	useEffect(() => {
		dispatch(fetchVoucherPlans(id));
	}, []);

	useEffect(() => {
		if (location?.pathname.includes("/update")) {
			setData({
				...voucherInfo,
				expiry_date: voucherInfo?.expiry_date?.slice(0, 10),
				valid_from: voucherInfo?.valid_from?.slice(0, 10),
			});
		}
	}, [voucherInfo]);

	const handleAdd = (e) => {
		e.preventDefault();
		let param = {
			...data,
			users: data?.users?.map((use) => {
				return use?.user_id;
			}),
		};
		if (param?.is_all === 1) {
			delete param?.users;
			dispatch(addVoucher({ param: param, navigate }));
		} else if (param?.users?.length > 0) {
			dispatch(addVoucher({ param: param, navigate }));
		}
	};

	const handleUserDelete = (id) => {
		setData({
			...data,
			users: data?.users?.filter((use) => {
				return use?.user_id !== id;
			}),
		});
	};

	useEffect(() => {
		if (userInfo?.userId) {
			setData({
				...data,
				users: [
					...(data?.users || []),
					{ user_id: userInfo?.userId, username: userInfo?.userName },
				],
			});
		}
	}, [userInfo]);

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Vouchers List", link: "/payment-request/view-voucher" },
					{
						name: location?.pathname.includes("/add")
							? "Add Voucher"
							: "Edit Voucher",
						link: "",
					},
				]}
			/>
			{isLoading?.voucherInfo ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader h="20" w="20" color={"orange"} />
				</div>
			) : (
				<form onSubmit={handleAdd} className="px-10 mt-20 py-3">
					<div className="grid grid-cols-4 gap-5 shadow-xl rounded-lg p-5">
						<div className="col-start-1 col-span-1">
							<label className="block mb-2 text-sm font-light">
								Voucher Title
							</label>
							<input
								onChange={(e) => setData({ ...data, voucher: e.target.value })}
								required
								value={data?.voucher}
								type="text"
								//readOnly={!location?.pathname.includes('/add')}
								placeholder="Enter Voucher title here.."
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
							/>
						</div>
						<div className="col-start-2 col-span-1">
							<label className="block mb-2 text-sm font-light">For</label>
							<select
								onChange={(e) => {
									setData({ ...data, is_all: parseInt(e.target.value) });
								}}
								required
								value={data?.is_all}
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
							>
								<option value={''}>Select</option>
								<option value={1}>All Users</option>
								<option value={2}>For Specific Users</option>
							</select>
						</div>
						<div className="col-start-3 col-span-1">
							<label className="block mb-2 text-sm font-light">
								Valid From
							</label>
							<input
								onChange={(e) =>
									setData({ ...data, valid_from: e.target.value })
								}
								value={data?.valid_from}
								type="date"
								required
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
							/>
						</div>
						<div className="col-start-4 col-span-1">
							<label className="block mb-2 text-sm font-light">
								Expiry Date
							</label>
							<input
								onChange={(e) =>
									setData({ ...data, expiry_date: e.target.value })
								}
								value={data?.expiry_date}
								required
								type="date"
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
							/>
						</div>
						<div className="col-start-1 col-span-1">
							<label className="block mb-2 text-sm font-light">Status</label>
							<select
								onChange={(e) =>
									setData({ ...data, is_active: parseInt(e.target.value) })
								}
								required
								value={data?.is_active}
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
							>
								<option value={''}>Select</option>
								<option value={1}>Active</option>
								<option value={0}>Deactive</option>
							</select>
						</div>
						<div className="col-start-2 col-span-1">
							<label className="block mb-2 text-sm font-light">
								How many times
							</label>
							<input
								onChange={(e) => {
									if (/^\d*$/.test(e.target.value)) {
										setData({
											...data,
											how_many_times: parseInt(e.target.value),
										});
									}
								}}
								required
								value={data?.how_many_times}
								type="number"
								className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
							/>
						</div>
						<div className="col-start-3 col-span-1">
							<label className="block mb-2 text-sm font-light">Plan</label>
							<select
								onChange={(e) =>
									setData({ ...data, plan_id: parseInt(e.target.value) })
								}
								required
								value={data?.plan_id}
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
							>
								<option value={''}>Select</option>;
								{plans?.map((plan) => {
									return <option value={plan?.id}>{plan?.name}</option>;
								})}
							</select>
						</div>
						<div className="col-start-4 col-span-1">
							<label className="block mb-2 text-sm font-light">Discount</label>
							<input
								onChange={(e) => {
									if (/^\d*$/.test(e.target.value)) {
										if (Number(e.target.value) > 100) {
											setData({ ...data, discount: 100 });
											return
										}
										setData({ ...data, discount: parseInt(e.target.value) });
									}
								}}
								required
								value={data?.discount}
								type="number"
								placeholder="Enter in %age"
								className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
							/>
						</div>
						{data?.is_all === 2 && (
							<div className="col-start-1 col-span-1">
								<label className="block mb-2 text-sm font-light">
									Select Users
								</label>
								<UsersList
									setUserInfo={setUserInfo}
									userList={(data?.users || [])?.map((d) => ({
										id: d?.user_id,
									}))}
								/>
								{(data?.users?.length === 0 ||
									data?.users?.length === undefined) && (
									<div className="text-red-500 text-xs">
										Select atleast 1 user!
									</div>
								)}
								{data?.is_all === 2 && (
									<div className="flex flex-wrap w-full justify-start gap-2 mt-3">
										{data?.users?.map((use) => {
											return (
												use && (
													<div className="text-xs flex justify-between shrink-0 border rounded-xl shadow-lg p-2 text-center">
														{use?.username}
														<button
															type="button"
															onClick={() => handleUserDelete(use?.user_id)}
															className="ml-3 text-red-500"
														>
															<RxCross1 size={12} color="red" />
														</button>
													</div>
												)
											);
										})}
									</div>
								)}
							</div>
						)}
					</div>
					<div className="flex justify-end items-end mt-5">
						<button
							type="submit"
							className="mt-9 border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
						>
							{isLoading?.addVoucher ? (
								<Loader h="6" w="6" color="blue" />
							) : location?.pathname.includes("/add") ? (
								"Add"
							) : (
								"Update"
							)}
						</button>
					</div>
				</form>
			)}
		</>
	);
};
