import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface casestudies {
  caseStudies: any;
  caseStudyInfo: any;
  sections: any;
  sectionInfo: any;
  isLoading: any;
  errorMsg: any;
}

const initialState: casestudies = {
  caseStudies: [],
  caseStudyInfo: {},
  sections: [],
  sectionInfo: {},
  isLoading: {},
  errorMsg: ""
};

export const caseStudiesSlice = createSlice({
  name: "caseStudies",
  initialState,
  reducers: {
    setCaseStudies: (state: casestudies, { payload }: PayloadAction<any>) => {
      state.caseStudies = payload;
    },
    setCaseStudyInfo: (state: casestudies, { payload }: PayloadAction<any>) => {
        state.caseStudyInfo = payload;
      },
    setDeleteCaseStudy: (state: casestudies, { payload }: PayloadAction<any>) => {
      const idToDelete = payload;
      state.caseStudies.data = state.caseStudies.data.filter((caseStudy) => caseStudy.id !== idToDelete);
    },
    setSections: (state: casestudies, { payload }: PayloadAction<any>) => {
      state.sections = payload;
    },
     setSection: (state: casestudies, { payload }: PayloadAction<any>) => {
      state.sectionInfo = payload;
    },
    setDeleteSection: (state: casestudies, { payload }: PayloadAction<any>) => {
      const idToDelete = payload;
      state.sections = state.sections.filter((section) => section.id !== idToDelete);
    },
    setLoading: (state: casestudies, { payload }: PayloadAction<any>) => {
      state.isLoading = { ...state.isLoading, ...payload };
    },
    setErrorMsg: (state: casestudies, { payload }: PayloadAction<any>) => {
        state.errorMsg = payload;
    },
    reset: (state: casestudies) => initialState
  },
});

export const fetchCaseStudies = (data: any) => async (dispatch: any) => {
  dispatch(setLoading({ fetchCaseStudies: true }));
  const json = await Api.caseStudies(data);
    if (json?.status === 200) {
      dispatch(setCaseStudies(json?.data?.data));
      dispatch(setLoading({ fetchCaseStudies: false }));
    } else {
        dispatch(setLoading({ fetchCaseStudies: false }));
      }
  return json;
};

export const getCaseStudy = (id: any) => async (dispatch: any) => {
    dispatch(setLoading({ caseStudyInfo: true }));
    const json = await Api.caseStudyInfo(id);
      if (json?.status === 200) {
        dispatch(setCaseStudyInfo(json?.data?.data));
        dispatch(setLoading({ caseStudyInfo: false }));
      } else {
        dispatch(setLoading({ caseStudyInfo: false }));
      }
    return json;
  };

export const addCaseStudy = (data: any, navigate: any) => async (dispatch: any) => {
  dispatch(setLoading({ addCaseStudy: true }));
  const json = await Api.addCaseStudy(data);
    if (json?.status === 200) {
      toastNotification({status: "success", message: json?.data?.message});
      dispatch(setLoading({ addCaseStudy: false }));
      navigate('/contents/case-studies')
    } else {
      toastNotification({status: "error", message: json?.data?.message || "Please try again"})
      dispatch(setLoading({ addCaseStudy: false }));
      }
  return json;
};

export const deleteCaseStudy = (id: any) => async (dispatch: any) => {
    dispatch(setLoading({deleteCaseStudy: true}));
    const json = await Api.deleteCaseStudy(id);
    if (json?.status === 200) {
      dispatch(setLoading({deleteCaseStudy: false}));
        dispatch(setDeleteCaseStudy(id));
        toastNotification({status: "success", message: json?.data?.message || "Case Study Deleted Successfully!"})
      } else {
        dispatch(setLoading({deleteCaseStudy: false}));
        toastNotification({status: "error", message: "Please try again"})
      }
    return json;
  };

  export const fetchCaseStudySections = (data: any) => async (dispatch: any) => {
  dispatch(setLoading({ fetchSections: true }));
  const json = await Api.caseStudySections(data);
    if (json?.status === 200) {
      dispatch(setSections(json?.data?.data));
      dispatch(setLoading({ fetchSections: false }));
    } else {
        dispatch(setLoading({ fetchSections: false }));
      }
  return json;
};

  export const fetchCaseStudySection = (data: any) => async (dispatch: any) => {
  dispatch(setLoading({ fetchSection: true }));
  const json = await Api.caseStudySection(data);
    if (json?.status === 200) {
      dispatch(setSection(json?.data?.data));
      dispatch(setLoading({ fetchSection: false }));
    } else {
        dispatch(setLoading({ fetchSection: false }));
      }
  return json;
};

  export const addSection = (data: any, navigate: any, id) => async (dispatch: any) => {
  dispatch(setLoading({ addSection: true }));
  const json = await Api.addCaseStudySection(data);
    if (json?.status === 200) {
      toastNotification({status: "success", message: json?.data?.message});
      dispatch(setLoading({ addSection: false }));
      navigate(`/contents/case-studies/${id}/sections`)
    } else {
      toastNotification({status: "error", message: json?.data?.message || "Please try again"})
      dispatch(setLoading({ addSection: false }));
      }
  return json;
};

export const editSection = (data: any, navigate: any, id) => async (dispatch: any) => {
  dispatch(setLoading({ editSection: true }));
  const json = await Api.addCaseStudySection(data);
    if (json?.status === 200) {
      toastNotification({status: "success", message: json?.data?.message});
      dispatch(setLoading({ editSection: false }));
      navigate(`/contents/case-studies/${id}/sections`)
    } else {
      toastNotification({status: "error", message: json?.data?.message || "Please try again"})
      dispatch(setLoading({ editSection: false }));
      }
  return json;
};

export const deleteSection = (id: any) => async (dispatch: any) => {
  dispatch(setLoading({ deleteSection: true }));
    const json = await Api.deleteSection(id);
    if (json?.status === 200) {
       dispatch(setLoading({ deleteSection: false }));
        dispatch(setDeleteSection(id));
        toastNotification({status: "success", message: json?.data?.message || "Case Study Deleted Successfully!"})
      } else {
        dispatch(setLoading({ deleteSection: false }));
        toastNotification({status: "error", message: "Please try again"})
      }
    return json;
  };

export const {
  setCaseStudies,
  setCaseStudyInfo,
  setLoading,
  setErrorMsg,
  setDeleteCaseStudy,
  setDeleteSection,
  setSections,
  setSection,
  reset: resetCaseStudy
} = caseStudiesSlice.actions;
export default caseStudiesSlice.reducer;
