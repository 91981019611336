import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Breadcrumbs } from "../../../components/breadcrumbs";

export const EmailDetails = () => {
	const { id } = useParams();
	const { emails } = useSelector((store) => store.voucher);
	const [data, setData] = useState({});

	useEffect(() => {
		const filterData = emails?.data?.find((data) => data?.id === parseInt(id));
		setData(filterData);
	}, [id]);

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Emails", link: "/payment-request/all-emails" },
					{ name: "Email Details", link: "" },
				]}
			/>
			<div className="relative overflow-x-auto h-full px-12">
				<div className="flex justify-between"></div>
				<table className="w-full text-sm my-5 text-left rtl:text-right text-black shadow-xl rounded-lg">
					<tbody>
						<tr className="border-b">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								ID
							</th>
							<td className="px-6 py-4">{data?.id}</td>
						</tr>
						<tr className="border-b">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								Subject
							</th>
							<td className="px-6 py-4">{data?.subject}</td>
						</tr>
						<tr className="border-b">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								Body
							</th>
							<td
								dangerouslySetInnerHTML={{ __html: data?.body }}
								className="px-6 py-4"
							></td>
						</tr>
						<tr className="border-b">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								Button Text
							</th>
							<td className="px-6 py-4">{data?.btn_text}</td>
						</tr>
						<tr className="border-b">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								Button Link
							</th>
							<td className="px-6 py-4">
								<a
									href={`https://${data?.btn_link}`}
									className="underline"
									target="_blank"
									rel="noreferrer"
								>
									{data?.btn_link}
								</a>
							</td>
						</tr>
						<tr className="border-b">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								Coupon
							</th>
							<td className="px-6 py-4">{data?.coupon}</td>
						</tr>
						<tr className="border-b">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								Image
							</th>
							<td className="px-6 py-4">
								<div className="mt-2">
									<img
										src={`${process.env.REACT_APP_SERVER_URL}/${data?.images}`}
										alt="img"
										className="w-[100px] h-[100px] rounded-lg"
									/>
								</div>
							</td>
						</tr>
						<tr className="border-b">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								Created At
							</th>
							<td className="px-6 py-4">{data?.created_at?.slice(0, 10)}</td>
						</tr>
						<tr>
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								User
							</th>
							<td className="px-6 py-4">
								{data?.user?.firstname + " " + data?.user?.lastname}
							</td>
							<td className="px-6 py-4">{data?.user?.email}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};
