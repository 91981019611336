import { useState, useImperativeHandle, forwardRef } from "react";
import { IoPersonSharp } from "react-icons/io5";
import { Modal } from "../../../components/modal";

const MemberModal = (props, ref) => {
	const [data, setData] = useState(null);
	const [show, setShow] = useState(false);

	const permissions = [
		{ name: "Edit Posts" },
		{ name: "View Posts" },
		{ name: "Delete Posts" },
		{ name: "Like Posts" },
		{ name: "Comment on Posts" },
	];

	useImperativeHandle(ref, () => ({
		open(data) {
			setData(data);
			setShow(true);
		},
	}));

	return (
		<Modal show={show} setShow={setShow}>
			<div className="flex justify-start flex-col w-full items-start gap-y-4">
				<div className="flex justify-center items-center w-full">
					<IoPersonSharp color="orange" className={`w-16 h-16 rounded-full`} />
				</div>
				<div className="text-lg font-semibold">Allowed Permissions:</div>
				<div className="flex flex-col gap-y-2">
					{permissions?.map((per) => {
						return (
							<div className="flex justify-start items-start gap-x-2">
								<input
									id="checked-checkbox"
									type="checkbox"
									className="w-5 h-5 text-orange-600 bg-gray-100 border-gray-300 rounded focus:ring-orange-500"
								/>
								{per?.name}
							</div>
						);
					})}
				</div>
				<button className="inline-flex w-full justify-center rounded-sm bg-orange-600 px-3 py-2 text font-semibold text-white shadow-sm hover:bg-orange-500 sm:w-auto">
					Save
				</button>
			</div>
		</Modal>
	);
};

export default forwardRef(MemberModal);
