import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { CoursesChart } from "../../containers/dashboard/coursesChart";
import { UsersGraph } from "../../containers/dashboard/graph";
import { JobsChart } from "../../containers/dashboard/jobsChart";
import { useEffect } from "react";
import { fetchDashboardData } from "../../redux/TermSlice.ts";
import { isEmpty } from "../../helper/join.js";
import { Loader } from "../../components/loader/index.js";
import { UserStatisticsChart } from "../../containers/dashboard/userStatisticsChart/index.jsx";
import { QuestionsChart } from "../../containers/dashboard/QuestionsChart/index.jsx";

export const Dashboard = () => {
	const dispatch = useDispatch();
	const { dashboardData } = useSelector((store) => store?.term);

	useEffect(() => {
		dispatch(fetchDashboardData());
		document.title = `Control Panel | Dashboard`;
	}, []);

	return (
		<>
			<Breadcrumbs />
			{isEmpty(dashboardData) && 
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader />
				</div>
			}
			{!isEmpty(dashboardData) && (
				<div className="px-12 w-full grid grid-cols-2 gap-10 pb-10">
					<div className="w-full h-full shadow-lg rounded-lg p-3">
						<UsersGraph users={dashboardData?.users} />
					</div>
					<div className="w-full h-full shadow-lg rounded-lg p-3">
						<CoursesChart courseData={dashboardData?.type} />
					</div>
					<div className="w-full h-full shadow-lg rounded-lg p-3">
						<UserStatisticsChart stats={dashboardData?.user_statistics} />
					</div>
					<div className="w-full h-full shadow-lg rounded-lg p-3">
						<QuestionsChart stats={dashboardData?.question_statistics} />
					</div>
					<div className="w-full h-full col-span-2 shadow-lg rounded-lg p-3">
						<JobsChart jobs={dashboardData?.jobs} />
					</div>
				</div>
			)}
		</>
	);
};
