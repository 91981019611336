import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { logout } from "../util/logout";
import { toastNotification } from "../services/notification-service";

interface LoginState {
  userInfo: {};
  token: null;
  isLoading: boolean;
  error: boolean;
}

const initialState: LoginState = {
  userInfo: {},
  token: null,
  isLoading: false,
  error: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUserInfo: (state: LoginState, { payload }: PayloadAction<any>) => {
      state.userInfo = payload;
    },
    setUserToken: (state: LoginState, { payload }: PayloadAction<any>) => {
      state.token = payload;
      state.isLoading = false;
    },
    setLoader: (state: LoginState, { payload }: PayloadAction<any>) => {
      state.isLoading = payload;
    },
    setError: (state: LoginState, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isLoading = false;
    },
    reset: (state) => initialState,
  },
});

export const fetchLoginInfo =
  (params: object, navigate: any) => async (dispatch: any) => {
    dispatch(setLoader(true));
    const json = await Api.userLogin(params);
    if (json !== undefined) {
      if (json?.status === 200) {
        dispatch(setUserToken(json?.data?.token));
        dispatch(setUserInfo(json?.data?.user_info));
        dispatch(setError(false));
        navigate("/dashboard");
      } else {
        dispatch(setLoader(false));
        dispatch(setError(true));
      }
    }
      dispatch(setLoader(false));
    return json;
  };

export const handleLogout = () => async (dispatch: any) => {
  const json = await Api.userLogout();
  if (json !== undefined) {
    if (json?.status === 200) {
      dispatch(logout());
    }
  }
};

export const passwordChange = (data: any, setChange: any, setParam: any) => async (dispatch: any) => {
  dispatch(setLoader(true));
  const json = await Api.passwordChange(data);
  if (json !== undefined) {
    if (json?.status === 200) {
      setChange(false);
      setParam({});
      toastNotification({status: "success", message: "password changed successfully!"})
    } else if (json?.response?.status === 422) {
        toastNotification({status: "warning", message: json?.response?.data?.message})
    }
  }
  dispatch(setLoader(false));
};

export const {
  setUserInfo,
  setUserToken,
  setLoader,
  setError,
  reset: resetLogin,
} = loginSlice.actions;
export default loginSlice.reducer;
