import { useEffect, useState } from "react";
import emitter from "../../services/emitter-service";
import { useLocation } from "react-router-dom";

export const ServerError = (props) => {
  const [serverError, setServerError] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    emitter.on("ServerError", () => setServerError(true));
  }, []);

  useEffect(() => {
    emitter.on("NetworkError", () => setNetworkError(true));
  }, []);

  useEffect(() => {
    if (serverError || networkError) {
      setServerError(false);
      setNetworkError(false);
    }
  }, [location?.pathname]);

  return (
    <>
      {serverError ? (
        <section>
        <div className="container mx-auto mt-20">
          <div className="xs:px-10 flex flex-wrap items-center justify-between rounded-lg border border-stroke px-6 md:px-8 lg:px-10 py-32">
            <div className="w-full">
              <div className="mb-6 md:mb-0">
                <div className="xs:text-2xl mb-1 text-xl font-semibold text-dark md:text-xl lg:text-[50px] flex justify-center items-center">
                  <div className="text-[100px] text-red-600 flex justify-center items-center">
                  <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="ml-5 w-20 h-20"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                />
              </svg>
                    <div className="text-3xl ml-2">500</div>
                  </div>
                  <div className="ml-5 text-xl"> | Internal Server Error</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      ) : networkError ? (
        <section>
      <div className="pt-32 px-20">
        <div className="flex flex-wrap items-center justify-between rounded-lg border border-stroke px-6 md:px-8 lg:px-10 py-32">
          <div className="w-full">
            <div className="mb-6 md:mb-0">
              <div className="xs:text-2xl mb-1 text-xl font-semibold text-dark md:text-xl lg:text-[50px] flex justify-center items-center">
                <div className="text-[100px] text-red-600 flex justify-center items-center">
                <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="ml-5 w-20 h-20"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
              />
            </svg>
                  <div className="text-3xl ml-2">Network Error</div>
                </div>
                <div className="ml-5 text-xl"> | Check Internet Connection</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      ) : (
        props.children
      )}
    </>
  );
};
