import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Api from "../services/api";

interface Candidates {
  requestCandidates: any;
  requestEmployees: any;
  isLoading: any;
}

const initialState: Candidates = {
  requestCandidates: {},
  requestEmployees: {},
  isLoading: {},
};

export const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    setRequestCandidates: (
      state: Candidates,
      { payload }: PayloadAction<any>
    ) => {
      state.requestCandidates = payload?.data;
      state.isLoading = { ...state.isLoading, requestCandidates: false };
    },
    setRequestEmployees: (
      state: Candidates,
      { payload }: PayloadAction<any>
    ) => {
      state.requestEmployees = payload?.data;
      state.isLoading = { ...state.isLoading, requestEmployees: false };
    },
    setLoader: (state: Candidates, { payload }: PayloadAction<any>) => {
      state.isLoading = { ...state.isLoading, ...payload };
    },
    reset: (state: Candidates) => initialState,
  },
});

export const fetchRequestCandidates = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ requestCandidates: true }));
  const json = await Api.candidateRequests(data);
  if (!!json) {
    if (json?.status === 200) {
      dispatch(setRequestCandidates(json?.data));
    }
  }
};

export const fetchRequestEmployees = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ requestEmployees: true }));
  const json = await Api.employeeRequests(data);
  if (!!json) {
    if (json?.status === 200) {
      dispatch(setRequestEmployees(json?.data));
    }
  }
};

export const {
  setRequestCandidates,
  setRequestEmployees,
  setLoader,
  reset: resetCandidate,
} = candidateSlice.actions;
export default candidateSlice.reducer;
