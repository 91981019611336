import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFaq } from "../../../redux/faqsSlice.ts";
import { Loader } from "../../../components/loader/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { AddBtn } from "../../../components/addBtn/index.js";

export const FaqDetails = () => {
  const { faqInfo, isLoading } = useSelector((store) => store.faqs);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFaq(id));
  }, [dispatch, id]);

  if (isLoading?.getFaq) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader w="20" h="20" color="orange" />
      </div>
    );
  }

  return (
    <>
    <Breadcrumbs breadcrumbs={[
				{name: "Faqs", link: "/contents/faqs"},
        {name: 'Faq Details', link: ''},
			]} />
    <div className="relative mx-[55px] rounded-lg shadow-lg overflow-x-auto h-full">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <tbody>
              <tr
                className="bg-white border-b "
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  ID
                </th>
                <td className="px-6 py-4">{faqInfo?.faq_id}</td>
                <td className="max-w-16 px-6"><AddBtn text={"Edit Faq"} to={`/contents/faq/edit/${(faqInfo?.faq_id)}`} /></td>
              </tr>
              <tr
                className="bg-white border-b"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Title
                </th>
                <td className="px-6 py-4">{faqInfo?.title}</td>
              </tr>
              <tr
                className="bg-white border-b"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Active
                </th>
                <td className="px-6 py-4">{faqInfo?.is_active === 1 ? "True" : "False"}</td>
              </tr>
              <tr
                className="bg-white border-b"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Description
                </th>
                <td dangerouslySetInnerHTML={{ __html: faqInfo?.description }}
                 className="px-6 py-4"></td>
              </tr>
              <tr
                className="bg-white border-b"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Categories
                </th>
                <td className="px-6 py-4">
                  {faqInfo?.category?.name}
                 </td>
              </tr>
              <tr
                className="bg-white border-b "
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Assigned Page
                </th>
                <td className="px-6 py-4">
                  {faqInfo?.page?.title}
                </td>
              </tr>
              <tr
                className="bg-white border-b"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Created At
                </th>
                <td className="px-6 py-4">{faqInfo?.created_at?.slice(0, 10)}</td>
              </tr>
        </tbody>
      </table>
    </div>
    </>
  );
};