import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../components/pagination/index.js";
import { Table } from "../../../components/table/index.jsx";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { fetchProfiles } from "../../../redux/influencerProfileSlice.ts";
import { debounce } from "lodash";
import Individual from "./individual/index.jsx";

export const InfluencersProfile = () => {
	const dispatch = useDispatch();
	const { profiles, isLoading } = useSelector(
		(store) => store.influencerprofile
	);
	const popupRef = useRef(null);
	const [param, setParam] = useState({ page: 1 });

	useEffect(() => {
		dispatch(fetchProfiles(param));
	}, [param]);

	const handlePage = (page) => {
		const par = { ...param, page: page };
		setParam(par);
	};

	const handleView = (a, b, tab) => {
		popupRef.current.open(tab);
	};

	const setName = debounce((value) => {
		const par = { ...param, page: 1, name: value };
		setParam(par);
	}, 1500);

	const tableData = [
		{ nested: true, value1: "creator", value2: "creator_name" },
		{ key: "Username", value: "username" },
		{
			key: "Profile Type",
			nested: true,
			value1: "creator",
			value2: "is_company",
			type: "profile_type",
		},
		{
			key: "Profile Status",
			value: "is_creator",
			type: "profile_status",
		},
		{
			key: "Updated At",
			nested: true,
			value1: "creator",
			value2: "updated_at",
			type: "date",
		},
	];

	const actions = [{ type: "view", func: handleView, isLoading: false }];
	return (
		<>
			<Individual ref={popupRef} />
			<Breadcrumbs breadcrumbs={[{ name: "Influencers Profile", link: "" }]} />
			<div className="mx-10 relative">
				<div className="flex flex-col max-w-[350px] my-3">
					<label className="font-light">Search By Name</label>
					<div className="flex justify-center items-center gap-x-4">
						<input
							onChange={(e) => {
								const inputValue = e.target.value;
								setName.cancel();
								setName(inputValue);
							}}
							placeholder="Start Typing to search..."
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						/>
					</div>
				</div>
				<div className="h-[calc(100vh-260px)] overflow-auto my-2 hide-scroll">
					<Table
						data={tableData}
						table={profiles}
						actions={actions}
						isLoading={isLoading?.profiles}
					/>
				</div>
				<Pagination
					current={profiles?.current_page}
					func={handlePage}
					total={profiles?.last_page}
				/>
			</div>
		</>
	);
};
