import { Breadcrumbs } from "../../components/breadcrumbs";
import React, { useEffect, useRef, useState } from "react";
import { MemberPicture } from "../../containers/hierarchy/memberImage";
import Xarrow from "react-xarrows";
import { v4 as uuidv4 } from "uuid";
import MemberModal from "../../containers/hierarchy/memberModal";
import { useDispatch } from "react-redux";
import { fetchBsUsers } from "../../redux/hierarchySlice.ts";
import UsersModal from "../../containers/hierarchy/usersModal/index.jsx";

export const HierarchyChart = () => {
	const [hierarchyData, setHierarchyData] = useState({
		1: { parent_id: null, level: 1, childs: [] },
	});
	const dispatch = useDispatch();
	const modalRef = useRef(null);
    const popupRef = useRef(null);

	useEffect(() => {
		dispatch(fetchBsUsers({}));
	}, []);

	const openModal = (data) => {
		modalRef.current.open(data);
	};

	const addNewChild = (parent_id, payload) => {
		let newId = uuidv4();
		let newData = { ...hierarchyData };
		newData[parent_id] = {
			...hierarchyData?.[parent_id],
			childs: [...hierarchyData?.[parent_id]?.childs, newId],
		};
		newData[newId] = {
			parent_id: parent_id,
			level: payload?.level + 1,
			childs: [],
		};
		setHierarchyData(newData);
	};

	const ArrowComp = ({ start, end }) => {
		return (
			<>
				<Xarrow
					strokeWidth={1}
					lineColor={"orange"}
					headColor={"orange"}
					curveness={0}
					animateDrawing
					start={start}
					end={end}
					headSize={0}
					headShape={"none"}
				/>
				{hierarchyData?.[end]?.childs?.map((child_id) => {
					return <ArrowComp start={end} end={child_id} />;
				})}
			</>
		);
	};

	const RecursiveHierarcy = ({ id, childs }) => {
		const gridTemplateColumns = `repeat(${childs?.length}, minmax(0, 1fr))`;

		return (
			<>
				<div
					className={`grid place-items-center w-full mt-20`}
					style={{ gridTemplateColumns }}
				>
					{childs?.map((child) => {
						return (
							<div className="place-items-center w-full">
								<div className="flex justify-center items-center">
									<div>
										<MemberPicture
											addNewChild={addNewChild}
											payload={hierarchyData?.[id]}
											id={child}
											openModal={openModal}
                                            popupRef={popupRef}
										/>
									</div>
								</div>
								<RecursiveHierarcy
									id={child?.id}
									childs={hierarchyData?.[child]?.childs}
								/>
							</div>
						);
					})}
				</div>
			</>
		);
	};
	return (
		<div>
			<MemberModal ref={modalRef} />
            <UsersModal ref={popupRef} addNewChild={addNewChild} />
			{hierarchyData?.["1"]?.childs?.map((child_id) => {
				return <ArrowComp start={"1"} end={child_id} />;
			})}
			<Breadcrumbs breadcrumbs={[{ name: "Hierarchy Chart", link: "" }]} />
			<div className="relative px-12 w-full">
				<div className="grid grid-cols-1 place-items-center w-full mt-10">
					<div className="place-items-center w-full">
						<div className="flex justify-center items-center">
							<div>
								<MemberPicture
									id={"1"}
									addNewChild={addNewChild}
									payload={hierarchyData?.["1"]}
									openModal={openModal}
                                    popupRef={popupRef}
								/>
							</div>
						</div>
						<RecursiveHierarcy id={"1"} childs={hierarchyData?.["1"]?.childs} />
					</div>
				</div>
			</div>
		</div>
	);
};
