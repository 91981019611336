import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface faqs {
  faqs: any;
  faqInfo: any;
  categories: any;
  categoryInfo: any;
  pages: any;
  pageInfo: any;
  isLoading: any;
  errorMsg: any;
}

const initialState: faqs = {
  faqs: [],
  faqInfo: {},
  categories: [],
  categoryInfo: {},
  pages: [],
  pageInfo: {},
  isLoading: {},
  errorMsg: ""
};

export const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    setFaqs: (state: faqs, { payload }: PayloadAction<any>) => {
      state.faqs = payload;
    },
    setFaqInfo: (state: faqs, { payload }: PayloadAction<any>) => {
      state.faqInfo = payload;
    },
    setDeleteFaq: (state: faqs, { payload }: PayloadAction<any>) => {
      const idToDelete = payload;
      state.faqs.data = state.faqs.data.filter((faq) => faq.faq_id !== idToDelete);
    },
    setCategories: (state: faqs, { payload }: PayloadAction<any>) => {
      state.categories = payload;
    },
    setCategoryInfo: (state: faqs, { payload }: PayloadAction<any>) => {
      state.categoryInfo = payload;
    },
    setDeleteCategory: (state: faqs, { payload }: PayloadAction<any>) => {
      const idToDelete = payload;
      state.categories.data = state.categories.data.filter((cate) => cate.faq_category_id !== idToDelete);
    },
    setPages: (state: faqs, { payload }: PayloadAction<any>) => {
      state.pages = payload;
    },
    setPageInfo: (state: faqs, { payload }: PayloadAction<any>) => {
      state.pageInfo = payload;
    },
    setDeletePage: (state: faqs, { payload }: PayloadAction<any>) => {
      const idToDelete = payload;
      state.pages.data = state.pages.data.filter((page) => page.id !== idToDelete);
    },
    setAddCategory: (state: faqs, { payload }: PayloadAction<any>) => {
      state.categories?.data.unshift(payload);
    },
     setAddPage: (state: faqs, { payload }: PayloadAction<any>) => {
      state.pages?.data.unshift(payload);
    },
    setLoading: (state: faqs, { payload }: PayloadAction<any>) => {
      state.isLoading = { ...state.isLoading, ...payload };
    },
    setErrorMsg: (state: faqs, { payload }: PayloadAction<any>) => {
        state.errorMsg = payload;
    },
    reset: (state: faqs) => initialState,
  },
});

export const fetchFaqs = (data: any) => async (dispatch: any) => {
  dispatch(setLoading({ fetchFaqs: true }));
  const json = await Api.faqs(data);
    if (json?.status === 200) {
      dispatch(setFaqs(json?.data?.data));
      dispatch(setLoading({ fetchFaqs: false }));
    } else {
        dispatch(setLoading({ fetchFaqs: false }));
      }
  return json;
};

export const getFaq = (id: any) => async (dispatch: any) => {
    dispatch(setLoading({ getFaq: true }));
    const json = await Api.faqInfo(id);
      if (json?.status === 200) {
        dispatch(setFaqInfo(json?.data?.data));
        dispatch(setLoading({ getFaq: false }));
      } else {
        dispatch(setLoading({ getFaq: false }));
      }
    return json;
  };

  export const addFaq = (data: any, navigate: any) => async (dispatch: any) => {
  dispatch(setLoading({ addFaq: true }));
  const json = await Api.addFaq(data);
    if (json?.status === 200 || json?.status === 201) {
      toastNotification({status: "success", message: json?.data?.message});
      dispatch(setLoading({ addFaq: false }));
      navigate('/contents/faqs');
    } else {
      toastNotification({status: "error", message: json?.response?.data?.[0] || "Please try again"})
      dispatch(setLoading({ addFaq: false }));
      }
  return json;
};

export const deleteFaq = (id: any) => async (dispatch: any) => {
  dispatch(setLoading({ deleteFaq: true }));
  const json = await Api.deletefaq(id);
  dispatch(setLoading({ deleteFaq: false }));
  if (json?.status === 200) {
      dispatch(setDeleteFaq(id));
      toastNotification({status: "success", message: json?.data?.message || "Faq Deleted Successfully!"})
    } else {
      toastNotification({status: "error", message: "Please try again"})
    }
  return json;
};

export const fetchCategories = (data: any) => async (dispatch: any) => {
  dispatch(setLoading({ fetchCategories: true }));
  const json = await Api.faqCategories(data);
  dispatch(setLoading({ fetchCategories: false }));
  if (json?.status === 200) {
      dispatch(setCategories(json?.data?.data));
    }
  return json;
};

export const getCategory = (id: any) => async (dispatch: any) => {
  dispatch(setLoading({ getCategory: true }));
  const json = await Api.faqCategoryInfo(id);
  dispatch(setLoading({ getCategory: false }));
    if (json?.status === 200) {
      dispatch(setCategoryInfo(json?.data?.data));
    }
  return json;
};

export const addCategory = (data: any, closeModal: any) => async (dispatch: any) => {
  dispatch(setLoading({ addCategory: true }));
  const json = await Api.addFaqCategory(data);
  dispatch(setLoading({ addCategory: false }));
    if (json?.status === 200 || json?.status === 201) {
      dispatch(setAddCategory(json?.data?.data));
      toastNotification({status: "success", message: json?.data?.message});
      closeModal();
    } else {
      toastNotification({status: "error", message: json?.data?.message || "Please try again"})
    }
  return json;
};

export const editCategory = (data: any, closeModal: any) => async (dispatch: any) => {
  dispatch(setLoading({ editCategory: true }));
  const json = await Api.addFaqCategory(data);
  dispatch(setLoading({ editCategory: false }));
    if (json?.status === 200 || json?.status === 201) {
      dispatch(setCategoryInfo(json?.data?.data));
      toastNotification({status: "success", message: json?.data?.message});
      closeModal();
    } else {
      toastNotification({status: "error", message: json?.data?.message || "Please try again"})
      }
  return json;
};

  export const deleteCategory = (id: any) => async (dispatch: any) => {
    dispatch(setLoading({ deleteCategory: true }));
    const json = await Api.deleteFaqCategory(id);
    dispatch(setLoading({ deleteCategory: false }));
    if (json?.status === 200) {
        dispatch(setDeleteCategory(id));
        toastNotification({status: "success", message: json?.data?.message || "Category Deleted Successfully!"})
      } else {
        toastNotification({status: "error", message: "Please try again"})
      }
    return json;
  };

  export const fetchPages = (data: any) => async (dispatch: any) => {
  dispatch(setLoading({ fetchPages: true }));
  const json = await Api.faqPages(data);
  dispatch(setLoading({ fetchPages: false }));
  if (json?.status === 200) {
      dispatch(setPages(json?.data?.data));
    }
  return json;
};

export const getPage = (id: any) => async (dispatch: any) => {
  dispatch(setLoading({ getPage: true }));
  const json = await Api.faqPageInfo(id);
  dispatch(setLoading({ getPage: false }));
    if (json?.status === 200) {
      dispatch(setPageInfo(json?.data?.data));
    }
  return json;
};

export const addPage = (data: any, closeModal: any) => async (dispatch: any) => {
  dispatch(setLoading({ addPage: true }));
  const json = await Api.addFaqPage(data);
  dispatch(setLoading({ addPage: false }));
    if (json?.status === 200 || json?.status === 201) {
      dispatch(setAddPage(json?.data?.data));
      toastNotification({status: "success", message: json?.data?.message || "Page Added Successfully"});
      closeModal();
    } else {
      toastNotification({status: "error", message: json?.data?.message || "Please try again"})
    }
  return json;
};

export const editPage = (data: any, closeModal: any) => async (dispatch: any) => {
  dispatch(setLoading({ editPage: true }));
  const json = await Api.addFaqPage(data);
  dispatch(setLoading({ editPage: false }));
    if (json?.status === 200 || json?.status === 201) {
      dispatch(setPageInfo(json?.data?.data));
      toastNotification({status: "success", message: json?.data?.message || "Page Updated Successfully"});
      closeModal();
    } else {
      toastNotification({status: "error", message: json?.data?.message || "Please try again"})
    }
  return json;
};

  export const deletePage = (id: any) => async (dispatch: any) => {
    dispatch(setLoading({ deletePage: true }));
    const json = await Api.deleteFaqPage(id);
    dispatch(setLoading({ deletePage: false }));
    if (json?.status === 200) {
        dispatch(setDeletePage(id));
        toastNotification({status: "success", message: json?.data?.message || "Page Deleted Successfully!"})
      } else {
        toastNotification({status: "error", message: "Please try again"})
      }
    return json;
  };

export const {
  setFaqs,
  setFaqInfo,
  setCategories,
  setLoading,
  setErrorMsg,
  setCategoryInfo,
  setDeleteFaq,
  setDeleteCategory,
  setPages,
  setPageInfo,
  setDeletePage,
  setAddCategory,
  setAddPage,
  reset: resetFaqs
} = faqsSlice.actions;
export default faqsSlice.reducer;
