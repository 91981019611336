import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	fetchCaseStudies,
	deleteCaseStudy,
} from "../../redux/caseStudiesSlice.ts";
import { Pagination } from "../../components/pagination/index.js";
import { AddBtn } from "../../components/addBtn/index.js";
import { FilterBtn } from "../../components/filterBtn/index.js";
import { FilterField } from "../../components/filterField/index.js";
import DeleteModal from "../../components/deleteModal/index.js";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import { removeEmptyString } from "../../helper/removeEmpty.js";

export const CaseStudies = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { caseStudies, isLoading } = useSelector((store) => store.caseStudies);
	const [params, setParams] = useState({ page: 1 });
	const [id, setId] = useState(null);
	const deleteRef = useRef(null);

	useEffect(() => {
		dispatch(fetchCaseStudies({ params: params }));
	}, []);

	function handlePage(page) {
		dispatch(fetchCaseStudies({ params: removeEmptyString({ ...params, page: page }) }));
	}

	const handleSearch = () => {
		dispatch(fetchCaseStudies({ params: removeEmptyString(params) }));
	};

	const handleClear = () => {
		setParams({});
		dispatch(fetchCaseStudies({ params: {} }));
	};

	const handleClearDate = () => {
		const param = { ...params, startDate: "", endDate: "", page: 1 };
		setParams(param);
		dispatch(fetchCaseStudies({ params: removeEmptyString(param) }));
	};

	const handleView = (id) => navigate(`/contents/case-studies/${id}/details`);

	const handleDelete = (id) => {
		setId(id);
		deleteRef?.current?.open({
			id: id,
			deleteFun: deleteCaseStudy,
			type: "Case Study",
		});
	};

	const tableData = [
		{ value: "title", to: `/contents/case-studies/{id}/details` },
		{ key: "Subtitle", value: "sub_title" },
		{ key: "Created", value: "created_at", type: "date" },
	];

	const actions = [
		{ type: "view", func: handleView, isLoading: false },
		{
			type: "delete",
			func: handleDelete,
			isLoading: isLoading?.deleteCaseStudy,
		},
	];

	return (
		<>
			<Breadcrumbs breadcrumbs={[
				{name: "Case Studies", link: ''},
			]} />
			<div className="mb-1 relative mx-12">
				<DeleteModal ref={deleteRef} />
				<div className="flex justify-between items-center space-x-10">
					<AddBtn
						to={"/contents/case-studies/add"}
						mt={5}
						text={"Add Case Study"}
					/>
					<div className="flex flex-col">
						<div className="flex flex-row gap-5">
							<FilterField
								param={params}
								setParam={setParams}
								type={"date range"}
							/>
							<div className="mt-9">
								<FilterBtn
									disabled={!params?.endDate && !params?.startDate}
									func={handleClearDate}
									text={"Clear"}
									color={"red"}
								/>
							</div>
						</div>
					</div>

					<div className="flex">
						<div className="flex flex-col pr-3">
							<FilterField param={params} setParam={setParams} type={"title"} />
						</div>
						<div className="flex justify-center items-center px-2 mt-6 gap-2">
							<FilterBtn
								disabled={
									!params?.title && (!params?.endDate || !params?.startDate)
								}
								func={handleSearch}
							/>
							<FilterBtn
								func={handleClear}
								disabled={!params?.title}
								text={"Clear"}
								color={"red"}
							/>
						</div>
					</div>
				</div>
				<div className="h-[calc(100vh-290px)] overflow-auto mt-5 hide-scroll">
					<Table
						data={tableData}
						table={caseStudies}
						actions={actions}
						id={id}
						isLoading={isLoading?.fetchCaseStudies}
					/>
				</div>
				<Pagination
					total={caseStudies?.last_page}
					current={caseStudies?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
