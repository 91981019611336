import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface Trainners {
  trainers: any;
  trainer: any;
  isLoading: any;
}

const initialState: Trainners = {
  trainers: {},
  trainer: {},
  isLoading: {},
};

export const trainersSlice = createSlice({
  name: "trainer",
  initialState,
  reducers: {
    setTrainers: (state: Trainners, { payload }: PayloadAction<any>) => {
      state.trainers = payload;
    },
    setTrainerInfo: (state: Trainners, { payload }: PayloadAction<any>) => {
      state.trainer = payload;
    },
    setDeleteTrainer: (state: Trainners, { payload }: PayloadAction<any>) => {
      const idToDelete = payload;
      state.trainers.data = state.trainers.data.filter(
        (trainer) => trainer.id !== idToDelete
      );
    },
    setStatusUpdate: (state: Trainners, { payload }: PayloadAction<any>) => {
      state.trainers = {
        ...state.trainers,
        data: state.trainers?.data?.map((member) => {
          return payload === member?.id
            ? { ...member, is_active: member?.is_active === 1 ? 0 : 1 }
            : member;
        }),
      };
    },

    setLoader: (state: Trainners, { payload }: PayloadAction<any>) => {
      state.isLoading = { ...state.isLoading, ...payload };
    },
    reset: (state: Trainners) => initialState,
  },
});

export const fetchTrainers = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ trainers: true }));
  const json = await Api.getTrainers(data);
  dispatch(setLoader({ trainers: false }));
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setTrainers(json?.data?.data));
    }
  }
};

export const fetchTrainerInfo = (id: any) => async (dispatch: any) => {
  dispatch(setLoader({ trainerInfo: true }));
  const json = await Api.trainerInfo(id);
  dispatch(setLoader({ trainerInfo: false }));
  if (json?.status === 200) {
    dispatch(setTrainerInfo(json?.data?.data?.data[0]));
  }
  return json;
};

export const addUpdateTrainer =
  (data: any, navigate: any) => async (dispatch: any) => {
    dispatch(setLoader({ addUpdateTrainer: true }));
    const json = await Api.addUpdateTrainer(data);
    dispatch(setLoader({ addUpdateTrainer: false }));
    if (json?.status === 200) {
      toastNotification({ status: "success", message: json?.data?.message });
      navigate("/contents/trainers");
    } else if (!!json && json?.response?.status === 422) {
      const message =
        json?.response?.data?.error?.[
          Object.keys(json?.response?.data?.error)[0]
        ][0];
      toastNotification({
        status: "error",
        message: message || "Error!",
      });
    }
    return json;
  };

export const updateTrainerStatus = (id: any) => async (dispatch: any) => {
  dispatch(setLoader({ updateTrainerStatus: true }));
  const json = await Api.updateTrainerStatus(id);
  dispatch(setLoader({ updateTrainerStatus: false }));
  if (json?.status === 200) {
    dispatch(setStatusUpdate(id));
    toastNotification({
      status: "success",
      message: json?.data?.message || "Team Updated Successfully!",
    });
  } else if (!!json && json?.response?.status === 422) {
    const message =
      json?.response?.data?.errors?.[
        Object.keys(json?.response?.data?.errors)[0]
      ][0];
    toastNotification({
      status: "error",
      message: message || "Error!",
    });
  }
  return json;
};

export const deleteTrainer = (id: any) => async (dispatch: any) => {
  dispatch(setLoader({ deleteTrainer: true }));
  const json = await Api.deleteTrainer(id);
  dispatch(setLoader({ deleteTrainer: false }));
  if (json?.status === 200) {
    dispatch(setDeleteTrainer(id));
    toastNotification({
      status: "success",
      message: json?.data?.message || "Trainer Deleted Successfully!",
    });
  } else {
    toastNotification({ status: "error", message: "Please try again" });
  }
  return json;
};

export const {
  setTrainers,
  setStatusUpdate,
  setTrainerInfo,
  setLoader,
  setDeleteTrainer,
  reset: resetTrainers,
} = trainersSlice.actions;
export default trainersSlice.reducer;
