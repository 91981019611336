import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { teamMemberInfo } from "../../../redux/teamSlice.ts";
import { Loader } from "../../../components/loader/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const TeamInfo = () => {
	const { id } = useParams();
	const { memberInfo, isLoading } = useSelector((store) => store.team);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(teamMemberInfo(id));
	}, []);

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Our Team", link: "/contents/team" },
					{ name: "Details", link: "" },
				]}
			/>
			{isLoading?.memberInfo ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader w="20" h="20" color="orange" />
				</div>
			) : (
				<div className="relative overflow-x-auto h-full px-[55px] py-5">
					<table className="w-full text-sm text-left mb-5 rtl:text-right text-black shadow-2xl rounded-lg">
						<tbody>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									ID
								</th>
								<td className="px-6 py-2">{memberInfo?.id}</td>
								<td className="w-[160px] px-3 py-4">
									<AddBtn
										to={`/contents/team/edit/${memberInfo?.id}`}
										text={"Edit Details"}
									/>
								</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Name
								</th>
								<td className="px-6 py-4">{memberInfo?.name}</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Title
								</th>
								<td className="px-6 py-4">{memberInfo?.title}</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Description
								</th>
								<td
									className="px-6 py-4"
									dangerouslySetInnerHTML={{ __html: memberInfo?.description }}
								></td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Quote
								</th>
								<td className="px-6 py-4">{memberInfo?.quote}</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Order_By
								</th>
								<td className="px-6 py-4">{memberInfo?.order_by}</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									LinkedIn
								</th>
								<td className="px-6 py-4">{memberInfo?.linkedin}</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Active
								</th>
								<td className="px-6 py-4">
									{memberInfo?.is_active === 1 ? "True" : "False"}
								</td>
							</tr>
							{memberInfo?.image_path && 
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Image
								</th>
								<td className="px-6 py-4">
									<div className="mt-2">
										<img
											src={memberInfo?.image_path}
											alt="team"
											className="max-w-[40px]"
										/>
									</div>
								</td>
							</tr>}
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Created At
								</th>
								<td className="px-6 py-4">
									{memberInfo?.created_at?.slice(0, 10)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}
		</>
	);
};
