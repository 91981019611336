import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";

interface Hierarchy {
	users: any;
	isLoading: any;
}

const initialState: Hierarchy = {
	users: {},
	isLoading: {},
};

export const hierarchySlice = createSlice({
	name: "hierarchy",
	initialState,
	reducers: {
		setUsers: (state: Hierarchy, { payload }: PayloadAction<any>) => {
			state.users = payload;
		},
		setLoader: (state: Hierarchy, { payload }: PayloadAction<any>) => {
			state.isLoading = { ...state.isLoading, ...payload };
		},
		reset: (state: Hierarchy) => initialState,
	},
});

export const fetchBsUsers = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ users: true }));
	const json = await Api.getBsUsers(data);
	dispatch(setLoader({ users: false }));
	if (json !== undefined) {
		if (200 === json?.status) {
			dispatch(setUsers(json?.data?.data));
		}
	}
};

export const {
	setUsers,
	setLoader,
	reset: resetHierarchy,
} = hierarchySlice.actions;
export default hierarchySlice.reducer;
