export const entries = [
  { type: "text", name: "title", text: "Job Title" },
  { type: "text", name: "location", text: "Location" },
  {
    type: "text",
    name: "employment_id",
    text: "Employment",
    isSelect: true,
    tname: "employment_type",
    ename: "name",
  },
  {
    type: "text",
    name: "seniority_id",
    text: "Seniority",
    isSelect: true,
    tname: "seniority_level",
    ename: "seniority_name",
  },
  {
    type: "text",
    name: "education_id",
    text: "Education",
    isSelect: true,
    tname: "education_level",
    ename: "education_level",
  },
  {
    type: "text",
    name: "skills",
    text: "Skills",
    isSelect: true,
    tname: "skills",
    ename: "title",
  },
  {
    type: "text",
    name: "categories",
    text: "Categories",
    isSelect: true,
    tname: "job_categories",
    ename: "name",
  },
  {
    type: "text",
    name: "experience_id",
    text: "Experience",
    isSelect: true,
    tname: "experience",
    ename: "experience",
  },
  {
    type: "text",
    name: "company_id",
    name2: 'external_company_id',
    text: "Company",
    isSelect: true,
    tname: "companies",
    ename: "title",
  },
  { type: "url", name: "external_link", text: "External Link", optional: true },
  { type: "date", name: "start_date", text: "Start Date of Job" },
  { type: "date", name: "end_date", text: "End Date of Job" },
  { type: "text", name: "postal_code", text: "Postal Code", optional: true },
  { type: "text", name: "city", text: "City", optional: true },
];
