import { useEffect, useState } from "react";
import Api from "../../../services/api";
import { toastNotification } from "../../../services/notification-service";
import { Loader } from "../../../components/loader";
import { Breadcrumbs } from "../../../components/breadcrumbs";

export const InfluencerFilters = () => {
	const [param, setParam] = useState({});
	const [tags, setTags] = useState({});
	const [loader, setLoader] = useState({});

	const fetchCategories = async () => {
		let json = await Api.jobCategories();
		if (!!json && json?.status === 200) {
			setTags(json?.data);
		}
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	const handleAdd = async () => {
		if (param?.name) {
			setLoader({...loader, add: true});
			const json = await Api.handleJobFilter({
				option: "company_categories",
				name: param?.name,
			});
			setLoader({...loader, add: false});
			if (!!json && json?.status === 201) {
				toastNotification({
					status: "success",
					message: "Category Added successfully!",
				});
                setTags({...tags, company_categories: json?.data?.data});
				setParam({});
			} else if (json?.response?.status === 409) {
                toastNotification({
					status: "warning",
					message: json?.response?.data?.message
				});
            } else {
				toastNotification({ status: "error", message: "Error adding category!" });
			}
		}
	};

	const handleDelete = async () => {
		if (param?.id) {
			setLoader({...loader, delete: true});
			const json = await Api.handleJobFilter({
				id: param?.id,
				option: "company_categories",
			});
			setLoader({...loader, delete: false});
			if (!!json && json?.status === 200) {
				toastNotification({
					status: "success",
					message: "Tag deleted successfully!",
				});
                setTags({...tags, company_categories: json?.data?.data});
				setParam({});
			} else {
				toastNotification({
					status: "error",
					message: "Error deleting tag filter!",
				});
			}
		}
	};

	return (
		<>
			<Breadcrumbs breadcrumbs={[
				{name: "Influencers", link: "/influnecers/profiles"},
				{name: "Filters", link: ""},
			]} />
			<div className="mb-1 relative mx-12 rounded-lg p-5 shadow-lg pb-10">
				<div className="text-xl text-orange-500 mx-5">Influencers Filter</div>
				<div className="flex justify-start items-center my-5">
					<div className="mx-5  space-y-3">
						<label className="font-light">Add Tag</label>
						<input
							onChange={(e) => setParam({ ...param, name: e.target.value })}
							value={param?.name || ''}
                            placeholder="Type new tag name..."
							type="text"
							className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
						/>
					</div>
					<button
						onClick={() => handleAdd()}
						className="mt-9 border-blue-600 text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
					>
						{loader?.add ? <Loader w="6" h="6" color={'blue'} /> :"Add"}
					</button>
				</div>
				<div className="flex justify-start items-center mt-5">
					<div className="mx-5 space-y-3">
						<label className="font-light">Delete Tag</label>
						<select
							onChange={(e) => setParam({ ...param, id: e.target.value })}
							value={param?.id || ''}
							type="text"
							className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
						>
							<option value={null}>Select</option>
							{tags?.company_categories?.map((category) => {
								return (
									<option value={category?.id}>
										{category?.name}
									</option>
								);
							})}
						</select>
					</div>
					<button
						onClick={() => handleDelete()}
						className="mt-9 border-red-600 text-red-600 hover:bg-red-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
					>
						{loader?.delete ? <Loader w="6" h="6" color={'red'} /> :"Delete"}
					</button>
				</div>
			</div>
		</>
	);
};
