import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "../../../components/modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanies } from "../../../redux/jobsSlice.ts";
import { toastNotification } from "../../../services/notification-service.js";

const durationButtons = [
	{ name: "Past 7 days", value: 7 },
	{ name: "Past month", value: 30 },
	{ name: "Past 3 months", value: 90 },
	{ name: "Past 6 months", value: 180 },
	{ name: "Past year", value: 365 },
];

const ExportModal = (props, ref) => {
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();
	const { companies } = useSelector((store) => store.jobs);
	const [query, setQuery] = useState({ days: 90 });

	useImperativeHandle(ref, () => ({
		open() {
			setShow(true);
		},
	}));

	useEffect(() => {
		dispatch(fetchCompanies());
	}, []);

	const handleExport = () => {
		if (!query?.company) {
			return toastNotification({
				status: "error",
				message: "Select a company",
			});
		}
		const queryString = new URLSearchParams(query).toString();
		const url = `${process.env.REACT_APP_SERVER_URL}/api/export-jobs?${queryString}`;
		window.open(url, "_blank");
	};

	return (
		<Modal show={show} setShow={setShow}>
			<div className="text-lg mt-4">
				<div className="text-xl">Company Wise Export</div>
				<div className="flex flex-col mt-4 gap-y-2">
					<label className="font-light">Select a Company</label>
					<select
						type="string"
						id="companies"
						name={"company"}
						onChange={(e) => setQuery({ ...query, company: e.target.value })}
						value={query?.company}
						className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
						placeholder=" "
					>
						<option value={""}>Select</option>
						{(companies || [])
							?.slice()
							//?.filter((company) => !company?.is_original)
							.sort((a, b) => a?.title?.localeCompare(b?.title))
							.map((comp) => (
								<option key={comp?.id} value={comp?.id}>
									{comp?.title}
								</option>
							))}
					</select>
				</div>
				<div className="flex flex-col mt-4 gap-y-3">
					<label className="font-light">Select Duration</label>
					<div className="flex justify-start items-start gap-2 flex-wrap">
						{durationButtons?.map((but, index) => {
							return (
								<button
									onClick={() => setQuery({ ...query, days: but?.value })}
									className={`${
										but?.value === query?.days ? "bg-orange-600 text-white" : ""
									} border-orange-600 border-2 text-sm rounded-full px-3 py-1`}
								>
									{but?.name}
								</button>
							);
						})}
					</div>
				</div>
				<button
					onClick={handleExport}
					className="mt-5 border-orange-600 capitalize bg-orange-600 px-3 py-1 border-2 rounded-lg text-white hover:opacity-90 transition-all duration-200 ease-in-out"
				>
					Export
				</button>
			</div>
		</Modal>
	);
};

export default forwardRef(ExportModal);
