import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface Buyer {
	bank: any;
	categories: any;
	isLoading: any;
}

const initialState: Buyer = {
	bank: {},
	categories: [],
	isLoading: {},
};

export const buyerSlice = createSlice({
	name: "buyer",
	initialState,
	reducers: {
		setKnowledgeBank: (state: Buyer, { payload }: PayloadAction<any>) => {
			state.bank = payload;
		},
		setAddBank: (state: Buyer, { payload }: PayloadAction<any>) => {
			state.bank = {
				...state.bank,
				[payload?.category_name]: [
					...(state.bank?.[payload?.category_name] || []),
					payload,
				],
			};
		},
		setDeleteKnowledgeBank: (state: Buyer, { payload }: PayloadAction<any>) => {
            if (state.bank?.[payload?.category_name]?.length === 1) {
                delete state.bank?.[payload?.category_name];
                return; 
            }
			state.bank = {
				...state.bank,
				[payload?.category_name]: state.bank?.[payload?.category_name]?.filter(
					(b) => b?.id !== payload?.id
				),
			};
		},
		setKnowledgeBankCategory: (state: Buyer, { payload }: PayloadAction<any>) => {
			state.categories = payload;
		},
		setLoader: (state: Buyer, { payload }: PayloadAction<any>) => {
			state.isLoading = { ...state.isLoading, ...payload };
		},
		reset: (state: Buyer) => initialState,
	},
});

export const fetchKnowledgeBank = () => async (dispatch: any) => {
	dispatch(setLoader({ knowledgeBank: true }));
	const json = await Api.knowledgeBank();
	dispatch(setLoader({ knowledgeBank: false }));
	if (json?.status === 200) {
		dispatch(setKnowledgeBank(json?.data));
	}
	return json;
};

export const addKnowledgeBank = (data: any, setData, id, setEditData) => async (dispatch: any) => {
	dispatch(setLoader({ addBank: true }));
	const json = await Api.addKnowledgeBank(data, id);
	dispatch(setLoader({ addBank: false }));
	if (!!json && json?.status === 201) {
		setData({});
		const inputThumb:any = document.getElementById('dropzone-thumb');
        if (inputThumb) {
            inputThumb.value = '';
        }
		const inputpdf:any = document.getElementById('dropzone-pdf');
        if (inputpdf) {
            inputpdf.value = '';
        }
		dispatch(setAddBank(json?.data?.data));
		toastNotification({
			status: "success",
			message: "Knowledge Bank Added Successfully!",
		});
	} else if (!!json && json?.status === 200) {
		const inputThumb:any = document.getElementById('dropzone-thumb');
        if (inputThumb) {
            inputThumb.value = '';
        }
		const inputpdf:any = document.getElementById('dropzone-pdf');
        if (inputpdf) {
            inputpdf.value = '';
        }
		dispatch(setKnowledgeBank(json?.data?.data));
		toastNotification({
			status: "success",
			message: "Knowledge Bank Edited Successfully!",
		});
		setEditData({});
		setData({});
	} else {
		toastNotification({
			status: "error",
			message: json?.response?.data?.error || "Error!",
		});
	}
	return json;
};

export const deleteKnowledgeBank = (data) => async (dispatch: any) => {
	dispatch(setLoader({ deleteBank: true }));
	const json = await Api.deleteKnowledgeBank(data?.id);
	dispatch(setLoader({ deleteBank: false }));
	if (json?.status === 200) {
		dispatch(setDeleteKnowledgeBank(data));
		toastNotification({
			status: "success",
			message: "Knowledge Bank Deleted Successfully!",
		});
	} else {
		toastNotification({
			status: "error",
			message: "Error!",
		});
	}
	return json;
};

export const addCategory = (data) => async (dispatch: any) => {
	dispatch(setLoader({ addCategory: true }));
	const json = await Api.addKnowledgeBankCategory(data);
	dispatch(setLoader({ addCategory: false }));
	if (json?.status === 201) {
		toastNotification({
			status: "success",
			message: json?.data?.status || "Category Added!",
		});
		dispatch(setKnowledgeBankCategory(json?.data?.categories))
	} else if (json?.status === 200) {
		toastNotification({
			status: "warning",
			message: json?.data?.status || "Category Already Exist!",
		});
	} else {
		toastNotification({
			status: "error",
			message: json?.data?.status || "Error Adding Category!",
		});
	}
	return json;
};

export const fetchCategories = () => async (dispatch: any) => {
	const json = await Api.getKnowledgeBankCategory();
	if (json?.status === 200) {
		dispatch(setKnowledgeBankCategory(json?.data))
	}
	return json;
};

export const {
	setKnowledgeBank,
	setAddBank,
	setDeleteKnowledgeBank,
	setKnowledgeBankCategory,
	setLoader,
	reset: resetBuyer,
} = buyerSlice.actions;
export default buyerSlice.reducer;
