import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNewsletter,
  fetchNewsCategories,
  fetchNewsletters,
} from "../../redux/others.ts";
import AddNew from "./addNew.js";
import { useRef } from "react";
import { Pagination } from "../../components/pagination/index.js";
import { FilterBtn } from "../../components/filterBtn/index.js";
import { AddBtn } from "../../components/addBtn/index.js";
import { FilterField } from "../../components/filterField/index.js";
import DeleteModal from "../../components/deleteModal/index.js";
import { isEmpty } from "../../helper/join.js";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";

export const Newsletters = () => {
  const dispatch = useDispatch();
  const { newsletters, isLoading } = useSelector((store) => store.other);
  const [params, setParams] = useState({});
  const [id, setId] = useState();
  const popupRef = useRef(null);
  const deleteRef = useRef(null);

  const addUpdatePopUp = (data) => {
    popupRef?.current?.open(data);
  };

  useEffect(() => {
    if (isEmpty(newsletters)) {
      dispatch(fetchNewsletters({ params: params }));
      dispatch(fetchNewsCategories());  
    }
  }, []);

  function handlePage(page) {
    dispatch(fetchNewsletters({ params: { ...params, page: page } }));
  }

  const handleClear = () => {
    setParams({});
    dispatch(fetchNewsletters({ params: {} }));
  };

  const handleEdit = (id) => {
    addUpdatePopUp({ id: id, status: "Update" });
    setId(id);
  }

  const handleDelete = (id) => {
    setId(id)
    deleteRef?.current?.open({
      id: id,
      deleteFun: deleteNewsletter,
      type: "Newsletter",
    })
  }

  const tableData = [
		{value: "title", to: '', img: "thumbnail"},
		{key: "Category", value1: "category", nested: true, value2: "name"},
		{key: "Created", value: "created_at"},
	];

	const actions = [
		{type: 'edit', func: handleEdit, isLoading: false},
		{type: 'delete', func: handleDelete, isLoading: isLoading?.deleteNewsLetter}
	];

  const handleSearch = () => dispatch(fetchNewsletters({ params: params }))

  return (
    <>
      <Breadcrumbs breadcrumbs={[
				{name: 'NewsLetters', link: ''},
			]} />
      <div className="mx-12 relative">
        <DeleteModal ref={deleteRef} />
        <div className="flex justify-start items-center space-x-10 my-2">
          <AddBtn
            func={() => {
              addUpdatePopUp({ status: "Add" });
              setId(null);
            }}
            mt={6}
            text={"Add"}
          />
          <FilterField
            param={params}
            setParam={setParams}
            type={"title"}
          />
          <FilterField
            param={params}
            setParam={setParams}
            type={"created"}
          />
          <div className="mt-7">
            <FilterBtn 
              func={handleClear}
              disabled={!params?.created && !params?.title}
              text={"Clear"}
              color={"red"}
            />
          </div>
          <div className="mt-7">
            <FilterBtn 
              func={handleSearch}
              disabled={!params?.created && !params?.title}
            />
          </div>
        </div>
        <div className="h-[calc(100vh-270px)] overflow-auto hide-scroll">
          <Table data={tableData} table={newsletters} actions={actions} id={id} isLoading={isLoading?.newsletters} />
        </div>
        <Pagination
          total={newsletters?.last_page}
          current={newsletters?.current_page}
          func={handlePage}
        />
        <AddNew ref={popupRef} />
      </div>
    </>
  );
};
