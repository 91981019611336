import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "../../../components/modal";

const View = ({ rfq }, ref) => {
	const [show, setShow] = useState(false);
	const [data, setData] = useState({});

	useImperativeHandle(ref, () => ({
		open(data) {
			setShow(true);
			setData(data);
		},
	}));

	return (
		<Modal size={"md"} show={show} setShow={setShow}>
			<div className="flex justify-start flex-col items-start gap-y-2">
				<h1 className="text-lg font-semibold">{data?.user_name}</h1>

				<h1 className="text-lg font-light">Email: {data?.user_email}</h1>
				{!rfq && (
					<h1 className="text-lg font-light">
						Ticket Type: {data?.ticket_type}
					</h1>
				)}
				<h1 className="text-lg font-light">
					{rfq ? "Company" : "Reason"}: {data?.reason}
				</h1>
				<h1 className="text-lg font-semibold">
					{rfq ? "Purpose/Requirement Details" : "Description"}:
				</h1>
				<div className="text-lg font-light">
					{data?.description || (rfq
						? "No Purpose/Requirement Details provided!"
						: "No description provided!")}
				</div>
			</div>
		</Modal>
	);
};

export default forwardRef(View);
