import { useDispatch, useSelector } from "react-redux";
import { fetchReports } from "../../../redux/others.ts";
import { fetchCountries } from "../../../redux/others.ts";

export const FilterReport = ({param, setParam}) => {
    const dispatch = useDispatch();
    const { countries } = useSelector((store) => store.other);

    const SearchType = [
		{ id: 1, type: "Search By Profile" },
		{ id: 2, type: "Search By Question" },
		{ id: 3, type: "Search By Answer" },
		{ id: 4, type: "Search By Activity" },
		{ id: 5, type: "Search Top 10 users by Year" },
	];

	return (
		<form className="flex flex-col my-2 shadow-xl mx-4 rounded-lg py-5 overflow-auto">
			<div className="mx-5 text-gray-600 space-y-3">
				<label className="text-lg">Select Search Option</label>
				<select
					value={param?.search_option}
					onChange={(e) => {
						//if (!!param?.start_date && !!param?.end_date) {
						dispatch(
							fetchReports({
								params: {
									search_option: parseInt(e.target.value),
									start_date: param?.start_date,
									end_date: param?.end_date,
								},
							})
						);
						//}
						setParam({
							search_option: parseInt(e.target.value),
							start_date: param?.start_date,
							end_date: param?.end_date,
						});
					}}
					type="text"
					className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-96"
				>
					{SearchType.map((type) => {
						return <option value={type.id}>{type.type}</option>;
					})}
				</select>
			</div>
			<div className="text-2xl mx-5 text-gray-800 mt-5">
				{SearchType?.find((type) => type.id === param?.search_option).type}
			</div>
			<div className="flex justify-start items-center">
				<div className="mx-5 text-gray-600 space-y-3">
					<label>Select Date Range</label>
					<div className="flex justify-start items-center">
						<input
							type="date"
							onChange={(e) =>
								setParam({ ...param, start_date: e.target.value })
							}
							className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-40"
						/>
						-
						<input
							type="date"
							onChange={(e) => setParam({ ...param, end_date: e.target.value })}
							className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-40"
						/>
					</div>
				</div>

				{param?.search_option === 1 && (
					<>
						<div className="mx-5 text-gray-600 space-y-3">
							<label>Select Country</label>
							<select
								type="text"
								onChange={(e) => {
									setParam({
										...param,
										country_id: parseInt(e.target.value),
									});
									delete param?.state_id;
									dispatch(
										fetchCountries({
											params: {
												type: "states",
												country_id: parseInt(e.target.value),
											},
										})
									);
								}}
								className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-40"
							>
								<option></option>
								{countries?.countries?.data?.map((country) => {
									return <option value={country?.id}>{country?.name}</option>;
								})}
							</select>
						</div>
						<div className="mx-5 text-gray-600 space-y-3">
							<label>Select State</label>
							<select
								onChange={(e) => {
									setParam({
										...param,
										state_id: parseInt(e.target.value),
									});
								}}
								type="text"
								className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-40"
							>
								<option></option>
								{countries?.states?.data?.map((state) => {
									return <option value={state?.id}>{state?.name}</option>;
								})}
							</select>
						</div>
						<div className="flex flex-col mx-5 text-gray-600 space-y-3">
							<label>Not Add Picture</label>
							<div className="col-start-2 col-span-2 relative inline-flex items-center cursor-pointer mb-3">
								<label className="relative inline-flex items-center cursor-pointer">
									<input
										type="checkbox"
										onClick={(e) =>
											setParam({
												...param,
												chk_picture_add: e.target.checked ? 1 : 0,
											})
										}
										className="sr-only peer"
									/>
									<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
								</label>
							</div>
						</div>
						<div className="flex flex-col mx-5 text-gray-600 space-y-3">
							<label>Not Complete Profile</label>
							<div className="col-start-2 col-span-2 relative inline-flex items-center cursor-pointer mb-3">
								<label className="relative inline-flex items-center cursor-pointer">
									<input
										type="checkbox"
										onClick={(e) =>
											setParam({
												...param,
												chk_complete_profile: e.target.checked ? 1 : 0,
											})
										}
										className="sr-only peer"
									/>
									<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
								</label>
							</div>
						</div>
					</>
				)}

				{param?.search_option === 2 && (
					<>
						<div className="mx-5 text-gray-600 space-y-3">
							<label>Select Category</label>
							<select
								type="text"
								className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-40"
							>
								<option></option>
							</select>
						</div>
						<div className="flex flex-col mx-5 text-gray-600 space-y-3">
							<label>Who have not post question</label>
							<div className="col-start-2 col-span-2 relative inline-flex items-center cursor-pointer mb-3">
								<label className="relative inline-flex items-center cursor-pointer">
									<input
										type="checkbox"
										onClick={(e) =>
											setParam({
												...param,
												chk_posted_question: e.target.checked ? 1 : 0,
											})
										}
										className="sr-only peer"
									/>
									<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
								</label>
							</div>
						</div>
					</>
				)}

				{param?.search_option === 3 && (
					<>
						<div className="mx-5 text-gray-600 space-y-3">
							<label>Select Category</label>
							<select
								type="text"
								className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-40"
							>
								<option></option>
							</select>
						</div>
						<div className="flex flex-col mx-5 text-gray-600 space-y-3">
							<label>Who have not post answer</label>
							<div className="col-start-2 col-span-2 relative inline-flex items-center cursor-pointer mb-3">
								<label className="relative inline-flex items-center cursor-pointer">
									<input
										type="checkbox"
										onClick={(e) =>
											setParam({
												...param,
												chk_posted_answer: e.target.checked ? 1 : 0,
											})
										}
										className="sr-only peer"
									/>
									<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
								</label>
							</div>
						</div>
					</>
				)}

				{param?.search_option === 4 && (
					<>
						<div className="flex flex-col mx-5 text-gray-600 space-y-3">
							<label>Who have logged once</label>
							<div className="col-start-2 col-span-2 relative inline-flex items-center cursor-pointer mb-3">
								<label className="relative inline-flex items-center cursor-pointer">
									<input
										type="checkbox"
										checked={param?.chk_activity === 2}
										onClick={(e) =>
											setParam({
												...param,
												chk_activity: e.target.checked ? 2 : 1,
											})
										}
										className="sr-only peer"
									/>
									<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
								</label>
							</div>
						</div>
						<div className="flex flex-col mx-5 text-gray-600 space-y-3">
							<label>Who have not completed their registration</label>
							<div className="col-start-2 col-span-2 relative inline-flex items-center cursor-pointer mb-3">
								<label className="relative inline-flex items-center cursor-pointer">
									<input
										type="checkbox"
										checked={param?.chk_activity === 1}
										onClick={(e) =>
											setParam({
												...param,
												chk_activity: e.target.checked ? 1 : 2,
											})
										}
										className="sr-only peer"
									/>
									<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
								</label>
							</div>
						</div>
					</>
				)}

				<div className="ml-16 mt-8">
					<button
						type="button"
						onClick={() => dispatch(fetchReports({ params: param }))}
						className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-transparent px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
					>
						Search
					</button>
				</div>
			</div>
		</form>
	);
};
