import { useLocation, Link } from "react-router-dom";
import { Faqs } from "../../containers/faqs/index.js";
import { Categories } from "../../containers/faqs/categories/index.js";
import { Pages } from "../../containers/faqs/pages/index.js";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";

export const FaqsHome = () => {
	const location = useLocation();
	const { type } = useParams();

	const aside = [
		{ title: "Faqs", route: `/contents/faqs` },
		{ title: "Categories", route: `/contents/faqs/categories` },
		{ title: "Pages", route: `/contents/faqs/pages` },
	];

	return (
		<div>
			<aside
				id="sidebar-multi-level-sidebar"
				className="fixed top-16 z-30 w-56 h-screen transition-transform -translate-x-full sm:translate-x-0"
				aria-label="Sidebar"
			>
				<div className="h-full px-3 py-4 overflow-y-auto bg-gray-100">
					<ul className="space-y-2 font-medium">
						{aside.map((item) => {
							return (
								<li>
									<Link
										to={item.route}
										className={`flex items-center font-normal p-2 transition rounded-full duration-200 ease-out group ${
											location.pathname === item.route
												? "bg-orange-500 text-white"
												: "text-gray-900 hover:bg-orange-200"
										}`}
									>
										<div className="ms-3">
											{`> ${item.title}`}
										</div>
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			</aside>

			<div className="p-4 ml-52 h-max">
				<Breadcrumbs
					breadcrumbs={[
						{
							name:
								type === "categories"
									? "Categories"
									: type === "pages"
									? "Pages"
									: "Faqs",
							link: "",
						},
					]}
				/>

				<div className="p-4 py-0 border-2 mx-12 border-gray-200 border-dashed rounded-lg">
					{type === "categories" ? (
						<Categories />
					) : type === "pages" ? (
						<Pages />
					) : (
						<Faqs />
					)}
				</div>
			</div>
		</div>
	);
};
