import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	changeJobStatus,
	deleteJob,
	fetchJobs,
	setJobParams,
} from "../../redux/jobsSlice.ts";
import { Pagination } from "../../components/pagination/index.js";
import DeleteModal from "../../components/deleteModal/index.js";
import { debounce } from "lodash";
import { AddBtn } from "../../components/addBtn/index.js";
import Api from "../../services/api.js";
import sample from "../../assets/jobsFormat/jobs_sample.csv";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import { toastNotification } from "../../services/notification-service.js";
import { Loader } from "../../components/loader/index.js";
import { VerticalTable } from "../../components/verticalTable/index.jsx";
import { IoCloudDownloadSharp, IoCloudUpload } from "react-icons/io5";
import { BsFiletypeCsv } from "react-icons/bs";
import ErrorModal from "./errorModal/index.jsx";
import ExportModal from "./ExportModal/index.jsx";
import { MdViewColumn, MdViewModule } from "react-icons/md";

export const Jobs = () => {
	const dispatch = useDispatch();
	const { jobs, isLoading, jobParams } = useSelector((store) => store.jobs);
	const [id, setId] = useState("");
	const [data, setData] = useState(jobParams || { page: 1 });
	const [loader, setLoader] = useState(false);
	const deleteRef = useRef(null);
	const errRef = useRef(null);
	const [isMounted, setIsMounted] = useState(false);
	const [row, setRow] = useState(false);
	const exportRef = useRef(null);

	useEffect(() => {
		if (isMounted) {
			dispatch(fetchJobs({ param: { ...data, page: 1 } }));
			dispatch(setJobParams({ ...data, page: 1 }));
		} else {
			setIsMounted(true);
		}
	}, [data?.created_at, data?.is_published, data?.title]);

	useEffect(() => {
		dispatch(fetchJobs({ param: { ...data } }));
	}, []);

	function handlePage(page) {
		dispatch(fetchJobs({ param: { ...data, page: page } }));
		dispatch(setJobParams({ ...data, page: page }));
	}

	const setJobs = debounce((value) => {
		setData({ ...data, title: value });
	}, 1500);

	const setDate = debounce((value) => {
		let dat = { ...data, created_at: value };
		if (value === "") {
			delete dat["created_at"];
		}
		setData(dat);
	}, 1500);

	const handleStatusChange = (id, status) => {
		setId(id);
		dispatch(
			changeJobStatus({
				id: id,
				key: "is_published",
				value: status == 0 ? 1 : 0,
			})
		);
	};

	const handleDelete = (id) => {
		setId(id);
		deleteRef?.current?.open({
			id: id,
			deleteFun: deleteJob,
			type: "Job",
		});
	};

	const handleFileUpload = async (e) => {
		const files = e.target.files;
		const formData = new FormData();
		formData.append("csv_file", files[0]);
		formData.append("header", true);
		setLoader(true);
		const json = await Api.jobCentralImport({ formData: { formData } });
		const inputThumb = document.getElementById("uploadFile1");
		if (inputThumb) {
			inputThumb.value = "";
		}
		setLoader(false);
		if (!!json) {
			if (json?.status === 200) {
				toastNotification({
					status: "success",
					message: json?.data?.message || "Jobs Uploaded!",
				});
				errRef.current.open(json?.data, false);
				dispatch(fetchJobs({ param: { page: 1 } }));
			} else if (json?.response?.status === 423) {
				toastNotification({
					status: "error",
					message: json?.response?.data?.errors?.file?.[0],
				});
			} else if (
				json?.response?.status === 422 &&
				json?.response?.data?.errors?.message
			) {
				toastNotification({
					status: "error",
					message: json?.response?.data?.errors?.message,
				});
			} else {
				if (
					json.response &&
					json?.response?.data &&
					json?.response?.data?.errors
				) {
					errRef.current.open(json?.response?.data?.errors, true);
				} else {
					toastNotification({
						status: "error",
						message: "Error Uploading Jobs",
					});
				}
			}
		}
	};

	const tableData = [
		{ value: "title", to: "/jobs/detail/{id}", key: "Title" },
		{
			key: "Company Name",
			value1: "companies",
			nested: true,
			value2: "title",
			value11: "external_companies",
		},
		{
			key: "Employment Type",
			value1: "employment",
			nested: true,
			value2: "name",
		},
		{
			key: "Qualification",
			value1: "education",
			nested: true,
			value2: "education_level",
		},
		{ key: "Country", value: "country" },
		{ key: "Created", value: "created_at" },
	];

	const actions = [
		{
			type: "toggle",
			func: handleStatusChange,
			isLoading: isLoading?.jobStatus,
			value: "is_published",
		},
		{ type: "view", link: `/jobs/detail/{id}`, id: "id" },
		{ type: "people", link: `/jobs/view-applicants/{id}`, id:"id" },
		{ type: "delete", func: handleDelete, isLoading: isLoading?.jobDelete },
	];

	return (
		<>
			<ExportModal ref={exportRef} />
			<ErrorModal ref={errRef} />
			<DeleteModal ref={deleteRef} />
			<Breadcrumbs breadcrumbs={[{ name: "Jobs", link: "" }]} />
			<div
				className={`mb-1 relative mx-12 ${
					loader && "opacity-80 pointer-events-none"
				}`}
			>
				<div className="flex md:flex-row flex-col justify-between items-start">
					<div className="flex justify-center items-center gap-2 mt-5">
						<AddBtn text={"Add"} to={"/jobs/add"} />
						<a
							className="flex bg-white justify-center items-center duration-200 gap-x-2 transition-all hover:opacity-70 hover:scale-[0.99] text-orange-500 border-orange-500 border-2 text-base px-3 py-2 outline-none rounded-full w-max cursor-pointer mx-auto"
							href={sample}
							download={"sample.csv"}
						>
							<IoCloudDownloadSharp className="mt-1" size={20} />
							Sample
						</a>
						<label
							for="uploadFile1"
							className={`flex bg-white hover:opacity-70 duration-200 transition-all hover:scale-[1.02] text-orange-500 border-orange-500 border-2 text-base px-3 py-2 outline-none rounded-full w-max cursor-pointer mx-auto`}
						>
							{loader ? (
								<Loader color={"orange"} w={"5"} h={"5"} />
							) : (
								<IoCloudUpload size={20} className="mt-1" />
							)}
							<span className="ml-2">Upload</span>
							<input
								onChange={handleFileUpload}
								type="file"
								id="uploadFile1"
								className="hidden"
								accept=".csv"
							/>
						</label>
						<button
							onClick={() => exportRef.current.open()}
							className={`flex gap-x-2 bg-white hover:opacity-70 duration-200 transition-all hover:scale-[1.02] text-orange-500 border-orange-500 border-2 text-base px-3 py-2 outline-none rounded-full w-max cursor-pointer mx-auto`}
						>
							<BsFiletypeCsv size="20" />
							<div>Export</div>
						</button>
					</div>
					<div className="flex justify-center gap-5">
						<div className="flex flex-col">
							<label className="font-light">Job Title</label>
							<input
								onChange={(e) => {
									const inputValue = e.target.value;
									setJobs.cancel();
									setJobs(inputValue);
								}}
								disabled={isLoading?.fetchJobs}
								defaultValue={jobParams?.title || ""}
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
							/>
						</div>
						<div className="flex flex-col">
							<label className="font-light">Status</label>
							<select
								onChange={(e) => {
									let dat = { ...data, is_published: Number(e.target.value) };
									if (e.target.value === "") {
										delete dat["is_published"];
									}
									setData(dat);
								}}
								defaultValue={jobParams?.is_published || ""}
								disabled={isLoading?.fetchJobs}
								type="select"
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
							>
								<option value={""}>Select</option>
								<option value={1}>Active</option>
								<option value={0}>Inactive</option>
							</select>
						</div>
						<div className="flex flex-col">
							<label className="font-light">Created At</label>
							<input
								type="date"
								onChange={(e) => {
									const inputValue = e.target.value;
									setDate.cancel();
									setDate(inputValue);
								}}
								disabled={isLoading?.fetchJobs}
								defaultValue={jobParams?.created_at || ""}
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-36 p-2.5"
							/>
						</div>
						<button
							onClick={() => setRow(!row)}
							className="mt-5 p-0.5 rounded-lg"
						>
							{row ? (
								<MdViewModule size={28} color="gray" />
							) : (
								<MdViewColumn size={28} color="gray" />
							)}
						</button>
					</div>
				</div>
				<div
					className={`h-[calc(100vh-270px)] overflow-x-hidden overflow-y-auto mt-5 hide-scroll`}
				>
					{row ? (
						<Table
							data={tableData}
							table={jobs}
							actions={actions}
							id={id}
							isLoading={isLoading?.fetchJobs}
						/>
					) : (
						<VerticalTable
							data={tableData}
							table={jobs}
							actions={actions}
							id={id}
							isLoading={isLoading?.fetchJobs}
						/>
					)}
				</div>

				<Pagination
					total={jobs?.last_page}
					current={jobs?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
