import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface Data {
  course_status?: any;
  id?: any;
}

interface CourseData {
  data?: Data[];
}

interface Courses {
  courses: CourseData;
  courseOrders: any;
  courseDetail: {};
  categories: any;
  reviews: any;
  users: any;
  settings: any;
  events: any;
  courseData: any;
  chapters: any;
  isLoading: {};
}

const initialState: Courses = {
  courses: {},
  courseOrders: {},
  courseDetail: {},
  categories: {},
  reviews: {},
  users: {},
  settings: {},
  events: {},
  courseData: {},
  chapters: [],
  isLoading: {},
};

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setCourses: (state: Courses, { payload }: PayloadAction<any>) => {
      state.courses = payload;
    },
    setCourseOrders: (state: Courses, { payload }: PayloadAction<any>) => {
      state.courseOrders = payload;
    },
    setDeleteCourseOrder: (state: Courses, { payload }: PayloadAction<any>) => {
      const idToDelete = payload;
      state.courseOrders.subscriptions.data = state.courseOrders.subscriptions.data.filter((dat) => dat.id !== idToDelete);
    },
    setCourseStatus: (state: Courses, { payload }: PayloadAction<any>) => {
      state.courses = {
        ...state.courses,
        data: (state.courses.data || []).map((data) => {
          return data.id === payload.id
            ? { ...data, course_status: payload.current_status }
            : data;
        }),
      };
    },
    setCourseDetail: (state: Courses, { payload }: PayloadAction<any>) => {
      state.courseDetail = payload;
    },
    setCourseData: (state: Courses, { payload }: PayloadAction<any>) => {
      state.courseData = payload;
    },
    setCategories: (state: Courses, { payload }: PayloadAction<any>) => {
      if (payload?.prev_cursor === null) {
        state.categories = payload;
      } else {
        state.categories = {
          ...payload,
          data: [...(state.categories.data || []), ...payload?.data],
        };
      }
    },
    setReviews: (state: Courses, { payload }: PayloadAction<any>) => {
      state.reviews = payload;
    },
    adReview: (state: Courses, { payload }: PayloadAction<any>) => {
      state.reviews = {
        ...state.reviews,
        reviews: {
          ...state.reviews.reviews,
          data: [...(state.reviews.reviews.data || []), {...payload, is_published: 0}],
        },
      };
    },
    changeReviewStatus: (state: Courses, { payload }: PayloadAction<any>) => {
        state.reviews = {
            ...state.reviews,
            reviews: {
              ...state.reviews.reviews,
              data: state.reviews.reviews.data.map((review) => {
                return {...review, is_published: review.id === payload ? (review.is_published === 1 ? 0:1): review.is_published}
              }),
            },
          };
    },
    delReview: (state: Courses, { payload }: PayloadAction<any>) => {
      state.reviews = {
        ...state.reviews,
        reviews: {
          ...state.reviews.reviews,
          data: state.reviews.reviews.data.filter((review) => {
            return review.id !== payload;
          }),
        },
      };
    },
    setSettings: (state: Courses, { payload }: PayloadAction<any>) => {
        state.settings = payload?.course;
    },
    setUsers: (state: Courses, { payload }: PayloadAction<any>) => {
      if (payload?.current_page === 1) {
        state.users = payload;
      } else {
        state.users = {
          ...payload,
          data: [...(state.users.data || []), ...payload?.data],
        };
      }
    },
    setEventResponse: (state: Courses, { payload }: PayloadAction<any>) => {
      state.events = payload?.data;
    },
    delCourse: (state: Courses, { payload }: PayloadAction<any>) => {
      state.courses = {
        ...state.courses,
        data: state.courses?.data?.filter((course) => course?.id !== payload)
      };
    },
    setCourseChapters: (state: Courses, { payload }: PayloadAction<any>) => {
      state.chapters = payload;
    },
    setAddChapter: (state: Courses, { payload }: PayloadAction<any>) => {
      state.chapters = [...state.chapters, payload];
    },
    setEditChapter: (state: Courses, { payload }: PayloadAction<any>) => {
      state.chapters = state.chapters?.map((chap) => chap?.id === payload?.id ? payload:chap);
    },
    setDelChapter: (state: Courses, { payload }: PayloadAction<any>) => {
      state.chapters = state.chapters?.filter((chap) => chap?.id !== payload);
    },
    setLoader: (state: Courses, { payload }: PayloadAction<any>) => {
      state.isLoading = { ...state.isLoading, ...payload };
    },
    reset: (state) => initialState,
  },
});

export const fetchAllCourses = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ courses: true }));
  const json = await Api.fetchCourses(data);
  dispatch(setLoader({ courses: false }));
  if (json !== undefined) {
    if (json?.status === 200) {
      dispatch(setCourses(json?.data));
    }
  }
  return json;
};

export const changeCourseStatus = (id: any) => async (dispatch: any) => {
  dispatch(setLoader({ courseStatus: true }));
  const json = await Api.courseStatus(id);
  dispatch(setLoader({ courseStatus: false }));
  if (json !== undefined) {
    if (json?.status === 200) {
      dispatch(
        setCourseStatus({ id, current_status: json?.data?.course_status })
      );
      toastNotification({status: "success", message: "Status changed successfully!"})
    } else {
      toastNotification({status: "error", message: "Status not updated!"})
    }
  }
  return json;
};

  export const addCourse = (data: any, navigate: any, type: string, id?: any, offtype?: string) => async (dispatch: any) => {
  dispatch(setLoader({ addCourse: true }));
  let json: any = null;
  if (type === "add") {
    json = await Api.addCourse(data);
  } else {
    json = await Api.editCourse(data, id);
  }
  dispatch(setLoader({ addCourse: false }));
    if (json?.status === 200 || json?.status === 201) {
      toastNotification({status: "success", message: json?.data?.message || 'Success Adding/Updating'});
      navigate(`/offerings/${offtype}`);
    } else {
      toastNotification({status: "error", message: json?.response?.data?.[0] || "Please try again"})
      }
  return json;
};

export const fetchCourseDetail = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ courseDetail: true }));
  const json = await Api.courseDetail(data);
  dispatch(setLoader({ courseDetail: false }));
  if (json !== undefined) {
    if (json?.status === 200) {
      dispatch(setCourseDetail(json?.data));
    }
  }
  return json;
};

export const fetchCourseData = (id: any) => async (dispatch: any) => {
  dispatch(setLoader({ courseData: true }));
  const json = await Api.courseData(id);
  dispatch(setLoader({ courseData: false }));
  if (json !== undefined) {
    if (json?.status === 200) {
      dispatch(setCourseData(json?.data));
    }
  }
  return json;
};

export const fetchCategories = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ categories: true }));
  const json = await Api.courseCategory(data);
  dispatch(setLoader({ categories: false }));
  if (json !== undefined) {
    if (json?.status === 200) {
      dispatch(setCategories(json?.data));
    }
  }
  return json;
};

export const fetchNextCategory = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ categories: true }));
  const json = await Api.nextCategory(data);
  dispatch(setLoader({ categories: false }));
  if (json !== undefined) {
    if (json?.status === 200) {
      dispatch(setCategories(json?.data));
    }
  }
  return json;
};

export const fetchCourseReview = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ reviews: true }));
  const json = await Api.courseReviews(data);
  dispatch(setLoader({ reviews: false }));
  if (json !== undefined) {
    if (json?.status === 200) {
      dispatch(setReviews(json?.data));
    }
  }
  return json;
};

export const fetchUserList = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ users: data?.user !== "" }));
  const json = await Api.usersList(data);
  dispatch(setLoader({ users: false }));
  if (json !== undefined) {
    if (json?.status === 200) {
      dispatch(setUsers(json?.data));
    }
  }
  return json;
};

export const addReview = (data: any, setShow: any) => async (dispatch: any) => {
  dispatch(setLoader({ addReview: true }));
  const json = await Api.addReview(data);
  dispatch(setLoader({ addReview: false }));
  if (json !== undefined) {
    if (json?.status === 200) {
      dispatch(adReview(json?.data?.review));
      toastNotification({status: "success", message: "Review added!"})
      setShow(false);
    } else if (json?.response?.status === 422) {
      toastNotification({status: "warning", message: "User or review field is missing!"})
    }
  }
  return json;
};

export const deleteReview = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ deleteReview: true }));
  const json = await Api.deleteReview(data);
  dispatch(setLoader({ deleteReview: false }));
  if (json !== undefined) {
    if (json?.status === 200) {
      dispatch(delReview(json?.data?.id));
      toastNotification({status: "success", message: "Review deleted!"})
    }
  }
  return json;
};

export const reviewStatus = (data: any) => async (dispatch: any) => {
    dispatch(setLoader({ reviewStatus: true }));
    const json = await Api.reviewStatus(data);
    dispatch(setLoader({ reviewStatus: false }));
    if (json !== undefined) {
      if (json?.status === 200) {
        dispatch(changeReviewStatus(data));
        toastNotification({status: "success", message: "Status Changed!"})
      }
    }
    return json;
  };

  export const fetchSetting = (data: any) => async (dispatch: any) => {
    dispatch(setLoader({ settings: true }));
    const json = await Api.courseSetting(data);
    dispatch(setLoader({ settings: false }));
    if (json !== undefined) {
      if (json?.status === 200) {
        dispatch(setSettings(json?.data));
      }
    }
    return json;
  };

  export const updateSettings = (data: any) => async (dispatch: any) => {
    dispatch(setLoader({ settingUpdate: true }));
    const json = await Api.updateCourseSetting(data);
    dispatch(setLoader({ settingUpdate: false }));
    if (json !== undefined) {
      if (json?.status === 200) {
        toastNotification({status: "success", message: "Settings Updated!"})
      } else {
        toastNotification({status: "error", message: "Settings not updated!"})
      }
    }
    return json;
  };

  export const fetchCourseOrders = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ courseOrders: true }));
  const json = await Api.courseOrders(data);
    if (json?.status === 200) {
      dispatch(setCourseOrders(json?.data?.data));
      dispatch(setLoader({ courseOrders: false }));
    } else {
        dispatch(setLoader({ courseOrders: false }));
      }
  return json;
};

export const deleteCourseOrder = (id: any) => async (dispatch: any) => {
  dispatch(setLoader({ deleteCourseOrder: true }));
  const json = await Api.deleteCourseOrder(id);
  if (json?.status === 200) {
    dispatch(setDeleteCourseOrder(id));
    dispatch(setLoader({ deleteCourseOrder: false }));
      toastNotification({status: "success", message: json?.data?.message || "Course Order Deleted Successfully!"})
    } else {
      dispatch(setLoader({ deleteCourseOrder: false }));
      toastNotification({status: "error", message: "Please try again"})
    }
  return json;
};

  export const fetchEventResponse = (data: any) => async (dispatch: any) => {
    dispatch(setLoader({ eventResponse: true }));
    const json = await Api.eventResponse(data);
    dispatch(setLoader({ eventResponse: false }));
    if (json !== undefined) {
      if (json?.status === 200) {
        dispatch(setEventResponse(json?.data));
      }
    }
    return json;
  };

  export const deleteCourse = (data: any) => async (dispatch: any) => {
    dispatch(setLoader({ deleteCourse: true }));
    const json = await Api.deleteCourse(data?.id);
    dispatch(setLoader({ deleteCourse: false }));
    if (json !== undefined) {
      if (json?.status === 200) {
        dispatch(delCourse(data?.id));
        toastNotification({status: "success", message: `${data?.type} deleted!`})
      }
    }
    return json;
  };

  export const fetchCourseChapters = (data: any) => async (dispatch: any) => {
    dispatch(setLoader({ chapters: true }));
    const json = await Api.courseChapters(data);
    dispatch(setLoader({ chapters: false }));
    if (json !== undefined) {
      if (json?.status === 200) {
        dispatch(setCourseChapters(json?.data?.data));
      }
    }
    return json;
  };


  export const addCourseChapter = (data: any, setAddChap: any, func) => async (dispatch: any) => {
    dispatch(setLoader({ addChap: true }));
    const json = await Api.addChapter(data);
    dispatch(setLoader({ addChap: false }));
    if (json !== undefined) {
      if (json?.status === 201) {
        setAddChap(false);
        func();
        dispatch(setAddChapter(json?.data?.data));
        toastNotification({status: "success", message: json?.data?.message || "Chapter added!"});
      } else if (json?.status === 200) {
        setAddChap(null);
        func();
        dispatch(setEditChapter(json?.data?.data));
        toastNotification({status: "success", message: json?.data?.message || "Chapter updated!"});
      } else if (json?.response?.status === 422) {
        toastNotification({status: "error", message: json?.response?.data?.message || "Error!"})
      }
    }
    return json;
  };

  export const deleteChapter = (data: any) => async (dispatch: any) => {
    dispatch(setLoader({ deleteChapter: true }));
    const json = await Api.deleteChaptersTopics(data);
    dispatch(setLoader({ deleteChapter: false }));
    if (json !== undefined) {
      if (json?.status === 200) {
        if (data?.type === "topic") {
          dispatch(setEditChapter(json?.data?.data?.[0]));
        } else {
          dispatch(setDelChapter(data?.content_id));
        }
        toastNotification({status: "success", message: json?.data?.message || "Chapter deleted!"})  
      }
    }
    return json;
  };

  export const addCourseTopic = (data: any, setShow: any) => async (dispatch: any) => {
    dispatch(setLoader({ addTopic: true }));
    const json = await Api.addTopic(data);
    dispatch(setLoader({ addTopic: false }));
    if (json !== undefined) {
      if (json?.status === 201) {
        setShow(false);
        dispatch(setEditChapter(json?.data?.data));
        toastNotification({status: "success", message: json?.data?.message || "Topic added!"});
      } else if (json?.status === 200) {
        setShow(false);
        dispatch(setEditChapter(json?.data?.data));
        toastNotification({status: "success", message: json?.data?.message || "Topic Updated!"});
      } else if (json?.response?.status === 422) {
        toastNotification({status: "error", message: json?.response?.data?.message || "Error!"})
      }
    }
    return json;
  };


export const {
  setCourses,
  setCourseStatus,
  setCourseDetail,
  setCourseData,
  setCategories,
  setReviews,
  adReview,
  delReview,
  setSettings,
  changeReviewStatus,
  setUsers,
  setEventResponse,
  delCourse,
  setLoader,
  setCourseOrders,
  setCourseChapters,
  setAddChapter,
  setEditChapter,
  setDelChapter,
  setDeleteCourseOrder,
  reset: resetCourse
} = courseSlice.actions;
export default courseSlice.reducer;
