import { resetTerm } from "../redux/TermSlice.ts";
import { resetCourse } from "../redux/courses.ts"
import { resetLogin } from "../redux/loginSlice.ts";
import { resetVoucher } from "../redux/vouchers.ts";
import { resetTickets } from "../redux/tickets.ts";
import { resetOthers } from "../redux/others.ts";
import { resetUsers } from "../redux/users.ts";
import { resetCandidate } from "../redux/candidates.ts";
import { resetFaqs } from "../redux/faqsSlice.ts";
import { resetCaseStudy } from "../redux/caseStudiesSlice.ts";
import { resetTeam } from "../redux/teamSlice.ts";
import { resetBlogs } from "../redux/blogsSlice.ts";
import { resetJobs } from "../redux/jobsSlice.ts";
import { resetBuyer } from "../redux/buyerSlice.ts";
import { resetInfluencer } from "../redux/influencersSlice.ts";
import { resetPolicies } from "../redux/policiesSlice.ts";
import { resetHierarchy } from "../redux/hierarchySlice.ts";
import { resetInfuencerProfile } from "../redux/influencerProfileSlice.ts";

export const logout = () => (dispatch) => {
    dispatch(resetCourse());
    dispatch(resetLogin());
    dispatch(resetTerm());
    dispatch(resetVoucher());
    dispatch(resetTickets());
    dispatch(resetOthers());
    dispatch(resetUsers());
    dispatch(resetCandidate());
    dispatch(resetFaqs());
    dispatch(resetCaseStudy());
    dispatch(resetTeam());
    dispatch(resetBlogs());
    dispatch(resetJobs());
    dispatch(resetBuyer());
    dispatch(resetInfluencer());
    dispatch(resetPolicies());
    dispatch(resetHierarchy());
    dispatch(resetInfuencerProfile());
}