import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchCategories, deleteCategory, addCategory } from "../../../redux/faqsSlice.ts";
import { Pagination } from "../../../components/pagination/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import { FilterBtn } from "../../../components/filterBtn/index.js";
import AddFormModel from "../../../components/addFormModal/index.js";
import { FilterField } from "../../../components/filterField/index.js";
import DeleteModal from "../../../components/deleteModal/index.js";
import { Table } from "../../../components/table/index.jsx";

export const Categories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categories, isLoading } = useSelector((store) => store.faqs);
  const [id, setId] = useState("");
  const [params, setParams] = useState({ page: 1 });
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const deleteRef = useRef(null);

  function closeModal() {
    setIsOpen(false);
    setData({});
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    dispatch(fetchCategories({ params: params }));
  }, []);

  function handlePage(page) {
    dispatch(fetchCategories({ params: { ...params, page: page } }));
  }

  const handleSearch = () => {
    dispatch(fetchCategories({ page: 1, params: params }));
  }

  const handleClear = () => {
    setParams({ page: params?.page});
    dispatch(fetchCategories({ params: { page: 1 } }));
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
      const file = files[0];

      setData({ ...data, image: file });

      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the image preview using the data URL
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (data?.name !== "") {

      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      dispatch(addCategory({ formData: { formData } }, closeModal));
    }
  };

  const handleView = (id) => navigate(`/contents/faqs/categories/${(id)}`);

  const handleDelete = (id) => {
    setId(id)
    deleteRef?.current?.open({
      id: id,
      deleteFun: deleteCategory,
      type: "Faq Category",
    })
  }

  const tableData = [
		{value: "name", to: '/contents/faqs/categories/{faq_cat}'},
		{key: "Created", value: "created_at", type:'date'},
	];

	const actions = [
		{type: 'view', func: handleView, isLoading: false},
		{type: 'delete', func: handleDelete, isLoading: isLoading?.deleteCategory}
	];

  return (
    <>
    <div className="relative">
        <DeleteModal ref={deleteRef} />
      <div className="flex justify-between items-center space-x-10">
        <AddBtn
          func={openModal}
          mt={5}
          text={"Add Category"}
        />
        <div className="flex">
        <div className="flex flex-col">
          <FilterField
            param={params}
            setParam={setParams}
            type={"name"}
          />
        </div>
        <div className="flex justify-center items-center px-2 mt-6 gap-2">
            <FilterBtn
              func={handleSearch}
              disabled={!params?.name}
            />
            <FilterBtn
              func={handleClear}
              disabled={!params?.name}
              text={"Clear"}
              color={"red"}
            />
        </div>
        </div>
      </div>
      <div className={`h-[calc(100vh-310px)] overflow-auto mt-5 hide-scroll`}>
      <Table data={tableData} table={categories} actions={actions} id={id} isLoading={isLoading?.fetchCategories} />
      </div>
      <Pagination
        total={categories?.last_page}
        current={categories?.current_page}
        func={handlePage}
      />
    </div>
      <AddFormModel isOpen={isOpen} closeModal={closeModal} handleAdd={handleAdd} data={data} setData={setData} isLoading={isLoading?.addCategory} name={true} image={true} imagePreview={imagePreview} handleFileChange={handleFileChange} />
    </>
  );
};
