import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchCaseStudySection } from "../../../redux/caseStudiesSlice.ts";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../../../components/loader/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const SectionInfo = () => {
  const { id, faqId } = useParams();
  const dispatch = useDispatch();
  const { sectionInfo, isLoading } = useSelector((store) => store.caseStudies);

  useEffect(() => {
    dispatch(fetchCaseStudySection(id));
  }, [dispatch, id]);

  return (
    <>
    <Breadcrumbs breadcrumbs={[
				{name: "Case Studies", link: '/contents/case-studies'},
				{name: 'Sections', link: `/contents/case-studies/${id}/sections`},
        {name: 'Section Details', link: ``},
			]} />
    {isLoading?.fetchSection ? <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader w="20" h="20" color="orange" />
      </div>:
    <div className="relative overflow-x-auto h-full mx-[55px] px-5 rounded-lg shadow-lg">
      <div className="flex justify-between my-3">
        <AddBtn text={'Edit Section'} to={`/contents/case-studies/${faqId}/sections/edit/${(sectionInfo?.id)}`} />
        </div>
      <table className="w-full text-sm text-left text-black rounded-lg">
        <tbody>
          <tr
            className="border-b"
          >
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              Section ID
            </th>
            <td className="px-6 py-4">{sectionInfo?.id}</td>
          </tr>
          <tr
            className="border-b"
          >
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              Name
            </th>
            <td className="px-6 py-4">{sectionInfo?.section_name}</td>
          </tr>
          <tr
            className="border-b"
          >
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              Content
            </th>
            <td dangerouslySetInnerHTML={{ __html: sectionInfo?.section_content }}
             className="px-6 py-4"></td>
          </tr>
          <tr
            className="border-b"
          >
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
            >
              Created At
            </th>
            <td className="px-6 py-4">{sectionInfo?.created_at?.slice(0, 10)}</td>
          </tr>
          <tr
          >
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              Images
            </th>
            <td className="px-6 py-4">
          <div className="mt-2 flex justify-start gap-x-5 items-center">
              {(sectionInfo?.images || []).map((img, index) => {
                return (
                  <div key={index} className="flex justify-center">
                    <img
                      src={img.path}
                      alt="casestudy"
                      className="w-[40px] h-[40px]"
                      />
                  </div>
                );
              })}
            </div>
            </td>
          </tr>
        </tbody>
      </table>
       
    </div>
    }
    </>
  );
};