import { useParams } from "react-router-dom";
import { useLocation, Link } from "react-router-dom";
import { CaseStudyDetails } from "./details.js";
import { Sections } from "../sections/index.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCaseStudy } from "../../../redux/caseStudiesSlice.ts";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const InfoHome = () => {
  const location = useLocation();
  const { id } = useParams();
  const { caseStudyInfo } = useSelector((store) => store.caseStudies);
  const dispatch = useDispatch();
  const detailsId = caseStudyInfo?.id

  useEffect(() => {
    dispatch(getCaseStudy(id));
  }, []);

  return (
    <div>
      <aside
        id="sidebar-multi-level-sidebar"
        className="fixed top-16 z-30 w-56 h-screen transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-100">
          <ul className="space-y-2 font-medium">
            <li>
              <div
                className={`flex items-center font-normal p-2 text-gray-900 transition rounded-full duration-200 ease-out group ${
                  location.pathname.includes(`/details`) ? "bg-orange-500 text-white" : "text-gray-900 hover:bg-orange-200"
                }`}
              >
                <Link to={`/contents/case-studies/${detailsId}/details`} className="ms-3">
                  {"> Details"}
                </Link>
              </div>
            </li>
            <li>
              <div
                className={`flex items-center p-2 font-normal text-gray-900 transition rounded-full duration-200 ease-out group ${
                  location.pathname.includes(`/sections`) ? "bg-orange-500 text-white" : "text-gray-900 hover:bg-orange-200"
                }`}
              >
                <Link
                  to={`/contents/case-studies/${detailsId}/sections`}
                  className="flex-1 ms-3 whitespace-nowrap"
                >
                  {"> Sections"}
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </aside>

      <div className="p-4 ml-52 h-max">
      <Breadcrumbs breadcrumbs={[
				{name: "Case Studies", link: '/contents/case-studies'},
				{name: location.pathname.includes(`/sections`) ? 'Sections': "Case Study Details", link: ``},
			]} />
        <div className="p-4 border-2 mt-4 border-gray-200 border-dashed rounded-lg mx-[55px]">
          {location.pathname.includes(`/sections`) ? (
            <Sections />
          ) : (
            <CaseStudyDetails />
          )}
        </div>
      </div>
    </div>
  );
};
