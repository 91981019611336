import camera from "../../../../assets/svg/camera.svg";

export const CompanyProfile = ({ data, handleChange }) => {
	return (
		<div className="flex justify-start gap-x-3 items-center">
			<label className="text-orange-500 font-light">Company Logo/Profile</label>

			<label
				className={`relative w-20 h-20 cursor-pointer -mt-5 flex justify-center items-center rounded-md ${!data?.profile_image && "bg-gray-200 !rounded-full"}`}
				htmlFor={"profile_image"}
			>
				<img
					className={`${
						data?.profile_image ? "object-contain" : "w-6 h-6"
					}  rounded-md`}
					src={
						data?.profile_image
							? typeof data?.profile_image === "string"
								? data?.profile_image
								: URL.createObjectURL(data?.profile_image)
							: camera
					}
					alt=""
				/>
				{data?.profile_image && (
					<div className="w-20 h-20 group hover:bg-gray-200 opacity-60 rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500">
						<img
							className="hidden group-hover:block w-5"
							src="https://www.svgrepo.com/show/33565/upload.svg"
							alt=""
						/>
					</div>
				)}
			</label>
			<input
				type="file"
				onChange={handleChange}
				accept="image/*"
				name="profile_image"
				id="profile_image"
				hidden="true"
			/>
		</div>
	);
};
