import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../components/pagination/index.js";
import { AddBtn } from "../../components/addBtn/index.js";
import DeleteModal from "../../components/deleteModal/index.js";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import {
	deleteTrainer,
	fetchTrainers,
	updateTrainerStatus,
} from "../../redux/trainerSlice.ts";
import { debounce } from "lodash";

export const Trainers = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { trainers, isLoading } = useSelector((store) => store.trainer);
	const [id, setId] = useState("");
	const [params, setParams] = useState({ page: 1 });
	const deleteRef = useRef(null);

	useEffect(() => {
		dispatch(fetchTrainers({ params: params }));
	}, [params]);

	function handlePage(page) {
		setParams({ ...params, page: page });
	}

	const handleStatusChange = (id) => {
		const data = { params: params };
		dispatch(updateTrainerStatus(id, data));
		setId(id);
	};

	const handleEdit = (id) => navigate(`/contents/trainers/edit/${id}`);

	const handleDelete = (id) => {
		setId(id);
		deleteRef?.current?.open({
			id: id,
			deleteFun: deleteTrainer,
			type: "Trainer",
		});
	};

	const setName = debounce((value) => {
		setParams({ ...params, name: value, page: 1 });
	}, 1500);

	const tableData = [
		{ value: "name", to: `/contents/trainers/{id}`, img: "image_path" },
		{ key: "Title", value: "title" },
		{ key: "Company", value: "company" },
		{ key: "Status", value: "is_active", type: "status" },
		{ key: "Created", value: "created_at" },
	];

	const actions = [
		{
			type: "status",
			func: handleStatusChange,
			isLoading: isLoading?.updateTrainerStatus,
			value: "is_active",
		},
		{ type: "edit", func: handleEdit, isLoading: false },
		{ type: "delete", func: handleDelete, isLoading: isLoading?.deleteTrainer },
	];

	return (
		<>
			<Breadcrumbs breadcrumbs={[{ name: "Trainers", link: "" }]} />
			<div className="relative mx-12">
				<DeleteModal ref={deleteRef} />
				<div className="flex justify-start items-center space-x-10 my-2">
					<AddBtn to={"/contents/trainers/add"} mt={6} text={"Add Trainer"} />
					<div className="flex flex-col">
						<label className="font-light">Search By Name</label>
						<div className="flex justify-center items-center gap-x-4">
							<input
								onChange={(e) => {
									const inputValue = e.target.value;
									setName.cancel();
									setName(inputValue);
								}}
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
							/>
						</div>
					</div>
				</div>
				<div className="h-[calc(100vh-270px)] overflow-auto mt-5 hide-scroll">
					<Table
						data={tableData}
						table={trainers}
						actions={actions}
						id={id}
						isLoading={isLoading?.trainers}
					/>
				</div>
				<Pagination
					total={trainers?.last_page}
					current={trainers?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
