import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { passwordChange } from "../../../../redux/loginSlice.ts";
import { Loader } from "../../../../components/loader/index.js";

export const ChangePassword = ({ change, setChange }) => {
  const [param, setParam] = useState({});
  const {isLoading} = useSelector((store) => store.login);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    dispatch(passwordChange({ params: param }, setChange, setParam));
  };
  return (
    <>
      {change && (
        <div
          className="relative z-50"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <form
                onSubmit={handleChange}
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex flex-col justify-start items-start gap-2">
                    <div className="w-full flex justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="green"
                        className="w-20 h-20 bg-green-100 rounded-full p-3 shadow-sm"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"
                        />
                      </svg>
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Curret Password *
                      </label>
                      <input
                        type="text"
                        value={param?.current_password || ""}
                        onChange={(e) =>
                          setParam({
                            ...param,
                            current_password: e.target.value,
                          })
                        }
                        className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
                        required
                      />
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-green-700">
                        New Password *
                      </label>
                      <input
                        type="text"
                        value={param?.new_password || ""}
                        onChange={(e) =>
                          setParam({ ...param, new_password: e.target.value })
                        }
                        className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-transparent px-6 py-3 flex flex-row-reverse gap-x-4">
                  <button
                    type="submit"
                    className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
                  >
                    {isLoading ? <Loader w="6" h="6" color={"blue"} />:"Change"}
                  </button>
                  <button
                    type="button"
                    onClick={() => setChange(false)}
                    className="border-red-600 capitalize text-red-600 hover:bg-red-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
                    >
                    Back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
