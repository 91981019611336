import { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../components/breadcrumbs";
import DraftEditor from "../../../components/DraftEditor";
import { Upload } from "../../../components/upload";
import { Loader } from "../../../components/loader";
import { toastNotification } from "../../../services/notification-service";
import { useDispatch, useSelector } from "react-redux";
import { addUpdatePolicy, fetchPolicy, setPolicy } from "../../../redux/policiesSlice.ts";
import { useNavigate, useParams } from "react-router-dom";

export const AddUpdatePolicy = () => {
	const [data, setData] = useState({});
	const [draft, setDraft] = useState();
	const [error, setError] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id, type } = useParams();
	const { isLoading, policy } = useSelector((store) => store.policies);

	useEffect(() => {
		if (id) {
			dispatch(fetchPolicy(id));
		} else {
            setPolicy({})
        }
	}, [dispatch, id]);

	useEffect(() => {
		setData(policy);
	}, [policy]);

	const handleSubmit = (e) => {
		e.preventDefault();
		const combinedData = { ...data };
		if (!combinedData?.thumbnail) {
			setError("Thumbnail is required!");
			return;
		}
		if (!draft) {
			setError("Description is required!");
			return;
		}
		combinedData["long_desc"] = draft;
		const formData = new FormData();

		if (typeof combinedData?.thumbnail === "string") {
			delete combinedData?.thumbnail;
		}

		Object.keys(combinedData).forEach((key) => {
			formData.append(key, combinedData[key]);
		});
		setError("");
		dispatch(addUpdatePolicy({ formData: { formData } }, navigate));
	};

	const handleFileChange = async (e) => {
		const files = e.target.files;
		if (files?.[0]?.type?.includes("image") && files?.[0]?.size > 1024000) {
			if (files?.[0]?.size > 1024000)
				toastNotification({
					status: "warning",
					message: "Please upload thumbnail of less than 1 Mb",
				});
			return;
		}
		if (!files?.[0]?.type?.includes("image")) {
			toastNotification({
				status: "warning",
				message: "Please upload image only!",
			});
			const inputThumb = document.getElementById(e.target.id);
			if (inputThumb) {
				inputThumb.value = "";
			}
			return;
		}
		setData({ ...data, [e.target.name]: files[0] });
	};

	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};
	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Policies", link: "/bs/policies" },
					{ name: `${type === "add" ? "Add" : "Edit"} Policy`, link: "" },
				]}
			/>
			{isLoading?.policy ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader w="20" h="20" color="orange" />
				</div>
			) : (
				<div className="bg-white mx-[55px] shadow-lg rounded-lg p-6 pb-10 mb-16">
					<div className="flex flex-col justify-center items-center">
						<form onSubmit={handleSubmit} className="w-full mx-auto">
							<div className="grid grid-cols-4 gap-5">
								<div className="relative col-start-1 md:col-span-2 w-full group">
									<input
										type="text"
										name="title"
										id="title"
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=" "
										value={data?.title || ""}
										onChange={handleChange}
										required
									/>
									<label
										htmlFor="title"
										className="peer-focus:font-medium cursor-text absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Title *
									</label>
								</div>
								<div className="relative col-span-4 w-full group">
									<input
										type="text"
										name="short_desc"
										id="short_desc"
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=" "
										value={data?.short_desc || ""}
										onChange={handleChange}
										required
									/>
									<label
										htmlFor="short_desc"
										className="peer-focus:font-medium cursor-text absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Short Description *
									</label>
								</div>
							</div>
							<div className="text-sm text-orange-500 mb-3 mt-6">
								Long Description *
							</div>
							<div className="relative w-full mb-5 group border border-gray-500 my-2">
								<DraftEditor
									onChange={setDraft}
									title={data?.long_desc || ""}
								/>
							</div>
							<div className="grid grid-cols-3 mt-3">
								<div className="relative w-full group col-start-1 col-span-1">
									<label className="text-sm text-orange-500">Thumbnail *</label>
									<Upload id={"thumbnail"}>
										<input
											id="thumbnail"
											type="file"
											accept="image/*"
											hidden="true"
											name="thumbnail"
											//value={data?.thumbnail || ""}
											onChange={handleFileChange}
											max={1}
										/>
									</Upload>
								</div>
							</div>
							{data?.thumbnail &&
								(typeof data?.thumbnail === "string" ? (
									<img
										src={data?.thumbnail}
										alt="Uploaded Preview"
										className="ml-4 mt-3 w-20 h-20 rounded"
									/>
								) : (
									<img
										src={URL.createObjectURL(data?.thumbnail)}
										alt="Uploaded Preview"
										className="ml-4 mt-3 w-20 h-20 rounded"
									/>
								))}
							<button className="border-blue-600 mt-3 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out">
								{isLoading?.addUpdatePolicy ? (
									<div>
										<Loader h="6" w="6" color={"blue"} />
									</div>
								) : (
									"Save"
								)}
							</button>
							{error && (
								<div className="text-[12px] mt-2 text-red-600">{error}</div>
							)}
						</form>
					</div>
				</div>
			)}
		</>
	);
};
