export const paramString = (param) => {
  let query = "";
  Object.entries(param)?.forEach((entry) => {
    query += entry[0] + "=" + entry[1] + "&";
  });
  return query;
};

export const slugify = (text) => {
  // replace non letter or digits by -
  text = text.replace(/[^a-zA-Z0-9]+/g, '-');
  // transliterate
  text = text.replace(/[^\u0000-\u007E]/g, function(c) {
      return '-';
  });
  // remove unwanted characters
  text = text.replace(/[^-\w]+/g, '');
  // trim
  text = text.replace(/^-+|-+$/g, '');
  // remove duplicate -
  text = text.replace(/-+/g, '-');
  // lowercase
  text = text.toLowerCase();
  if (!text.trim()) {
      return 'n-a';
  }
  return text;
}

export const deslugify = (slug) => {
  let text = slug.replace(/-/g, ' ');
  
  text = text.replace(/\b\w/g, char => char.toUpperCase());

  return text;
}
