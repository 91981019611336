import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";

interface TermState {
    tab: Number;
    dashboardData: {};
}

const initialState: TermState = {
    tab: 0,
    dashboardData: {}
}

const termSlice = createSlice({
    name: 'term',
    initialState,
    reducers: {
        setTab: (state: TermState, {payload}: PayloadAction<any>) => {
            state.tab = payload;
        },
        setDashboardData: (state: TermState, {payload}: PayloadAction<any>) => {
            state.dashboardData = payload;
        },
        reset: (state) => initialState
    }
})

export const fetchDashboardData = () => async (dispatch: any) => {
    const json = await Api.dashboardData();
    if (json !== undefined) {
      if (json?.status === 200) {
        dispatch(setDashboardData(json?.data));
      }
    }
  };

export const {setTab, setDashboardData, reset: resetTerm} = termSlice.actions;
export default termSlice.reducer;