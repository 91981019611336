import React, { useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const UsersGraph = ({users}) => {
	const [options] = useState({
		animationEnabled: true,
		title: {
			text: "Total Registered Users",
			fontSize: 24,
			fontFamily: "Arial",
            fontWeight: "normal",
            fontColor: "#EE5E1B",
		},
		subtitles: [{
			text: `Total Users ${users?.active_users + users?.inactive_users}`,
			verticalAlign: "center",
			fontSize: 20,
			dockInsidePlotArea: true
		}],
		data: [{
			type: "doughnut",
			showInLegend: true,
			indexLabel: "{name}: {y}",
			yValueFormatString: "#,###",
			dataPoints: [
				{ name: "Active Users", y: users?.active_users, color: "#FF6600" },
				{ name: "Inactive Users", y: users?.inactive_users, color: '#000' },
			]
		}]
	});

	return (
		<div>
			<CanvasJSChart options={options} />
		</div>
	);
}
