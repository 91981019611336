import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components/loader";
import { AddFile } from "./addFile";
import { useEffect, useState } from "react";
import {
	deleteKnowledgeBank,
	fetchCategories,
	fetchKnowledgeBank,
} from "../../../redux/buyerSlice.ts";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import heart from "../../../assets/images/grayHeart.png";

export const KnowledgeBank = () => {
	const { bank, isLoading } = useSelector((store) => store.buyer);
	const [id, setId] = useState(null);
	const [editData, setEditData] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchKnowledgeBank());
		dispatch(fetchCategories());
	}, []);

	return (
		<>
			<Breadcrumbs breadcrumbs={[{ name: "Knowledge Bank", link: "" }]} />
			{isLoading?.knowledgeBank ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader h="20" w="20" color={"orange"} />
				</div>
			) : (
				<div className="bg-white pb-4 mx-12">
					<AddFile setEditData={setEditData} editData={editData} />
					{Object.keys(bank || {})?.length !== 0 && (
						<div className="flex flex-col justify-start items-start mt-5 gap-y-10 mb-10 w-full shadow-2xl p-5 rounded-2xl">
							{Object.keys(bank || {})?.map((key) => {
								return (
									<div className="flex flex-col justify-start items-center gap-y-5 w-full">
										<span className="text-left text-2xl capitalize">{key}</span>
										<div className="grid grid-cols-3 gap-5 space-8">
											{bank?.[key]?.map((b) => {
												return (
													<div className={`shadow-lg hover:shadow-2xl rounded-2xl h-full max-w-[350px] w-full p-5 flex flex-col justify-start items-start gap-y-5 ${editData?.id === b?.id && "shadow-blue-500"}`}>
														<a
															href={b?.file_path?.path}
															target="_blank"
															rel="noreferrer"
															className="object-fill w-full"
														>
															<img
																className="w-full h-[170px] rounded-t-2xl object-fill"
																src={b?.image?.path}
																alt={b?.name}
															/>
														</a>
														<div className="flex justify-between items-start gap-x-6 w-full">
															<a
																href={b?.file_path?.path}
																target="_blank"
																rel="noreferrer"
																className="text-orange-500 cursor-pointer"
															>
																{b?.name} {">>"}
															</a>
															<div className="flex justify-center gap-x-2">
																{editData?.id !== b?.id ?
																	<button
																		onClick={() => {
																			setEditData(b);
																			window.scrollTo({
																				top: 0,
																				behavior: "smooth",
																			});
																		}}
																		className="p-1 text-blue-600 border rounded-lg border-blue-600 bg-red hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out"
																	>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			fill="none"
																			viewBox="0 0 24 24"
																			strokeWidth="1.5"
																			stroke="currentColor"
																			className="w-4 h-4"
																		>
																			<path
																				strokeLinecap="round"
																				strokeLinejoin="round"
																				d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
																			/>
																		</svg>
																	</button>
																: <div className="w-6" />
																}
																<button
																	onClick={() => {
																		setId(b?.id);
																		setEditData({});
																		dispatch(deleteKnowledgeBank(b));
																	}}
																	className="p-1 text-red-600 border rounded-lg border-red-600 bg-red hover:bg-red-600 hover:text-white transition-all duration-200 ease-in-out"
																>
																	{isLoading?.deleteBank && id === b?.id ? (
																		<Loader w={"4"} h={"4"} color={"red"} />
																	) : (
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			fill="none"
																			viewBox="0 0 24 24"
																			strokeWidth="1.5"
																			stroke="currentColor"
																			className="w-4 h-4"
																		>
																			<path
																				strokeLinecap="round"
																				strokeLinejoin="round"
																				d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
																			/>
																		</svg>
																	)}
																</button>
															</div>
														</div>
														<div className="flex flex-wrap justify-start items-center gap-2">
															{(b?.tags || [])?.map((tag) => {
																return (
																	<div className="flex items-center p-1 border border-gray-400 text-center rounded-lg">
																		<p className="text-[8px] shrink-0">{tag}</p>
																	</div>
																);
															})}
														</div>
														<div className="flex justify-start items-center gap-x-2">
															<img
																alt={"likes"}
																src={heart}
																className="w-[14px]"
															/>
															<span className="text-[14px] leading-6 font-normal text-[#3B455A]">
																{b?.additional_votes || 0} ({b?.is_paid ? 'Paid': 'Free'})
															</span>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			)}
		</>
	);
};
