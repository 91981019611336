import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface SystemSettings {
	plans: object;
	settings: object;
	isLoading: object;
}

const initialState: SystemSettings = {
	plans: [],
	settings: {},
	isLoading: {},
};

const systemSettingsSlice = createSlice({
	name: "systemsettings",
	initialState,
	reducers: {
		setPlans: (state: SystemSettings, { payload }: PayloadAction<any>) => {
			state.plans = payload;
		},
		setSettings: (state: SystemSettings, { payload }: PayloadAction<any>) => {
			state.settings = payload;
		},
		setLoading: (state: SystemSettings, { payload }: PayloadAction<any>) => {
			state.isLoading = { ...state.isLoading, ...payload };
		},
		reset: (state) => initialState,
	},
});

export const fetchPlans = () => async (dispatch: any) => {
	dispatch(setLoading({ plans: true }));
	const json = await Api.getPlans();
	dispatch(setLoading({ plans: false }));
	if (json !== undefined) {
		if (json?.status === 200) {
			dispatch(setPlans(json?.data));
		}
	}
};

export const fetchSettings = () => async (dispatch: any) => {
	dispatch(setLoading({ settings: true }));
	const json = await Api.getSettings();
	dispatch(setLoading({ settings: false }));
	if (json !== undefined) {
		if (json?.status === 200) {
			dispatch(setSettings(json?.data));
		}
	}
};

export const updatePlans = (query: object) => async (dispatch: any) => {
	dispatch(setLoading({ updatePlans: true }));
	const json = await Api.postPlans(query);
	dispatch(setLoading({ updatePlans: false }));
	if (json !== undefined) {
		if (json?.status === 200) {
			toastNotification({
				status: "success",
				message: json?.data?.message || "Plans Updated!",
			});
			dispatch(fetchPlans());
		} else if (!!json && json?.response?.status === 422) {
			const message =
				json?.response?.data?.errors?.[
					Object.keys(json?.response?.data?.errors)[0]
				][0];
			toastNotification({
				status: "error",
				message: message || "Error!",
			});
		}
	}
};

export const updateSettings = (query: object) => async (dispatch: any) => {
	dispatch(setLoading({ updateSettings: true }));
	const json = await Api.postSettings(query);
	dispatch(setLoading({ updateSettings: false }));
	if (json !== undefined) {
		if (json?.status === 200) {
			toastNotification({
				status: "success",
				message: json?.data?.message || "Settings Updated!",
			});
		} else if (!!json && json?.response?.status === 422) {
			const message =
				json?.response?.data?.errors?.[
					Object.keys(json?.response?.data?.errors)[0]
				][0];
			toastNotification({
				status: "error",
				message: message || "Error!",
			});
		}
	}
};


export const {
	setPlans,
	setSettings,
	setLoading,
	reset: resetSystemSettings,
} = systemSettingsSlice.actions;
export default systemSettingsSlice.reducer;
