import { useEffect, useState, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	fetchCategories,
	deleteCategory,
	addCategory,
} from "../../../redux/blogsSlice.ts";
import { AddBtn } from "../../../components/addBtn/index.js";
import AddFormModel from "../../../components/addFormModal/index.js";
import DeleteModal from "../../../components/deleteModal/index.js";
import { Table } from "../../../components/table/index.jsx";

export const Categories = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { categories, isLoading } = useSelector((store) => store.blogs);
	const [id, setId] = useState(null);
	const [isOpen, setIsOpen] = useState(false);

	const [data, setData] = useState();
	const deleteRef = useRef(null);

	function closeModal() {
		setIsOpen(false);
		setData({});
	}

	function openModal() {
		setIsOpen(true);
	}

	useEffect(() => {
		dispatch(fetchCategories({ params: { page: 1 } }));
	}, []);

	const handleAdd = (e) => {
		e.preventDefault();
		const formData = new FormData();
		if (data?.name !== "") {
			Object.keys(data).forEach((key) => {
				formData.append(key, data[key]);
			});

			dispatch(addCategory({ formData: { formData } }, closeModal));
		}
	};

	const handleView = (id) => navigate(`/contents/blogs/categories/${id}`);

	const handleDelete = (id) => {
		setId(id);
		deleteRef?.current?.open({
			id: id,
			deleteFun: deleteCategory,
			type: "Blog Category",
		});
	};

	const tableData = [
		{ value: "name", to: "/contents/blogs/categories/{id}" },
		{ key: "Created", value: "created_at", type: "date" },
	];

	const actions = [
		{ type: "view", func: handleView, isLoading: false },
		{
			type: "delete",
			func: handleDelete,
			isLoading: isLoading?.deleteCategory,
		},
	];

	return (
		<>
			<div className="relative">
				<DeleteModal ref={deleteRef} />
				<div className="text-xl text-orange-500">Categories</div>
				<div className="flex justify-between items-center space-x-10">
					<AddBtn func={openModal} mt={5} text={"Add Category"} />
				</div>
				<div className={`h-[calc(100vh-330px)] overflow-auto mt-5 hide-scroll`}>
					<Table
						data={tableData}
						table={{ data: categories }}
						actions={actions}
						id={id}
						isLoading={isLoading?.fetchCategories}
					/>
				</div>
			</div>
			<AddFormModel
				isOpen={isOpen}
				closeModal={closeModal}
				handleAdd={handleAdd}
				data={data}
				setData={setData}
				isLoading={isLoading?.addCategory}
				name={true}
			/>
		</>
	);
};
