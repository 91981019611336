import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../components/pagination/index.js";
import { fetchEventResponse } from "../../redux/courses.ts";
import { isEmpty } from "../../helper/join.js";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";

export const EventResponse = () => {
  const dispatch = useDispatch();
  const {events, isLoading} = useSelector((store) => store.course);

  useEffect(() => {
    if (isEmpty(events)) {
      dispatch(fetchEventResponse({ params: {} }));
    }
  }, []);

  function handlePage(page) {
    dispatch(fetchEventResponse({ params: { page: page } }));
  }

  const tableData = [
		{type: "fullName", to: ``},
		{key: "Event", nested:'true', value1: "course", value2: 'course_name'},
		{key: "E-mail", value: "email"},
		{key: "Created", value: "created_at", type:'date'}
	];

  return (
    <>
      <Breadcrumbs breadcrumbs={[{name: "Event Response", link: ""}]} />
      <div className="mx-12 relative">
        <div className="h-[calc(100vh-190px)] overflow-auto my-2 hide-scroll">
          <Table data={tableData} table={events} isLoading={isLoading?.eventResponse} />
        </div>
        <Pagination
          total={events?.last_page}
          current={events?.current_page}
          func={handlePage}
        />
      </div>
    </>
  );
};
