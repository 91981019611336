import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const JobsChart = ({jobs}) => {
    const options = {
        animationEnabled: true,
        title: {
            text: "Jobs Posted in last 12 months",
			fontSize: 24,
			fontFamily: "Arial",
            fontWeight: "normal",
            fontColor: "#EE5E1B",
        },
        axisY : {
            title: "Number of Jobs",
        },
        toolTip: {
            shared: true
        },
        data: [
            {
                type: "spline",
                name: "Jobs Posted",
                showInLegend: false,
				color: '#FF6600', 
                dataPoints: Object.keys?.(jobs || {})?.map((job) => {return ({ y: jobs?.[job], label: job})})
            }
        ]
    };

    return (
        <CanvasJSChart options={options} />
    );
}