import React, { useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const QuestionsChart = ({stats}) => {
	const [options] = useState({
		animationEnabled: true,
		title: {
			text: "Discussions Statistics",
			fontSize: 24,
			fontFamily: "Arial",
            fontWeight: "normal",
            fontColor: "#EE5E1B",
		},
		subtitles: [{
			text: `Postings`,
			verticalAlign: "center",
			fontSize: 20,
			dockInsidePlotArea: true
		}],
		data: [{
			type: "doughnut",
			showInLegend: true,
			indexLabel: "{name}: {y}",
			yValueFormatString: "#,###",
			dataPoints: [
				{ name: "Active Discussions", y: stats?.active_questions_count, color: "#FF6600" },
				{ name: "Active Discussions Attachements", y: stats?.active_questions_attachments_count, color: '#007aff' },
                { name: "Active Answers", y: stats?.active_questions_answer_count, color: "#ffd700" },
				{ name: "Active Saved Discussions", y: stats?.active_questions_save_questions_count, color: '#3498db' },
                { name: "Inactive Discussions", y: stats?.Inactive_questions_count, color: '#000' },
			]
		}]
	});

	return (
		<div>
			<CanvasJSChart options={options} />
		</div>
	);
}
