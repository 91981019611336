import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPage, editPage } from "../../../redux/faqsSlice.ts";
import { Loader } from "../../../components/loader/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import AddFormModel from "../../../components/addFormModal/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const FaqPageInfo = () => {
	const { pageInfo, isLoading } = useSelector((store) => store.faqs);
	const { id } = useParams();
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState();

	function closeModal() {
		setIsOpen(false);
	}

	function openModal() {
		setIsOpen(true);
	}

	useEffect(() => {
		setData(pageInfo);
	}, [pageInfo]);

	useEffect(() => {
		dispatch(getPage(id));
	}, [dispatch, id]);

	if (isLoading?.getPage) {
		return (
			<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
				<Loader w="20" h="20" color="orange" />
			</div>
		);
	}

	const handleAdd = (e) => {
		e.preventDefault();
		const formData = new FormData();

		delete data.slug;
		delete data.created_at;
		delete data.updated_at;
		if (data?.name !== "") {
			Object.keys(data).forEach((key) => {
				formData.append(key, data[key]);
			});
		}

		dispatch(editPage({ formData: { formData } }, closeModal));
	};

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Faqs", link: "/contents/faqs" },
          { name: "Pages", link: `/contents/faqs/pages` },
					{ name: "Details", link: `` },
				]}
			/>
			<div className="flex justify-between my-3 mx-12">
				<AddBtn func={openModal} mt={5} text={"Edit Page"} />
			</div>
			<div className="relative overflow-x-auto h-full mt-3 mx-12 rounded-lg shadow-lg">
				<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
					<tbody>
						<tr className="bg-white border-b">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								Page ID
							</th>
							<td className="px-6 py-4">{pageInfo?.id}</td>
						</tr>
						<tr className="bg-white border-b">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								Title
							</th>
							<td className="px-6 py-4">{pageInfo?.title}</td>
						</tr>
						<tr className="bg-white border-b">
							<th
								scope="row"
								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
							>
								Created At
							</th>
							<td className="px-6 py-4">
								{pageInfo?.created_at?.slice(0, 10)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<AddFormModel
				isOpen={isOpen}
				closeModal={closeModal}
				handleAdd={handleAdd}
				data={data}
				setData={setData}
				isLoading={isLoading?.editPage}
				title={true}
			/>
		</>
	);
};
