import { Combobox, ComboboxButton, Transition } from "@headlessui/react";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../../redux/courses.ts";
import { Loader } from "../loader";
import { IoChevronDown } from "react-icons/io5";

export const UsersList = ({ setUserInfo, userList }) => {
	const dispatch = useDispatch();
	const [user, setUser] = useState('');
	const { users, isLoading } = useSelector((store) => store.course);

	const handleScroll = (e) => {
		const target = e.target;
		if (true) {
			if (target.scrollHeight - target.scrollTop === target.clientHeight) {
				dispatch(fetchUserList({ user: user, page: users?.current_page + 1 }));
			}
		}
	};

  useEffect(() => {
    dispatch(fetchUserList({ user: '', page: 1 }));
  }, [])

	const dispatchFetchUsersList = debounce((value) => {
		setUser(value);
		dispatch(fetchUserList({ user: value, page: 1 }));
	}, 1500);

	return (
		<div className="flex justify-start">
			<Combobox
				onChange={(e) => {
					const selected = users?.data?.find((user) => {
						return user?.firstname + " " + user?.lastname === e;
					});
          if (!!userList) {
            dispatch(fetchUserList({ user: "", page: 1 }));
            setUser('');
          } else {
            setUser(e)
          }
					setUserInfo({ userName: e, userId: selected?.id });
				}}
        value={user}
			>
				<div className="relative">
					<div className="flex justify-center">
						<Combobox.Input
              autoComplete="off"
							placeholder="Select User"
							onChange={(e) => {
								const inputValue = e.target.value;
								dispatchFetchUsersList.cancel();
								dispatchFetchUsersList(inputValue);
							}}
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						/>
            <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
              <IoChevronDown className="size-4 fill-white/60 group-data-[hover]:fill-white" />
            </ComboboxButton>
					</div>
					<Transition
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Combobox.Options
							onScroll={handleScroll}
							className="absolute mt-1 max-h-40 w-full overflow-auto ounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
						>
							{(users?.data || [])?.length === 0 && (
								<div className="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900">
									No User found!
								</div>
							)}
							{(users?.data || [])?.map(
								(use) =>
									(userList
										? !userList?.map((i) => i.id).includes(use.id)
										: true) && (
										<Combobox.Option
											key={use?.id}
											className={({ active }) =>
												`relative cursor-default select-none py-2 pl-10 pr-4 ${
													active ? "bg-orange-600 text-white" : "text-gray-900"
												}`
											}
											value={use?.firstname + " " + use?.lastname}
										>
											{({ selected }) => (
												<>
													<span
														className={`block truncate ${
															selected ? "font-medium" : "font-normal"
														}`}
													>
														{use?.firstname} {use?.lastname}
													</span>
													{selected ? (
														<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
													) : null}
												</>
											)}
										</Combobox.Option>
									)
							)}
						</Combobox.Options>
					</Transition>
				</div>
			</Combobox>
			{isLoading?.users && (
				<div className="ml-2 mt-1">
					<Loader w="4" h="4" color={"orange"} />
				</div>
			)}
		</div>
	);
};
