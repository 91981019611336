import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../components/pagination/index.js";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import { removeEmptyString } from "../../helper/removeEmpty.js";
import { Table } from "../../components/table/index.jsx";
import {
	deleteQuery,
	fetchCompanies,
	fetchQueries,
} from "../../redux/jobsSlice.ts";
import DeleteModal from "../../components/deleteModal/index.js";
import View from "./View/index.jsx";

export const CompanyQueries = () => {
	const dispatch = useDispatch();
	const { companies, isLoading, queries } = useSelector((store) => store.jobs);
	const [filter, setFilter] = useState({ page: 1 });
	const popupRef = useRef();
	const modalRef = useRef();
	const [id, setId] = useState(null);

	useEffect(() => {
		dispatch(fetchQueries(removeEmptyString(filter)));
	}, [filter]);

	useEffect(() => {
		dispatch(fetchCompanies());
	}, []);

	function handlePage(page) {
		dispatch(
			fetchQueries({ params: removeEmptyString({ ...filter, page: page }) })
		);
	}

	const handleDelete = (id) => {
		setId(id);
		popupRef?.current?.open({
			id: id,
			deleteFun: deleteQuery,
			type: "Query",
		});
	};

	const tableData = [
		{ value: "fullname" },
		{ key: "Email", value: "email" },
		{ key: "Company", value: "company_name" },
		{ key: "Created Date", value: "created_at", type: "date" },
	];

	const handleView = (a, b, tab) => {
		modalRef.current.open(tab);
	};

	const actions = [
		{ type: "view", func: handleView, isLoading: false },
		{ type: "delete", func: handleDelete, isLoading: isLoading?.deleteQuery },
	];

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Companies", link: "/companies/all" },
					{ name: "Queries", link: "" },
				]}
			/>
			<View ref={modalRef} />
			<DeleteModal ref={popupRef} />
			<div className="relative mx-12">
				<div className="flex justify-start items-center space-x-10">
					<div className="flex flex-col w-1/3 gap-y-2">
						<label className="font-light">Select Company</label>
						<select
							value={filter?.company_id || ""}
							onChange={(e) => {
								setFilter({ ...filter, company_id: e.target.value });
							}}
							type="select"
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						>
							<option value={""}>All</option>
							{(companies || [])
								?.filter((comp) => comp?.is_original)
								?.map((comp) => {
									return <option value={comp?.id}>{comp?.title}</option>;
								})}
						</select>
					</div>
				</div>
				<div className={`h-[calc(100vh-290px)] overflow-auto mt-5 hide-scroll`}>
					<Table
						data={tableData}
						table={queries}
						isLoading={isLoading?.queries}
						actions={actions}
						id={id}
					/>
				</div>

				<Pagination
					total={queries?.last_page}
					current={queries?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
