import { useEffect, useState } from "react";
import Api from "../../../services/api";
import { toastNotification } from "../../../services/notification-service";
import { Loader } from "../../../components/loader";
import { Breadcrumbs } from "../../../components/breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries, setCountries } from "../../../redux/others.ts";

export const JobsFilterUpdate = () => {
	const [param, setParam] = useState({});
	const [categories, setCategories] = useState({});
	const [loader, setLoader] = useState({});
	const { countries } = useSelector((store) => store.other);
	const dispatch = useDispatch();

	const fetchCategories = async () => {
		let json = await Api.jobCategories();
		if (!!json && json?.status === 200) {
			let temp = json?.data;
			delete temp['companies']
			delete temp['company_categories']
			setCategories(temp);
		}
	};

	useEffect(() => {
		fetchCategories();
		dispatch(fetchCountries({ params: { type: "countries" } }));
	}, []);

	const handleAdd = async () => {
		if (param?.optionAdd && param?.name) {
			setLoader({...loader, add: true});
			const json = await Api.handleJobFilter({
				option: param?.optionAdd,
				name: param?.name,
			});
			setLoader({...loader, add: false});
			if (!!json && json?.status === 201) {
				toastNotification({
					status: "success",
					message: "Job filter Added successfully!",
				});
                setCategories({...categories, [param?.optionAdd]: json?.data?.data});
				setParam({});
			} else if (json?.response?.status === 409) {
                toastNotification({
					status: "warning",
					message: json?.response?.data?.message
				});
            } else {
				toastNotification({ status: "error", message: "Error adding job filter!" });
			}
		}
	};

	const handleDelete = async () => {
		if (param?.id && param?.optionDel) {
			setLoader({...loader, delete: true});
			const json = await Api.handleJobFilter({
				id: param?.id,
				option: param?.optionDel,
			});
			setLoader({...loader, delete: false});
			if (!!json && json?.status === 200) {
				toastNotification({
					status: "success",
					message: "Job filter deleted successfully!",
				});
                setCategories({...categories, [param?.optionDel]: json?.data?.data});
				setParam({});
			} else {
				toastNotification({
					status: "error",
					message: "Error deleting job filter!",
				});
			}
		}
	};

	const handleCountryUpdate = async () => {
		if (param?.country && param?.country_name) {
			setLoader({...loader, country: true});
			const json = await Api.handleJobFilter({
				option: 'country',
				name: param?.country,
				replace: param?.country_name
			});
			setLoader({...loader, country: false});
			if (!!json && json?.status === 200) {
				toastNotification({
					status: "success",
					message: "Country Name Updated successfully!",
				});
				dispatch(setCountries({data: json?.data, type: 'countries'}));
				setParam({});
			}
		}

	}

	return (
		<>
			<Breadcrumbs breadcrumbs={[
				{name: "Jobs", link: "/jobs/view-all"},
				{name: "Job Filters", link: ""},
			]} />
			<div className="mb-1 relative mx-12 rounded-lg p-5 shadow-lg pb-10">
				<div className="text-xl text-orange-500 mx-5">Add New Job Filter</div>
				<div className="flex justify-start items-center my-5">
					<div className="mx-5  space-y-3">
						<label className="font-light">Input New Filter Name</label>
						<input
							onChange={(e) => setParam({ ...param, name: e.target.value })}
							value={param?.name || ''}
							type="text"
							className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
						/>
					</div>
					<div className="mx-5 space-y-3">
						<label className="font-light">Select Filter Type</label>
						<select
							value={param?.optionAdd || ""}
							onChange={(e) => setParam({ ...param, optionAdd: e.target.value })}
							type="text"
							className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
						>
							<option value={null}>Select</option>
							{Object.keys(categories || {})?.map((category) => {
								return <option>{category}</option>;
							})}
						</select>
					</div>
					<button
						onClick={() => handleAdd()}
						className="mt-9 border-blue-600 text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
					>
						{loader?.add ? <Loader w="6" h="6" color={'blue'} /> :"Add"}
					</button>
				</div>
				<div className="text-xl text-orange-500 mx-5 mt-10">Delete Job Filter</div>
				<div className="flex justify-start items-center mt-5">
					<div className="mx-5 space-y-3">
						<label className="font-light">Select Filter Type</label>
						<select
							value={param?.optionDel || ""}
							onChange={(e) => setParam({ ...param, optionDel: e.target.value })}
							type="text"
							className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
						>
							<option value={null}>Select</option>
							{Object.keys(categories || {})?.map((category) => {
								return <option>{category}</option>;
							})}
						</select>
					</div>
					<div className="mx-5 space-y-3">
						<label className="font-light">Select Options</label>
						<select
							onChange={(e) => setParam({ ...param, id: e.target.value })}
							value={param?.id || ''}
							type="text"
							className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
						>
							<option value={null}>Select</option>
							{categories[param?.optionDel]?.map((category) => {
								return (
									<option value={category?.id}>
										{category?.name ||
											category?.title ||
											category?.experience ||
											category?.education_level ||
											category?.seniority_name}
									</option>
								);
							})}
						</select>
					</div>
					<button
						onClick={() => handleDelete()}
						className="mt-9 border-red-600 text-red-600 hover:bg-red-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
					>
						{loader?.delete ? <Loader w="6" h="6" color={'red'} /> :"Delete"}
					</button>
				</div>
				<div className="text-xl text-orange-500 mx-5 mt-10">Update Country Name</div>
				<div className="flex justify-start items-center mt-5">
					<div className="mx-5 space-y-3">
						<label className="font-light">Select Country</label>
						<select
							value={param?.country || ""}
							onChange={(e) => setParam({ ...param, country: e.target.value })}
							type="text"
							className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
						>
							<option value={null}>Select</option>
							{(countries?.countries?.data || [])?.map((country) => {
								return <option>{country?.name}</option>;
							})}
						</select>
					</div>
					<div className="mx-5 space-y-3">
						<label className="font-light">Enter New Country Name</label>
						<input
							onChange={(e) => setParam({ ...param, country_name: e.target.value })}
							value={param?.country_name || ''}
							type="text"
							className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
						/>
					</div>
					<button
						onClick={() => handleCountryUpdate()}
						className="mt-9 border-orange-600 text-orange-600 hover:bg-orange-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
					>
						{loader?.country ? <Loader w="6" h="6" color={'orange'} /> :"Update"}
					</button>
				</div>
			</div>
		</>
	);
};
