import { dateFormat, formatDate } from "../../helper/dateFormat";

export const FilterField = ({ param, setParam, type, format, text }) => {
	return (
		<>
			{type === "created" ? (
				<div className="flex flex-col">
					<label className="font-light">Created At</label>
					<input
                        value={format ? formatDate(param?.created || ''): param?.created || ''}
						onChange={(e) =>
							setParam({ ...param, created: format ? dateFormat(e.target.value): e.target.value})
						}
						type="date"
						className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
					/>
				</div>
			) : type === "date range" ? (
				<div className="flex flex-col">
					<label className="font-light">{text || "Select Date Range"}</label>
					<div className="flex flex-row">
						<input
							type="date"
							value={param?.startDate || ''}
							onChange={(e) =>
								setParam({ ...param, startDate: e.target.value })
							}
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-40 p-2.5 mt-2"
						/>
						<span className="mt-4 mx-5">-</span>
						<input
							onChange={(e) =>
								setParam({ ...param, endDate: e.target.value })
							}
							type="date"
							value={param?.endDate || ''}
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-40 p-2.5 mt-2"
						/>
					</div>
				</div>
			) : (
				<div className="flex flex-col">
					<label className="capitalize font-light">{type}</label>
					<input
						value={param?.[type] || ''}
						onChange={(e) => setParam({ ...param, [type]: e.target.value })}
						className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
					/>
				</div>
			)}
		</>
	);
};
