import { useState, Fragment, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import DraftEditor from "../../components/DraftEditor/index.js";
import { fetchCategories, addFaq, fetchPages, getFaq } from "../../redux/faqsSlice.ts";
import { Loader } from "../../components/loader/index.js";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { LuChevronsUpDown } from "react-icons/lu";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";

export const EditFaq = () => {
  const { categories, isLoading, pages, faqInfo } = useSelector((store) => store.faqs);
  const [data, setData] = useState();
  const [draft, setDraft] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1 });
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [error, setError] = useState("");
  const [selectedPage, setSelectedPage] = useState([]);
  const [query, setQuery] = useState('');
  const [pageQuery, setPageQuery] = useState('');
  const { id } = useParams();

  useEffect(() => {
    dispatch(getFaq(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchCategories({ params: params }));
    dispatch(fetchPages({ params: params }));
  }, []);

  useEffect(() => {
    if (faqInfo){
      const updatedData = { ...faqInfo, category_id: faqInfo?.category?.faq_category_id, page_id: faqInfo?.page?.id };
      setData(updatedData);
      setSelectedCategory(updatedData?.category);
      setSelectedPage(updatedData?.page);
      setDraft(updatedData?.description);
    }
  }, [faqInfo]);

  const handleAdd = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const combinedData = { ...data, description: draft };
    if (!combinedData?.title || !combinedData.description || combinedData.description === "<p><br></p>") {
      const errorMessage = !combinedData?.title ? "Title is required field." :
        !combinedData.description || combinedData.description === "<p><br></p>" ? "Content is required field." : "";
      setError(errorMessage);
      return;
    }
    delete combinedData.category;
    delete combinedData.created_at;
    delete combinedData.faq_category_id;
    delete combinedData.page;
    delete combinedData.updated_at;

      Object.keys(combinedData).forEach((key) => {
        formData.append(key, combinedData[key]);
      });
    setError("");
    dispatch(addFaq({ formData: { formData } }, navigate));
  };

  const handleCategorySelected = (category) => {
    setSelectedCategory(() => {
      const updatedSelected = category;
      setData((prevData) => ({ ...prevData, category_id: updatedSelected?.faq_category_id }));
      return updatedSelected;
    });
  };

  const handlePageSelected = (page) => {
    setSelectedPage(() => {
      const updatedSelected = page;
      setData((prevData) => ({ ...prevData, page_id: updatedSelected?.id }));
      return updatedSelected;
    });
  };

  const filteredCategories =
    query === ''
      ? categories?.data || []
      : (categories?.data || []).filter((category) =>
        category?.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      );

  const filteredPages =
    pageQuery === ''
      ? pages?.data || []
      : (pages?.data || []).filter((page) =>
        page?.title
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(pageQuery.toLowerCase().replace(/\s+/g, ''))
      );

  if (isLoading?.getFaq) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader w="20" h="20" color="orange" />
      </div>
    );
  }

  return (
    <>
      <Breadcrumbs
				breadcrumbs={[
					{ name: "Faqs", link: "/contents/faqs" },
					{ name: "Details", link: `/contents/faq/${id}` },
          { name: "Edit Faq", link: `` },
				]}
			/>
    <div className="bg-white px-4 py-6 mx-[55px] rounded-lg shadow-lg">
      <form onSubmit={handleAdd} className="flex flex-col justify-center items-center mt-5">
        <div className="w-full mx-auto">
          <div className="grid md:grid-cols-4 md:gap-20">
            <div className="relative md:col-start-1 md:col-span-2 w-full mb-5 group">
              <input
                type="text"
                name="title"
                id="title"
                //readOnly
                className="block py-2.5 px-0 w-full text-sm text-ornage bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                placeholder=" "
                value={data?.title || ''}
                onChange={(e) =>
                  setData({ ...data, title: e.target.value })
                }
                required
              />
              <label
                htmlFor="title"
                className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Title *
              </label>
            </div>
            <div className="md:col-start-3 md:col-span-2 relative z-99 w-full mb-5 group">
              <Combobox value={selectedCategory} onChange={handleCategorySelected}>
                <div className="relative">
                  <div className="relative w-full cursor-default overflow-hidden text-left focus:outline-none focus-visible:ring-2 sm:text-sm">
                    <Combobox.Input
												className="text-gray-900 border-b-2 border-gray-300 sm:text-sm focus:ring-orange-600 focus:border-orange-600 placeholder:text-orange-500 block w-full p-2.5 px-0 outline-none"
                      displayValue={(category) => category?.name}
                      onChange={(event) => setQuery(event.target.value)}
                      placeholder="Select Category *"
                      autoComplete="off"
                      required
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                  >
                    <Combobox.Options className="absolute z-[999] mt-1 max-h-40 w-full overflow-auto ounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {filteredCategories.length === 0 && query !== '' ? (
                        <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                          Nothing found.
                        </div>
                      ) : (
                        filteredCategories
                          .map((category) => (
                            <Combobox.Option
                              key={category.id}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-orange-600 text-white" : "text-gray-900"
                                }`
                              }
                              value={category}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {category?.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                                    >
                                      <FaCheckCircle className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Combobox.Option>
                          ))
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
          </div>
          <div className="grid md:grid-cols-4 md:gap-20">
            <div className="relative md:col-start-1 md:col-span-2 z-[99] w-full mb-5 group">
              <Combobox value={selectedPage} onChange={handlePageSelected}>
                <div className="relative">
                  <div className="relative w-full cursor-default overflow-hidden text-left focus:outline-none focus-visible:ring-2 sm:text-sm">
                    <Combobox.Input
												className="text-gray-900 border-b-2 border-gray-300 sm:text-sm focus:ring-orange-600 focus:border-orange-600 placeholder:text-orange-500 block w-full p-2.5 px-0 outline-none"
                      displayValue={(page) => page?.title}
                      onChange={(event) => setPageQuery(event.target.value)}
                      placeholder="Select Page *"
                      autoComplete="off"
                      required
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setPageQuery('')}
                  >
                    <Combobox.Options className="absolute z-[999] mt-1 max-h-40 w-full overflow-auto ounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {filteredPages.length === 0 && pageQuery !== '' ? (
                        <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                          Nothing found.
                        </div>
                      ) : (
                        filteredPages
                          .map((page) => (
                            <Combobox.Option
                              key={page.id}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-orange-600 text-white" : "text-gray-900"
                                }`
                              }
                              value={page}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {page?.title}
                                  </span>
                                  {selected ? (
                                    <span
                                      className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                                    >
                                      <FaCheckCircle className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Combobox.Option>
                          ))
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
          </div>
          <div>
            <div className="text-sm text-orange-500 mb-3">
              Description  *
            </div>
            <div className="relative w-full mb-5 group border border-black">
              <DraftEditor onChange={setDraft} title={data?.description} />
            </div>
            {error && <p className="my-2 text-red-500">{error}</p>}
          </div>
          <button
					  className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
          >
            {isLoading?.addFaq ? (
              <div>
                <Loader h="6" w="6" color={"blue"} />
              </div>
            ) : (
              "Update"
            )}
          </button>
        </div>
      </form>
    </div>
    </>
  );
};
