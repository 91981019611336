import { toast } from 'react-hot-toast';


export const toastNotification = ({status, message}) => {
    if (status === "success") {
        toast.success(message)
    } else if (status === "warning") {
        toast(message, {
            icon: '⚠️',
          });
    } else if (status === "error") {
        toast.error(message)
    }
}
