import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { fetchFaqs, deleteFaq } from "../../redux/faqsSlice.ts";
import { Pagination } from "../../components/pagination/index.js";
import { AddBtn } from "../../components/addBtn/index.js";
import { FilterBtn } from "../../components/filterBtn/index.js";
import { FilterField } from "../../components/filterField/index.js";
import DeleteModal from "../../components/deleteModal/index.js";
import { Table } from "../../components/table/index.jsx";

export const Faqs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { faqs, isLoading } = useSelector((store) => store.faqs);
  const [id, setId] = useState("");
  const [params, setParams] = useState({ page: 1 });
  const deleteRef = useRef(null);

  useEffect(() => {
    dispatch(fetchFaqs({ params: params }));
  }, []);

  function handlePage(page) {
    dispatch(fetchFaqs({ params: { ...params, page: page } }));
  }

  const handleSearch = () => {
    dispatch(fetchFaqs({ params: params }));
  }

  const handleClear = () => {
    setParams({});
    dispatch(fetchFaqs({ params: { page: 1 } }));
  };

  const handleDelete = (id) => {
    setId(id);
    deleteRef?.current?.open({
      id: id,
      deleteFun: deleteFaq,
      type: "Faq",
    })
  }

  const handleView = (id) =>navigate(`/contents/faq/${(id)}`);

  const tableData = [
		{value: "title", to: '/contents/faq/{faq_id}'},
		{key: "Page", nested: true, value1: "page", value2: "title"},
		{key: "Status", value: "is_active", type:'status'},
		{key: "Created", value: "created_at", type:'date'},
	];

	const actions = [
		{type: 'view', func: handleView, isLoading: false},
		{type: 'delete', func: handleDelete, isLoading: isLoading?.deleteFaq}
	];

  return (
    <div className="relative">
      <DeleteModal ref={deleteRef} />
      <div className="flex justify-between items-center space-x-10">
        <AddBtn
          to={"/contents/faq/add"}
          mt={5}
          text={"Add Faq"}
        />
        <div className="flex">
          <div className="flex flex-col justify-center pr-3 mt-2">
            <FilterField
              param={params}
              setParam={setParams}
              type={"title"}
            />
        </div>
          <div className="flex flex-col mt-2">
            <FilterField
              param={params}
              setParam={setParams}
              type={"created"}
            />
          </div>
        <div className="flex justify-center items-center px-2 mt-7 gap-2">
            <FilterBtn
              func={handleSearch}
              disabled={!params?.title && !params?.created}
            />
            <FilterBtn
              func={handleClear}
              disabled={!params?.title && !params?.created}
              text={"Clear"}
              color={"red"}
            />
        </div>
        </div>
      </div>
      <div className={`h-[calc(100vh-315px)] overflow-auto mt-5 hide-scroll`}>
        <Table data={tableData} table={faqs} actions={actions} id={id} isLoading={isLoading?.fetchFaqs} />
      </div>
      <Pagination
        total={faqs?.last_page}
        current={faqs?.current_page}
        func={handlePage}
      />
    </div>
  );
};
