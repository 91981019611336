import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components/loader";
import { useEffect } from "react";
import { fetchJobViews } from "../../../redux/jobsSlice.ts";
import { Link, useParams } from "react-router-dom";
import { Pagination } from "../../../components/pagination";
import { Unavailable } from "../../../components/noData";
import { Table } from "../../../components/table/index.jsx";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const ViewUsers = () => {
	const { jobViews, jobInfo, isLoading } = useSelector((store) => store.jobs);
	const dispatch = useDispatch();
	const { id } = useParams();

	useEffect(() => {
		dispatch(fetchJobViews({ page: 1, id: id }));
	}, []);

	const handlePage = (page) => {
		dispatch(fetchJobViews({ page: page, id: id }));
	};

	const tableData = [
		{type: "fullName", to: '/users/user-detail/{id}', nested:true},
		{key: "Primary Id", nested: true, value1:'user', value2:'id'},
		{key: "Viewed On", value: "created_at", type: 'date'},
	];

	return (
		<>
			<Breadcrumbs breadcrumbs={[
					{name: "Jobs", link: "/jobs/view-all"},
					{name: jobInfo?.title ?? 'Details', link: `/jobs/detail/${id}`},
					{name: "Job Views", link: ``},
			]} />
			<div className="mb-1 relative px-10">
				<div className={`h-[calc(100vh-200px)] overflow-auto`}>
					<Table data={tableData} table={jobViews} isLoading={isLoading?.jobViews} />
				</div>
				<Pagination
					total={jobViews?.last_page}
					current={jobViews?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
