import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { deleteSection, fetchCaseStudySections } from "../../../redux/caseStudiesSlice.ts";
import { AddBtn } from "../../../components/addBtn/index.js";
import { Loader } from "../../../components/loader/index.js";
import { useEffect, useRef, useState } from "react";
import DeleteModal from "../../../components/deleteModal/index.js";
import { convertDateToWords } from "../../../helper/dateFormat.js";
import { Table } from "../../../components/table/index.jsx";

export const Sections = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { sections, isLoading } = useSelector((store) => store.caseStudies);
  const [idToDelete, setIdToDelete] = useState(null);
  const deleteRef = useRef(null);

  useEffect(() => {
    dispatch(fetchCaseStudySections(id));
  }, [dispatch, id]);

  if (isLoading?.fetchSections) {
    return (
      <div className="flex justify-center">
        <Loader w="20" h="20" color="orange" />
      </div>
    );
  };

  const handleView = (id, paramId) =>navigate(`/contents/case-studies/${paramId}/sections/${id}`);

  const handleDelete = (id) => {
    setIdToDelete(id)
    deleteRef?.current?.open({
      id: id,
      deleteFun: deleteSection,
      type: "Case Study Section",
    })
  }

  const tableData = [
		{value: "section_name", to: `/contents/case-studies/{paramId}/sections/{id}`},
		{key: "Created", value: "created_at", type:'date'},
	];

	const actions = [
		{type: 'view', func: handleView, isLoading: false},
		{type: 'delete', func: handleDelete, isLoading: isLoading?.deleteSection}
	];

  return (
    <div className="mb-1 relative p-5">
      <DeleteModal ref={deleteRef} />
      <div className="flex justify-between items-center space-x-10">
        <AddBtn
          to={`/contents/case-studies/${id}/sections/add`}
          mt={5}
          text={"Add Section"}
        />
      </div>
      <div className={`h-[calc(100vh-330px)] overflow-auto mt-5`}>
        <Table data={tableData} table={{data: sections}} actions={actions} id={idToDelete} />
      </div>
    </div>
  );
};
