export const Content = ({displayTerm}) => {
    return (
        <>
            <ol className="relative border-s z-10 border-gray-200">
            {displayTerm?.map((data, index) => {
                return (
                    <li className="mb-10 ms-4" key={index}>
                        <div className={`absolute w-3 h-3 bg-gray-${(index+1)*100} rounded-full mt-1.5 -start-1.5 border border-gray-400`}></div>
                        <h3 className="text-lg font-semibold text-gray-900">
                            {data.title}
                        </h3>
                        <p className="mb-4 text-base font-normal text-gray-500">
                            {data.description}
                        </p>
                    </li>
                )
            })}
            </ol>
        </>

    )
}