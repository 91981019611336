import { useState, useImperativeHandle, forwardRef } from "react";
import { toastNotification } from "../../../../services/notification-service.js";
import { BsFileEarmarkTextFill } from "react-icons/bs";
import { Upload } from "../../../../components/upload/index.jsx";
import Api from "../../../../services/api.js";
import { Loader } from "../../../../components/loader/index.js";

const AttachmentModal = (
	{ setData: setCompanyData, data: companyData },
	ref
) => {
	const [data, setData] = useState();
	const [show, setShow] = useState(false);
	const [modal, setModal] = useState();
	const [loader, setLoader] = useState(false);

	useImperativeHandle(ref, () => ({
		open({ data, status }) {
			setShow(true);
			setModal(status);
			if (status === "add") {
				setData({
					company_id: data?.company_id,
					file: data?.url,
					name: data?.name,
				});
			} else {
				setData({
					company_id: data?.company_id,
					file: data?.url,
					name: data?.name,
					id: data?.id,
				});
			}
		},
	}));

	const handleFileChange = async (e) => {
		const files = e.target.files;
		const file = files[0];
		const inputThumb = document.getElementById(e.target.id);
		if (inputThumb) {
			inputThumb.value = "";
		}
        if (e.target.name === "thumb") {
            if (!file?.type?.includes("image")) {
                toastNotification({
                    status: "error",
                    message: "Select Image Only!",
                });
                return;
            }
            if (file.size > 2 * 1024 * 1024) {
                toastNotification({
                    status: "error",
                    message: "Thumbnail size exceeds the maximum limit of 2 MB!",
                });
                return;
            }
            setData({ ...data, [e.target.name]: file });
            return;
        }
		if (file.size > 20 * 1024 * 1024) {
			toastNotification({
				status: "error",
				message: "File size exceeds the maximum limit of 20 MB!",
			});
			return;
		}
		setData({ ...data, [e.target.name]: file, name: file?.name });
	};

	const handleAdd = async (e) => {
		e.preventDefault();
		if (!data?.file) {
			toastNotification({
				status: "error",
				message: "Please attach attachment!",
			});
			return;
		}

		const formData = new FormData();

		Object.keys(data).forEach((key) => {
			formData.append(key, data[key]);
		});

		setLoader(true);
		const json = await Api.addCompanyAttachement({ formData });
		setLoader(false);
		if (!!json) {
			if (json?.status === 200) {
				const retrievedData = json?.data?.data;
				setShow(false);
				toastNotification({
					status: "success",
					message: "Attachment Added!",
				});
				setCompanyData({
					...companyData,
					attachments: [...(companyData?.attachments || []), retrievedData],
				});
			} else {
				toastNotification({
					status: "error",
					message: json?.response?.data?.message || "Error",
				});
			}
		}
	};

	return (
		<>
			{show && (
				<div
					className="relative z-50"
					aria-labelledby="modal-title"
					role="dialog"
					aria-modal="true"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<form
								onSubmit={handleAdd}
								className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
							>
								<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
									<div className="flex flex-col justify-start items-start gap-y-4">
										<div className="relative md:col-start-1 md:col-span-2 z-20 w-full mb-5 group">
											<label className="block uppercase mb-2 font-bold text-center text-orange-600 text-lg">
												{modal} Attachment
											</label>
										</div>
										<div className="w-full">
											<label className="block mb-2 font-light text-orange-600">
												File Name *
											</label>
											<input
												value={data?.name || ""}
												onChange={(e) =>
													setData({ ...data, name: e.target.value })
												}
												type="text"
												className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
												required
											/>
										</div>
										<div className="w-full mt-3">
											<label className="block mb-2 font-light text-orange-600">
												Upload Attachment (Max Allowed Size: 20 mb) *
											</label>
											<Upload id={"file_upload"}>
												<input
													id="file_upload"
													type="file"
													hidden="true"
													name="file"
													onChange={handleFileChange}
													max={1}
												/>
											</Upload>
										</div>
										{data?.file && (
											<a
												href={
													typeof data?.file === "string"
														? data?.file
														: URL.createObjectURL(data?.file)
												}
												className="hover:text-orange-500"
												target="_blank"
												rel="noopener noreferrer"
											>
												{data?.file?.name}
											</a>
										)}
										{!data?.file?.type?.includes("image") && (
											<>
												<div className="w-full mt-3">
													<label className="block mb-2 font-light text-orange-600">
														Thumbnail (Max Allowed Size: 2 mb)
													</label>
													<Upload id={"thumbnail"}>
														<input
															id="thumbnail"
															type="file"
															hidden="true"
                                                            accept="image/*"
															name="thumb"
															onChange={handleFileChange}
															max={1}
														/>
													</Upload>
												</div>
												{data?.thumb && (
													<img
														className="hover:text-orange-500 w-20"
														src={URL.createObjectURL(data?.thumb)}
                                                        alt="thumbnail"
													/>
												)}
											</>
										)}
									</div>
								</div>
								<div className="bg-transparent px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									<button
										type="submit"
										className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
									>
										{loader ? <Loader w="6" h="6" color="orange" /> : "Save"}
									</button>
									<button
										onClick={() => {
											setShow(false);
											setData({});
										}}
										type="button"
										className="inline-flex w-full justify-center border border-orange-600 rounded-md bg-white px-3 py-2 text-sm font-semibold text-orange-600 shadow-sm hover:opacity-90 sm:ml-3 sm:w-auto"
									>
										Back
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default forwardRef(AttachmentModal);
