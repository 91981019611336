import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

export const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
      navigate("/dashboard")
  }, [location?.pathname])
  
  return (
    <section>
      <div className="container mx-auto mt-20">
        <div className="xs:px-10 flex flex-wrap items-center justify-between rounded-lg border border-stroke px-6 md:px-8 lg:px-10 py-32">
          <div className="w-full">
            <div className="mb-6 md:mb-0">
              <div className="xs:text-2xl mb-1 text-xl font-semibold text-dark md:text-xl lg:text-2xl flex justify-center items-center">
                <div className="text-[50px] text-red-600 flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-20 h-20"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
                    />
                  </svg>
                  <div>404</div>
                </div>
                <div className="ml-5"> | Page not found</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
