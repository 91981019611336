import { useLocation, Link } from "react-router-dom";
import { Blogs } from "../../containers/blogs/index.js";
import { Categories } from "../../containers/blogs/categories/index.js";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";

export const BlogsHome = () => {
	const location = useLocation();
	const { type } = useParams();

	const aside = [
		{ title: "Blogs", route: "/contents/blogs" },
		{ title: "Categories", route: `/contents/blogs/categories` },
	];

	return (
		<div>
			<aside
				id="sidebar-multi-level-sidebar"
				className="fixed top-16 z-30 w-56 h-screen transition-transform -translate-x-full sm:translate-x-0"
				aria-label="Sidebar"
			>
				<div className="h-full px-3 py-4 overflow-y-auto bg-gray-100">
					<ul className="space-y-2">
						{aside.map((item) => {
							return (
								<li>
									<div
										className={`flex items-center p-2 transition rounded-full duration-200 ease-out group ${
											location.pathname === item.route
												? "bg-orange-500 text-white"
												: "text-gray-900 hover:bg-orange-200"
										}`}
									>
										<Link to={item.route} className="ms-3">
											{`> ${item.title}`}
										</Link>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</aside>

			<div className="p-4 ml-52 h-max">
				<Breadcrumbs
					breadcrumbs={[
						{ name: type === "categories" ? "Categories" : "Blogs", link: "" },
					]}
				/>
				<div className="p-4 border-2 mt-4 border-gray-200 border-dashed rounded-lg mx-12">
					{type === "categories" ? <Categories /> : <Blogs />}
				</div>
			</div>
		</div>
	);
};
