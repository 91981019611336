import { useEffect, useState } from "react";
import DraftEditor from "../../../components/DraftEditor";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchCategories,
	fetchNextCategory,
	addCourse,
	fetchCourseData,
	setCourseData,
} from "../../../redux/courses.ts";
import { Combobox, Transition } from "@headlessui/react";
import { Loader } from "../../../components/loader";
import { debounce } from "lodash";
import { toastNotification } from "../../../services/notification-service.js";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { useParams } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { capitalize } from "../../../helper/join.js";
import { Upload } from "../../../components/upload/index.jsx";


export const AddCourse = () => {
	const [data, setData] = useState();
	const [draft, setDraft] = useState();
	const [company, setCompany] = useState(null);
	const [speaker, setSpeaker] = useState(null);
	const { categories, isLoading, courseData } = useSelector(
		(store) => store.course
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { type, id, offtype } = useParams();
	let name = capitalize(offtype.slice(0, -1));

	useEffect(() => {
		if (type === "edit" && id) {
			dispatch(fetchCourseData(id));
		}
		if (type === "add") {
			setData(null);
			dispatch(setCourseData({}));
		}
	}, [type, id]);

	useEffect(() => {
		if (courseData?.course && type === "edit") {
			let course = { ...courseData?.course };
			course["course_videos"] = (courseData?.course?.trailers || [])
				?.filter((video) => {
					if (video?.youtube_link !== null) {
						course["youtube"] = true;
						course["youtube_thumbnail"] = video?.path;
						course["youtube_url"] = video?.youtube_link;
					}
					return video?.youtube_link === null;
				})
				?.map((path) => path?.path);
			course["course_requirement"] = (
				courseData?.course?.course_requirement || []
			)?.map((req) => req?.requirements);
			course['is_featured'] = !!courseData?.course?.is_training
			if ((courseData?.course?.course_session || [])?.length > 0) {
				course["live_session_title"] =
					courseData?.course?.course_session[0]?.title;
				course["live_session_url"] = courseData?.course?.course_session[0]?.url;
				course["live_session_date"] =
					courseData?.course?.course_session[0]?.date;
				course["live_session_type"] =
					courseData?.course?.course_session[0]?.is_paid;
			}
			if (
				!!courseData?.course?.course_objective &&
				courseData?.course?.course_objective !== "null"
			) {
				course["custom_package_price"] =
					courseData?.course?.course_objective || null;
			}
			setData(course);
			setDraft(course["course_description"]);
			if (course["company_description"]) {
				setCompany(course["company_description"]);
			}
			if (course["speaker_description"]) {
				setSpeaker(course["speaker_description"]);
			}
		}
	}, [courseData]);

	useEffect(() => {
		if (type === "add") {
			if (offtype === "courses") {
				setData({ ...data, data_type: "COURSE" });
			} else if (offtype === "products") {
				setData({ ...data, data_type: "PRODUCT" });
			} else if (offtype === "events") {
				setData({ ...data, data_type: "EVENT" });
			}
		}
	}, [offtype]);

	const dataType = ["EVENT", "PAST_EVENT"];

	const handleFileChange = async (e) => {
		if (e.target.name === "course_videos") {
			if ((data?.course_videos || [])?.length < 3) {
				const files = e.target.files;
				let newVideo = [...(data?.course_videos || [])];
				for (let i = 0; i < files.length; i++) {
					const file = files[i];
					newVideo.push(file);
				}
				if (!files?.[0]?.type?.includes("video")) {
					const inputThumb = document.getElementById(e.target.id);
					if (inputThumb) {
						inputThumb.value = '';
					}
					toastNotification({status: 'warning', message: 'Please upload video file only!'});
					return;
				}
				if (files?.[0]?.size > 20 * 1024 * 1024) {
					const inputThumb = document.getElementById(e.target.id);
					if (inputThumb) {
						inputThumb.value = '';
					}
					toastNotification({status: 'warning', message: 'File Size exceeded 20Mb limit!'});
					return;
				}
				setData({ ...data, course_videos: newVideo });
			} else {
				toastNotification({status: 'warning', message: "Can't upload more than 3!"})
			}
		} else {
			const files = e.target.files;
			if (files?.[0]?.type?.includes("image") && files?.[0]?.size > 1024000) {
				if (files?.[0]?.size > 1024000)
					toastNotification({
						status: "warning",
						message: "Please upload file of less than 1 Mb",
					});
				return;
			}
			if (!files?.[0]?.type?.includes("image")) {
				toastNotification({status: 'warning', message: 'Please upload image only!'});
				const inputThumb = document.getElementById(e.target.id);
				if (inputThumb) {
					inputThumb.value = '';
				}
				return;
			}
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				setData({ ...data, [e.target.name]: file });
			}
		}
	};

	const handleScroll = (e) => {
		const target = e.target;
		if (categories?.next_cursor) {
			if (target.scrollHeight - target.scrollTop === target.clientHeight) {
				dispatch(fetchNextCategory(categories?.next_cursor));
			}
		}
	};

	const delEntries = (arr, obj) => {
		let shadowData = { ...data };
		(arr || [])?.map((entry) => {
			delete shadowData[entry];
		});
		setData({ ...shadowData, ...obj });
	};

	const handleDataType = (e) => {
		const a = e.target.value;
		if (a === "EVENT" || a === "PAST_EVENT") {
			delEntries(
				["is_featured", "package", "custom_package_price", "is_shippable"],
				{ data_type: a }
			);
		} else if (a === "PRODUCT") {
			delEntries(["is_featured"], { data_type: a });
		} else if (a === "COURSE") {
			delEntries(["package", "custom_package_price", "is_shippable"], {
				data_type: a,
			});
		} else {
			setData({ ...data, data_type: a });
		}
	};

	const dispatchFetchCategories = debounce((value) => {
		dispatch(fetchCategories({ first: 10, name: value }));
	}, 1500);

	const handleSubmit = async (e) => {
		e.preventDefault();
		let category = data?.categories?.map((categ) => {
			return categ?.id;
		});

		let modifiedData = {
			...data,
			course_description: draft,
			categories: JSON.stringify(category),
			course_requirements: JSON.stringify(data?.course_requirement),
		};

		if (speaker) {
			modifiedData = {...modifiedData, speaker_description: speaker}
		}
		if (company) {
			modifiedData = {...modifiedData, company_description: company}
		}

		if (!modifiedData?.course_description || modifiedData?.course_description === "<p><br></p>") {
			toastNotification({
				status: "warning",
				message: `${name} Description is required!`,
			});
			return;
		}
		if (!modifiedData?.course_thunbnail) {
			toastNotification({
				status: "warning",
				message: `${name} Thumbnail is required!`,
			});
			return;
		}
		// if (
		//   !modifiedData?.youtube &&
		//   (modifiedData?.course_videos || [])?.length === 0
		// ) {
		//   toastNotification({
		//     status: "warning",
		//     message: "Upload atleast one video!",
		//   });
		//   return;
		// }
		if (modifiedData?.youtube && !modifiedData?.youtube_thumbnail) {
			toastNotification({
				status: "warning",
				message: "Please upload youtube thumbnail!",
			});
			return;
		}
		if ((data?.categories || [])?.length === 0) {
			toastNotification({
				status: "warning",
				message: "Atleast one category is required!",
			});
			return;
		}

		delete modifiedData?.course_videos;

		const formData = new FormData();
		data?.course_videos?.forEach((video, index) => {
			formData.append(`course_videos[${index}]`, video);
		});
		if (!("course_videos" in modifiedData)) {
			Object.keys(modifiedData).forEach((key) => {
				formData.append(key, modifiedData[key]);
			});
		}
		dispatch(
			addCourse({ formData: { formData } }, navigate, type, data?.id, offtype)
		);
	};

	if (isLoading?.courseData) {
		return (
			<>
				<Breadcrumbs
					breadcrumbs={[
						{ name: capitalize(offtype), link: `/offerings/${offtype}` },
						{
							name: `${type === "add" ? "Add" : "Edit"} ${capitalize(
								offtype
							)?.slice(0, -1)}`,
							link: "",
						},
					]}
				/>
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader h="20" w="20" color={"orange"} />
				</div>
			</>
		);
	}

	const handleRemoveVideo = (index) => {
		const newVideoData = [...data.course_videos];
		newVideoData.splice(index, 1);
		setData({ ...data, course_videos: newVideoData });
	};

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: capitalize(offtype), link: `/offerings/${offtype}` },
					{
						name: `${type === "add" ? "Add" : "Edit"} ${capitalize(
							offtype
						)?.slice(0, -1)}`,
						link: "",
					},
				]}
			/>
			<div className={`bg-white px-[55px] pb-4 ${isLoading?.addCourse && "pointer-events-none"}`}>
				<div className="flex flex-col justify-center items-center">
					<form
						onSubmit={handleSubmit}
						className="w-full mx-auto shadow-xl p-4 rounded-lg"
					>
						<div className="grid md:grid-cols-4 md:gap-20">
							<div className="relative md:col-start-1 md:col-span-2  w-full mb-5 group">
								<input
									type="text"
									name="floating_email"
									id="floating_email"
									className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
									placeholder=" "
									required
									value={data?.course_name || ""}
									onChange={(e) =>
										setData({ ...data, course_name: e.target.value })
									}
								/>
								<label
									htmlFor="floating_email"
									className="peer-focus:font-medium cursor-text absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									{name} Name<span>*</span>
								</label>
							</div>
							<div className="relative md:col-start-3 md:col-span-2 z-20 w-full mb-5 group">
								<Combobox
									onChange={(e) => {
										let a = {};
										categories?.data?.map((category) => {
											if (category?.name === e) {
												a = { name: category?.name, id: category?.id };
											}
										});
										if (a?.id) {
											setData({
												...data,
												categories:
													data && data.categories ? [...data.categories, a] : [a],
											});
										}
									}}
								>
									<div className="relative">
										<div className="flex justify-center">
											<Combobox.Input
												onChange={(e) => {
													const inputValue = e.target.value;
													dispatchFetchCategories.cancel();
													dispatchFetchCategories(inputValue);
												}}
												className="block py-2.5 px-0 w-full text-sm text-left text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
											></Combobox.Input>
											{isLoading?.categories && (
												<div className="mr-10">
													<Loader w="8" h="8" color="orange" />
												</div>
											)}
										</div>
										<Transition
											leave="transition ease-in duration-100"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
										>
											<Combobox.Options
												onScroll={handleScroll}
												className="absolute mt-1 max-h-40 w-full overflow-auto ounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
											>
												{categories?.data
													?.filter((cat) => {
														return !data?.categories
															?.map((c) => c?.name)
															?.includes(cat?.name);
													})
													?.map((person, personIdx) => (
														<Combobox.Option
															key={personIdx}
															className={({ active }) =>
																`relative cursor-default select-none py-2 pl-10 pr-4 ${
																	active
																		? "bg-orange-600 text-white"
																		: "text-gray-900"
																}`
															}
															value={person?.name}
														>
															{({ selected }) => (
																<>
																	<span
																		className={`block truncate ${
																			selected ? "font-medium" : "font-normal"
																		}`}
																	>
																		{person.name}
																	</span>
																	{selected ? (
																		<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
																	) : null}
																</>
															)}
														</Combobox.Option>
													))}
											</Combobox.Options>
										</Transition>
									</div>
								</Combobox>
								<label
									htmlFor="floating_email"
									className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									Categories<span>*</span>
								</label>
							</div>
						</div>
						<div className="grid md:grid-cols-4 ">
							{(data?.categories || [])?.length > 0 && (
								<div className="md:col-start-1 md:col-span-4 relative w-full mb-5 group">
									<span className="text-sm text-orange-500">
										Selected Categories:
									</span>
									<div className="gap-2 flex flex-wrap mt-1">
										{data?.categories?.map((cat) => {
											return (
												<div className="flex items-center gap-x-3 px-3 py-1 border-2 border-orange-500 text-center rounded-full">
													<p className="text-[15px] shrink-0">{cat?.name}</p>
													<button
														type="button"
														onClick={() =>
															setData({
																...data,
																categories: data?.categories?.filter(
																	(category) => category?.name !== cat?.name
																),
															})
														}
													>
														<RxCross1 size={18} className="bg-orange-500 rounded-full p-0.5" color="white" />
													</button>
												</div>
											);
										})}
									</div>
								</div>
							)}
							{offtype === "events" && (
								<div className="md:col-start-1 md:col-span-2 relative  w-full mb-5 group">
									<select
										type="text"
										name="floating_password"
										id="floating_password"
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=" "
										required
										value={data?.data_type || ""}
										onChange={handleDataType}
									>
										<option value={""}>Select data type</option>
										{dataType?.map((t) => {
											return <option value={t}>{t}</option>;
										})}
									</select>
									<label
										htmlFor="floating_password"
										className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Data Type<span>*</span>
									</label>
								</div>
							)}
						</div>
						<div className="grid md:grid-cols-4 md:gap-20">
							<div className="md:col-start-1 md:col-span-2 gap-5 flex items-center">
								<div className="relative  w-full mb-5 group">
									<input
										type="text"
										readOnly
										value={"Please add multiple requirements"}
										name="floating_email"
										id="floating_email"
										className="block py-2.5 px-0 w-full text-sm text-orange-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 peer"
										placeholder=" "
										required
									/>
								</div>
								<button
									type="button"
									onClick={() => {
										if ((data?.course_requirement || [])?.length < 5) {
											setData({
												...data,
												course_requirement: [
													...(data?.course_requirement || []),
													"",
												],
											});
										}
									}}
									className="text-orange-600 h-max font-medium rounded-lg text-sm w-max sm:w-auto px-3 py-1 max-h-[28px] max-w-[56px] text-center"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
										/>
									</svg>
								</button>
							</div>
							<div className="md:col-start-3 md:col-span-2 relative  w-full mb-5 group">
								<input
									type="date"
									name="floating_password"
									id="floating_password"
									value={data?.published_at?.slice(0, 10) || ""}
									onChange={(e) =>
										setData({ ...data, published_at: e.target.value })
									}
									className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
									placeholder=" "
									min={data?.data_type === 'PAST_EVENT' ? '' : new Date().toISOString().split('T')[0]}
									required
								/>
								<label
									htmlFor="floating_password"
									className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									{name} publishing date *
								</label>
							</div>
						</div>
						<div className="grid md:grid-cols-8 md:gap-x-20">
							{data?.course_requirement?.map((require, index) => {
								return (
									<div className="md:col-span-4 gap-x-5 flex items-center">
										<div className="relative w-full mb-5 group">
											<input
												type="text"
												onChange={(e) =>
													setData({
														...data,
														course_requirement: data?.course_requirement?.map(
															(r, i) => {
																return i === index ? e.target.value : r;
															}
														),
													})
												}
												value={data?.course_requirement[index]}
												name="floating_email"
												id="floating_email"
												className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
												placeholder=" "
												required
												autoFocus
											/>
											<label
												htmlFor="floating_email"
												className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
											>
												Requirement#{index + 1}
											</label>
										</div>
										<button
											type="button"
											onClick={() => {
												setData({
													...data,
													course_requirement: data?.course_requirement?.filter(
														(r, i) => {
															return i !== index;
														}
													),
												});
											}}
											className="text-red-600 h-max font-medium rounded-lg text-lg w-max sm:w-auto px-2 py-0.5 text-center"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth="1.5"
												stroke="currentColor"
												className="w-6 h-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
												/>
											</svg>
										</button>
									</div>
								);
							})}
						</div>
						<div className="grid md:grid-cols-8 md:gap-20 my-4 bg-orange-100 p-4 rounded-lg">
							<div class="flex items-center col-start-1 col-span-2">
								<input
									id="checked-checkbox"
									type="checkbox"
									checked={data?.live_session_title?.length >= 0}
									onChange={(e) => {
										if (!e.target.checked) {
											delEntries([
												"live_session_title",
												"live_session_url",
												"live_session_date",
												"live_session_type",
											]);
										} else {
											let dat = { ...data };
											dat = {
												...dat,
												live_session_title: "",
												live_session_type: false,
											};
											setData(dat);
										}
									}}
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
								/>
								<label
									htmlForfor="checked-checkbox"
									className="ms-2 text-sm font-medium text-gray-900"
								>
									Add live session link
								</label>
							</div>
							<div class="flex items-center col-start-3 col-span-2">
								<input
									id="checked-checkbox"
									type="checkbox"
									checked={data?.youtube || false}
									onChange={(e) => {
										setData({ ...data, youtube: e.target.checked });
										if (!e.target.checked) {
											delEntries(["youtube_thumbnail", "youtube_url"], {
												youtube: e.target.checked,
											});
										}
									}}
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
								/>
								<label
									htmlForfor="checked-checkbox"
									className="ms-2 text-sm font-medium text-gray-900"
								>
									Add Youtube Redirect Url
								</label>
							</div>
							{(data?.data_type === "EVENT" || data?.data_type === "PAST_EVENT") && <div class="flex items-center col-start-5 col-span-2">
								<input
									id="checked-checkbox"
									type="checkbox"
									checked={data?.register || false}
									onChange={(e) => {
										if (!e.target.checked) {
											delEntries([
												"register_url",
												"register"
											]);
										} else {
											let dat = { ...data };
											dat = {
												...dat,
												register: true
											};
											setData(dat);
										}
									}}
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
								/>
								<label
									htmlForfor="checked-checkbox"
									className="ms-2 text-sm font-medium text-gray-900"
								>
									Registeration Url
								</label>
							</div>}
							{(data?.data_type === "EVENT" || data?.data_type === "PAST_EVENT") && <div class="flex items-center col-start-7 col-span-2">
								<input
									id="checked-checkbox"
									type="checkbox"
									checked={data?.is_external_event || false}
									onClick={(e) => {
										setData({...data, is_external_event: e.target.checked})
									}}
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
								/>
								<label
									htmlForfor="checked-checkbox"
									className="ms-2 text-sm font-medium text-gray-900"
								>
									Partner's Event
								</label>
							</div>}
							{data?.data_type === "COURSE" && (
								<div class="flex items-center col-start-5 col-span-2">
									<input
										id="checked-checkbox"
										type="checkbox"
										checked={data?.is_featured}
										onChange={(e) =>
											setData({ ...data, is_featured: e.target.checked })
										}
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
									/>
									<label
										htmlForfor="checked-checkbox"
										className="ms-2 text-sm font-medium text-gray-900"
									>
										{name} is featured
									</label>
								</div>
							)}
							{data?.data_type === "PRODUCT" && (
								<>
									<div class="flex items-center col-start-5 col-span-2">
										<input
											id="checked-checkbox"
											type="checkbox"
											checked={!!data?.is_shippable || false}
											onChange={(e) =>
												setData({
													...data,
													is_shippable: e.target.checked ? 1 : 0,
												})
											}
											className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
										/>
										<label
											htmlForfor="checked-checkbox"
											className="ms-2 text-sm font-medium text-gray-900"
										>
											is shippable
										</label>
									</div>
									<div class="flex items-center col-start-7 col-span-2">
										<input
											id="checked-checkbox"
											type="checkbox"
											checked={data?.custom_package_price !== undefined}
											onChange={(e) => {
												if (e.target.checked) {
													setData({ ...data, custom_package_price: 0 });
												} else {
													delEntries(["custom_package_price"]);
												}
											}}
											className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
										/>
										<label
											htmlForfor="checked-checkbox"
											className="ms-2 text-sm font-medium text-gray-900"
										>
											Has custom package (Item Based)
										</label>
									</div>
								</>
							)}
						</div>
						{data?.live_session_title !== undefined && (
							<div className="grid md:grid-cols-4 md:gap-20">
								<div className="relative md:col-start-1 md:col-span-1  w-full mb-5 group">
									<input
										type="text"
										name="floating_email"
										id="live_session_title"
										value={data?.live_session_title || ""}
										onChange={(e) =>
											setData({ ...data, live_session_title: e.target.value })
										}
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=" "
										required
									/>
									<label
										htmlFor="live_session_title"
										className="peer-focus:font-medium cursor-text absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Title
									</label>
								</div>
								<div className="md:col-start-2 md:col-span-1 relative  w-full mb-5 group">
									<input
										type="url"
										name="floating_password"
										id="live_session_url"
										value={data?.live_session_url || ""}
										onChange={(e) =>
											setData({ ...data, live_session_url: e.target.value })
										}
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=""
										required
									/>
									<label
										htmlFor="live_session_url"
										className="peer-focus:font-medium cursor-text absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Live Session Url
									</label>
								</div>
								<div className="relative md:col-start-3 md:col-span-1  w-full mb-5 group">
									<input
										type="date"
										name="floating_email"
										id="floating_email"
										value={data?.live_session_date || ""}
										onChange={(e) =>
											setData({ ...data, live_session_date: e.target.value })
										}
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=" "
										required
										min={new Date().toISOString().split('T')[0]}
									/>
									<label
										htmlFor="floating_email"
										className="peer-focus:font-medium cursor-text absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Date
									</label>
								</div>
								<div class="flex items-center col-start-4 col-span-1">
									<input
										id="checked-checkbox"
										type="checkbox"
										checked={data?.live_session_type || false}
										onChange={(e) =>
											setData({ ...data, live_session_type: e.target.checked })
										}
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
									/>
									<label
										htmlForfor="checked-checkbox"
										className="ms-2 text-sm font-medium text-gray-900"
									>
										Paid Session
									</label>
								</div>
							</div>
						)}
						{data?.youtube && (
							<div className="grid md:grid-cols-4 md:gap-20">
								<div className="relative  w-full mb-5 group md:col-start-1 md:col-span-1">
									<label
										htmlFor="youtube_thumbnail"
										className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Thumbnail <span>*</span>
									</label>
									<Upload id={"youtube_thumbnail"}>
										<input
											id="youtube_thumbnail"
											type="file"
											accept="image/*"
											hidden="true"
											name="youtube_thumbnail"
											onChange={handleFileChange}
											max={1}
										/>
									</Upload>
									{data?.youtube_thumbnail && (
										<div className="relative">
											{typeof data?.youtube_thumbnail === "string" ? (
												<img
													src={data?.youtube_thumbnail}
													alt="Uploaded Preview"
													className="ml-4 mt-4 w-20 h-20 rounded"
												/>
											) : (
												<img
													src={URL.createObjectURL(data?.youtube_thumbnail)}
													alt="Uploaded Preview"
													className="ml-4 mt-4 w-20 h-20 rounded"
												/>
											)}
											<IoMdClose
												className="cursor-pointer text-red-500 border border-red-500 absolute left-0 top-0 h-4 w-4 rounded-full bg-white"
												onClick={() => {
													delEntries(["youtube_thumbnail"]);
													const inputThumb = document.getElementById('youtube_thumbnail');
													if (inputThumb) {
														inputThumb.value = '';
													}
												}}
											/>
										</div>
									)}
								</div>
								<div className="md:col-start-2 md:col-span-1 relative  w-full mb-5 group">
									<input
										type="url"
										name="youtube_url"
										id="youtube_url"
										value={data?.youtube_url || ""}
										onChange={(e) =>
											setData({ ...data, youtube_url: e.target.value })
										}
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=""
										required
									/>
									<label
										htmlFor="youtube_url"
										className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Youtube Url <span>*</span>
									</label>
								</div>
							</div>
						)}

						{data?.custom_package_price !== undefined &&
							data?.custom_package_price !== null && (
								<div className="grid md:grid-cols-4 md:gap-20">
									<div className="md:col-start-1 md:col-span-1 relative  w-full mb-5 group">
										<input
											type="number"
											name="floating_password"
											id="package_price"
											value={data?.custom_package_price || ""}
											onChange={(e) => 
												Number(e.target.value) > -1 &&
												setData({
													...data,
													custom_package_price: e.target.value,
												})
											}
											className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
											placeholder=""
											required
										/>
										<label
											htmlFor="package_price"
											className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
										>
											Package Price in USD <span>*</span>
										</label>
									</div>
								</div>
							)}
							{data?.register && (
								<div className="grid md:grid-cols-4 md:gap-20">
									<div className="md:col-start-1 md:col-span-1 relative  w-full mb-5 group">
										<input
											type="url"
											name="reg_url"
											id="reg_url"
											value={data?.register_url || ""}
											onChange={(e) =>
												setData({
													...data,
													register_url: e.target.value,
												})
											}
											className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
											placeholder=""
											required
										/>
										<label
											htmlFor="reg_url"
											className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
										>
											Registeration Url <span>*</span>
										</label>
									</div>
								</div>
							)}
						<div className="text-sm text-orange-500 mb-3">
							{name} description <span>*</span>
						</div>
						<div className="relative w-full mb-5 group border border-gray-500">
							<DraftEditor
								onChange={setDraft}
								title={courseData?.course?.course_description}
							/>
						</div>
						{offtype === "events" && (
							<div className="grid md:grid-cols-8 md:gap-x-20">
								<div className="md:col-start-1 md:col-span-2 relative w-full mb-5 group">
									<input
										id="company_name_input"
										type="text"
										value={data?.company_name || ""}
										onChange={(e) =>
											setData({ ...data, company_name: e.target.value })
										}
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=" "
										required={data?.is_external_event || false}
									/>
									<label
										htmlFor="company_name_input"
										className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Company Name {data?.is_external_event && "*"}
									</label>
								</div>
							</div>
						)}
						{offtype === "events" && (
							<>
								<div className="text-sm text-orange-500 mb-3">
									About Company
								</div>
								<div className="relative w-full mb-5 group border border-gray-500">
									<DraftEditor
										onChange={setCompany}
										title={courseData?.course?.company_description}
									/>
								</div>
							</>
						)}
						{offtype === "events" && (
							<div className="grid md:grid-cols-8 md:gap-x-20">
								<div className="md:col-start-1 md:col-span-2 relative w-full mb-5 group">
									<input
										id="speaker_name_input"
										type="text"
										value={data?.speaker_name || ""}
										onChange={(e) =>
											setData({ ...data, speaker_name: e.target.value })
										}
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=" "
									/>
									<label
										htmlFor="speaker_name_input"
										className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Speaker Name
									</label>
								</div>
							</div>
						)}
						{offtype === "events" && (
							<>
								<div className="text-sm text-orange-500 mb-3">
									About Speaker
								</div>
								<div className="relative w-full mb-5 group border border-gray-500">
									<DraftEditor
										onChange={setSpeaker}
										title={courseData?.course?.speaker_description}
									/>
								</div>
							</>
						)}

						<div className="md:grid md:grid-cols-3">
							<div className="relative w-full mb-5 group md:col-start-1 md:col-span-1">
								<label
									htmlFor="course_thumbnail"
									className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									{name} Thumbnail<span>*</span>
								</label>
								<Upload id={"course_thumbnail"}>
									<input
										id="course_thumbnail"
										type="file"
										accept="image/*"
										hidden="true"
										//className="text-sm mt-4"
										name="course_thunbnail"
										onChange={handleFileChange}
										max={1}
									/>
								</Upload>
								{data?.course_thunbnail && (
									<div className="relative">
										{typeof data?.course_thunbnail === "string" ? (
											<img
												src={data?.course_thunbnail}
												alt="Uploaded Preview"
												className="ml-4 mt-4 w-20 h-20 rounded"
											/>
										) : (
											<img
												src={URL.createObjectURL(data?.course_thunbnail)}
												alt="Uploaded Preview"
												className="ml-4 mt-4 w-20 h-20 rounded"
											/>
										)}
										<IoMdClose
											className="cursor-pointer text-red-500 border border-red-500 absolute left-0 top-0 h-4 w-4 rounded-full bg-white"
											onClick={() => {
												delEntries(["course_thunbnail"]);
												const inputThumb = document.getElementById('course_thumbnail');
												if (inputThumb) {
													inputThumb.value = '';
												}
											}}
										/>
									</div>
								)}
							</div>
							<div className="relative  w-full mb-5 group md:col-start-2 md:col-span-1">
								<label
									htmlFor="course_videos"
									className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									{name} Videos
								</label>
								<Upload id={"course_videos"}>
									<input
										id="course_videos"
										type="file"
										accept="video/*"
										hidden="true"
										name="course_videos"
										onChange={handleFileChange}
										max={1}
									/>
								</Upload>
								{(data?.course_videos || []).map((preview, index) => (
									<div className="relative mt-3">
										{typeof preview === "string" ? (
											<video controls width="200">
												<source src={preview} />
											</video>
										) : (
											<video controls width="200">
												<source
													src={URL.createObjectURL(preview)}
													type={preview?.type}
												/>
												{preview?.name}
											</video>
										)}
										<IoMdClose
											className="cursor-pointer text-red-500 border border-red-500 absolute left-0 top-0 h-4 w-4 rounded-full bg-white"
											onClick={() => {
												handleRemoveVideo(index);
												const inputThumb = document.getElementById('course_videos');
												if (inputThumb) {
													inputThumb.value = '';
												}
											}}
										/>
									</div>
								))}
							</div>
							{/* <div className="relative  w-full mb-5 group md:col-start-3 md:col-span-1">
                <label
                  htmlFor="floating_phone"
                  className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  {name} Support File (Optional)
                </label>
                <input
                  id="dropzone-file"
                  type="file"
                  accept="image/*,video/*"
                  className="text-sm mt-4"
                  name="support_files"
                  onChange={(e) =>
                    setData({ ...data, course_support_file: e.target.value })
                  }
                  max={1}
                />
              </div> */}
						</div>
						<button
							type="submit"
							className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-transparent px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
						>
							{isLoading?.addCourse ? (
								<Loader w="6" h="6" color="blue" />
							) : (
								type === 'edit' ? 'save':'add'
							)}
						</button>
					</form>
				</div>
			</div>
		</>
	);
};
