import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface Policies {
	policies: any;
    policy: any;
	isLoading: any;
}

const initialState: Policies = {
	policies: {},
    policy: {},
	isLoading: {},
};

export const policiesSlice = createSlice({
	name: "policies",
	initialState,
	reducers: {
		setPolicies: (state: Policies, { payload }: PayloadAction<any>) => {
			state.policies = payload;
		},
        setPolicy: (state: Policies, { payload }: PayloadAction<any>) => {
			state.policy = payload;
		},
		setLoader: (state: Policies, { payload }: PayloadAction<any>) => {
			state.isLoading = { ...state.isLoading, ...payload };
		},
		reset: (state: Policies) => initialState,
	},
});

export const fetchPolicies = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ policies: true }));
	const json = await Api.getPolicies(data);
	dispatch(setLoader({ policies: false }));
	if (json !== undefined) {
		if (200 === json?.status) {
			dispatch(setPolicies(json?.data));
		}
	}
};

export const fetchPolicy = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ policy: true }));
	const json = await Api.getPolicies(data);
	dispatch(setLoader({ policy: false }));
	if (json !== undefined) {
		if (200 === json?.status) {
			dispatch(setPolicy(json?.data));
		}
	}
};

export const addUpdatePolicy =
	(data: any, navigate: any) => async (dispatch: any) => {
		dispatch(setLoader({ addUpdatePolicy: true }));
		const json = await Api.addUpdatePolicy(data);
		dispatch(setLoader({ addUpdatePolicy: false }));
		if (json?.status === 200 || json?.status === 201) {
			toastNotification({ status: "success", message: json?.data?.message });
			navigate("/bs/policies");
		}
		return json;
	};

export const {
	setPolicies,
    setPolicy,
	setLoader,
	reset: resetPolicies,
} = policiesSlice.actions;
export default policiesSlice.reducer;
