import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCourses } from "../../redux/courses.ts";
import { Pagination } from "../../components/pagination/index.js";
import { FilterBtn } from "../../components/filterBtn/index.js";
import { AddBtn } from "../../components/addBtn/index.js";
import { FilterField } from "../../components/filterField/index.js";
import { CoursesTable } from "../../containers/courses/coursesTable/index.js";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import { useParams } from "react-router-dom";
import { capitalize } from "../../helper/join.js";
import { removeEmptyString } from "../../helper/removeEmpty.js";

export const ViewCourses = () => {
	const dispatch = useDispatch();
	const { courses, isLoading } = useSelector((store) => store.course);
	const [filter, setFilter] = useState({ page: 1 });
	const {type} = useParams();

	useEffect(() => {
		let data_type = null;
		if (type === 'courses') {
			data_type = 'COURSE'
		} else if (type === 'products') {
			data_type = 'PRODUCT'
		} else if (type === 'events') {
			data_type = 'EVENT'
		}
		setFilter({data_type: data_type, page:1});
		dispatch(fetchAllCourses({ params: {page:1, data_type: data_type} }));
	}, [type]);

	function handlePage(page) {
		dispatch(fetchAllCourses({ params: removeEmptyString({ ...filter, page: page }) }));
	}

	const handleSearch = () => {
		Object.keys(filter)?.forEach((key) => {
			if (filter[key] === "") {
				delete filter[key];
			}
		});
		dispatch(fetchAllCourses({ page: 1, params: removeEmptyString(filter) }));
	};

	const handleClear = () => {
		setFilter({created: '', data_type: filter?.data_type, status: ''});
		dispatch(fetchAllCourses({ params: { page: 1, data_type: filter?.data_type } }));
	};

	return (
		<>
			<Breadcrumbs breadcrumbs={[{name: capitalize(type), link: ""}]} />
			<div className="relative mx-12">
				<div className="flex justify-start items-center space-x-10">
					<AddBtn to={`/offerings/${type}/action/add`} mt={5} text={`Add ${capitalize(type)?.slice(0, -1)}`} />
					<FilterField param={filter} setParam={setFilter} type={"title"} />
					<FilterField
						param={filter}
						setParam={setFilter}
						type={"created"}
						format={true}
					/>
					<div className="flex flex-col">
						<label className="font-light">Status</label>
						<select
							value={filter?.status || ''}
							onChange={(e) =>
								setFilter({ ...filter, status: (e.target.value) })
							}
							type="select"
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						>
							<option value={""}>Select</option>
							<option value={"publish"}>Publish</option>
							<option value={"draft"}>Draft</option>
						</select>
					</div>
					<div className="flex justify-center items-center space-x-5 mt-6">
						<FilterBtn func={handleSearch} />
						<FilterBtn func={handleClear} text={"Clear"} color={"red"} />
					</div>
				</div>
				<CoursesTable isLoading={isLoading} courses={courses} type={type} />

				<Pagination
					total={courses?.last_page}
					current={courses?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
