import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components/loader";
import { Pagination } from "../../../components/pagination";
import { fetchRequestCandidates } from "../../../redux/candidates.ts";
import { FilterBtn } from "../../../components/filterBtn/index.js";
import { FilterField } from "../../../components/filterField/index.js";
import { Unavailable } from "../../../components/noData/index.js";
import { convertDateToWords } from "../../../helper/dateFormat.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const CandidateRequests = () => {
	const dispatch = useDispatch();
	const [params, setParams] = useState({});
	const { requestCandidates, isLoading } = useSelector(
		(store) => store.candidate
	);
	const [id, setId] = useState();

	useEffect(() => {
		dispatch(fetchRequestCandidates({ params: params }));
	}, []);

	function handlePage(page) {
		dispatch(fetchRequestCandidates({ params: { ...params, page: page } }));
	}

	const handleClear = () => {
		setParams({});
		dispatch(fetchRequestCandidates({ params: {} }));
	};

	const handleSearch = () =>
		dispatch(fetchRequestCandidates({ params: params }));

	return (
		<>
			<Breadcrumbs breadcrumbs={[
				{name: 'Candidate Requests', link: ''},
			]} />
			<div className="relative mx-12">
				<div className="flex justify-start items-center space-x-10 my-2">
					<FilterField param={params} setParam={setParams} type={"date range"} />
					<div className="mt-7">
						<FilterBtn
							func={handleClear}
							disabled={!params?.startDate && !params?.endDate}
							text={"Clear"}
							color={"red"}
						/>
					</div>
					<div className="mt-7">
						<FilterBtn
							func={handleSearch}
							disabled={!(!!params?.startDate && !!params?.endDate)}
						/>
					</div>
				</div>
				<div className="h-[calc(100vh-270px)] overflow-auto hide-scroll">
					<table className="w-full text-sm text-left text-gray-500 border border-black">
					<thead className="text-sm text-orange-500 uppercase bg-gray-100 border border-black">
							<tr>
								<th scope="col" className="px-6 py-3 text-center">
									User Request
								</th>
								<th scope="col" className="px-6 py-3 text-center">
									Status
								</th>
								<th scope="col" className="px-6 py-3 text-center">
									Date
								</th>
							</tr>
						</thead>
						{isLoading?.requestCandidates ? (
							<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
								<Loader h="20" w="20" color={"orange"} />
							</div>
						) : (
							<tbody>
								<Unavailable data={requestCandidates} />
								{requestCandidates?.data?.map((req, index) => {
									return (
										<tr
											key={index}
											className="bg-white hover:bg-gray-50"
										>
											<td className="px-6 py-4 text-center">
												<span className="text-blue-600">
													{req?.user?.firstname} {req?.user?.lastname}
												</span>{" "}
												requested for the candidate
											</td>
											<td
												className={`px-6 py-4 text-center ${
													req?.status === 1 ? "text-green-500" : "text-orange-500"
												}`}
											>
												{req?.status === 1 ? "Accepted" : "Pending"}
											</td>
											<td className="px-6 py-4 text-center">
												{convertDateToWords(req?.created_at?.slice(0, 10))}
											</td>
										</tr>
									);
								})}
							</tbody>
						)}
					</table>
				</div>
				<Pagination
					total={requestCandidates?.last_page}
					current={requestCandidates?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
