import { useEffect, useRef, useState } from "react";
import { Breadcrumbs } from "../../components/breadcrumbs";
import DeleteModal from "../../components/deleteModal";
import { Table } from "../../components/table";
import { AddBtn } from "../../components/addBtn";
import { useDispatch, useSelector } from "react-redux";
import { fetchPolicies } from "../../redux/policiesSlice.ts";
import { Pagination } from "../../components/pagination/index.js";
import { useNavigate } from "react-router-dom";

export const BSPolicies = () => {
	const popupRef = useRef();
	const [id, setId] = useState(null);
	const [param, setParam] = useState({ page: 1 });
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { policies, isLoading } = useSelector((store) => store.policies);

	useEffect(() => {
		dispatch(fetchPolicies({ param }));
	}, [param]);

	const handlePage = (page) => {
		setParam({ ...param, page: page });
	};

	const handleDelete = () => {};

	const handleEdit = (id) => {
		navigate(`/bs/policies/edit/${id}`);
	};

	const tableData = [
		{ value: "title", img: "thumbnail", to: "/bs/policies/edit/{id}" },
		{ key: "Short Description", value: "short_desc" },
		{ key: "Updated Date", value: "updated_at", type: "date" },
	];

	const actions = [
		{ type: "edit", func: handleEdit, isLoading: false },
		{ type: "delete", func: handleDelete, isLoading: isLoading?.deleteCourse },
	];

	return (
		<>
			<Breadcrumbs breadcrumbs={[{ name: "Policies", link: "" }]} />
			<div className="relative px-12 w-full">
				<div className="flex justify-start items-center space-x-10 my-2">
					<AddBtn to={"/bs/policies/add"} mt={6} text={"Add"} />
				</div>
				<DeleteModal ref={popupRef} />

				<div className="h-[calc(100vh-270px)] overflow-auto mt-5 hide-scroll">
					<Table
						data={tableData}
						table={policies}
						actions={actions}
						id={id}
						isLoading={isLoading?.policies}
					/>{" "}
				</div>
				<Pagination
					total={policies?.last_page}
					current={policies?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
