import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteErrorLog, fetchErrorLogs } from "../../redux/others.ts";
import { Pagination } from "../../components/pagination/index.js";
import { isEmpty } from "../../helper/join.js";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import DeleteModal from "../../components/deleteModal/index.js";

export const ErrorLogs = () => {
	const dispatch = useDispatch();
	const { errorLogs, isLoading } = useSelector((store) => store.other);
	const [id, setId] = useState(null);
	const popupRef = useRef();

	useEffect(() => {
		if (isEmpty(errorLogs)) {
			dispatch(fetchErrorLogs(1));
		}
	}, []);

	function handlePage(page) {
		dispatch(fetchErrorLogs(page));
	}

	const handleDelete = (id) => {
		dispatch(deleteErrorLog(id));
		setId(id);
	};

	const tableData = [
		{ value: "subject", to: `` },
		{ key: "Content", value: "content" },
		{ key: "Created", value: "created_at", type: "date" },
	];

	const actions = [
		{
			type: "delete",
			func: handleDelete,
			isLoading: isLoading?.deleteErrorLog,
		},
	];

	const DeleteIcon = () => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth="1.5"
				stroke="currentColor"
				className="w-5 h-5"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
				/>
			</svg>
		);
	};

	return (
		<>
			<DeleteModal ref={popupRef} />
			<Breadcrumbs breadcrumbs={[{ name: "Error Logs", link: "" }]} />
			<div className="mx-10 relative">
				<div className="h-[calc(100vh-180px)] overflow-auto my-2 hide-scroll">
					{(errorLogs?.data || [])?.length > 0 &&
					<button
						onClick={() => {
							setId('all');
							popupRef?.current?.open({
                              id: null,
                              deleteFun: deleteErrorLog,
                              type: "All Error Logs",
                            })
						}}
						className={`bg-white ml-4 shrink-0 py-2  gap-x-2 text-sm px-3 mt-3 border-2 text-orange-600 rounded-full border-orange-600 flex justify-center hover:opacity-70 hover:scale-105`}
					>
						<DeleteIcon />
						Delete All
					</button>
					}
					<Table
						data={tableData}
						table={errorLogs}
						actions={actions}
						tabs_two={true}
						id={id}
						isLoading={isLoading?.errorLogs || (isLoading?.deleteErrorLog && id === 'all')}
					/>
				</div>
				<Pagination
					current={errorLogs?.current_page}
					func={handlePage}
					total={errorLogs?.last_page}
				/>
			</div>
		</>
	);
};
