import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTicketRead, fetchTickets } from "../../redux/tickets.ts";
import { Pagination } from "../../components/pagination/index.js";
import { isEmpty } from "../../helper/join.js";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";

export const AllTickets = () => {
	const dispatch = useDispatch();
	const { ticketList, isLoading } = useSelector((store) => store.ticket);
	const [id, setId] = useState(null);

	useEffect(() => {
		if (isEmpty(ticketList)) {
			dispatch(fetchTickets(1));
		}
	}, []);

	function handlePage(page) {
		dispatch(fetchTickets(page));
	}

	const handleRead = (id) => {
		setId(id);
		dispatch(fetchTicketRead(id))
	};

	const tableData = [
		{ value: "user_name", to: "" },
		{ key: "Ticket Type", value: "ticket_type" },
		{ key: "Email", value: "user_email" },
		{ key: "Description", value: "description" },
		{ key: "Reason", value: "reason" },
		{ key: "Date Created", value: "created_at", type: "date" },
	];

	const actions = [
	{ type: "read", func: handleRead, isLoading: isLoading?.ticketRead },
	{ type: "email", to: 'user_email', subject:'ticket_type' }];

	return (
		<>
			<Breadcrumbs breadcrumbs={[
					{name: 'Tickets', link: ''},
			]} />
			<div className="mx-10 relative">
				<div className="h-[calc(100vh-190px)] overflow-auto my-2 hide-scroll">
					<Table
						data={tableData}
						table={ticketList}
						actions={actions}
						id={id}
						isLoading={isLoading?.tickets}
					/>
				</div>
				<Pagination
					current={ticketList?.current_page}
					func={handlePage}
					total={ticketList?.last_page}
				/>
			</div>
		</>
	);
};
