import { useSelector } from "react-redux";
import { Loader } from "../../../components/loader/index.js";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchCourseDetail } from "../../../redux/courses.ts";

export const CourseDetails = ({ title }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCourseDetail(title));
  }, []);

  const { courseDetail, isLoading } = useSelector((store) => store.course);
  const data = [
    { detail: "Viewed by ", status: courseDetail?.minimum_payout },
    { detail: "Support files", status: courseDetail?.course_support_files?.length },
    { detail: "Enrolled", status: courseDetail?.students?.length },
    { detail: "Purchased by", status: courseDetail?.course_count_payment?.length },
    { detail: "Reviews", status: courseDetail?.reviews?.length },
    { detail: "Uploaded Trailers", status: courseDetail?.trailers?.length },
    { detail: "Questions", status: courseDetail?.questions?.length },
    { detail: "Interested users", status: courseDetail?.course_interests?.length },
    { detail: "Status", status: courseDetail?.course_status },
  ];

  if (isLoading?.courseDetail) {
    return (
      <div className="flex justify-center">
        <Loader w="20" h="20" color="orange" />
      </div>
    );
  }

  return (
    <div className="h-[calc(100vh-200px)] overflow-auto">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              Detail
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((dat, ind) => {
            return (
              <tr
                key={ind}
                className="bg-white border-b"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  {dat?.detail}
                </th>
                <td className="px-6 py-4">{dat?.status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
