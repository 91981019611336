import { Combobox, ComboboxButton, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { fetchCompanyDetail } from "../../../../redux/jobsSlice.ts";
import { IoChevronDown } from "react-icons/io5";

export const CompanyTitle = ({
	companies,
	selectedCompany,
	handleSelectedCompany,
	is_original
}) => {
	const [valQuery, setValQuery] = useState("");
	const dispatch = useDispatch();

	useEffect(() => {
		if (selectedCompany?.id) {
			dispatch(fetchCompanyDetail(selectedCompany?.id));
		}
	}, [selectedCompany?.id]);

	const displayCompanies = ([...(companies || [])])
	?.filter((comp) =>
		(comp?.title || '')?.toLowerCase()?.includes(valQuery?.toLowerCase())
	)
	?.filter((c) => is_original ? c?.is_original : !c?.is_original)
	.sort((a, b) => {
		const query = valQuery.toLowerCase() || '';
		const aTitle = a?.title?.toLowerCase() || '';
		const bTitle = b?.title?.toLowerCase() || '';
		const aStartsWithQuery = aTitle.startsWith(query);
		const bStartsWithQuery = bTitle.startsWith(query);
	
		if (aStartsWithQuery && !bStartsWithQuery) {
		  return -1;
		}
		if (!aStartsWithQuery && bStartsWithQuery) {
		  return 1;
		}
		return aTitle.localeCompare(bTitle);
	  });
		
	return (
		<Combobox value={selectedCompany} onChange={handleSelectedCompany}>
			<div className="relative w-1/3">
				<div className="relative w-full cursor-default overflow-hidden text-left focus:outline-none focus-visible:ring-2 sm:text-sm">
					<Combobox.Input
						className="text-gray-900 border-b-2 border-gray-300 sm:text-sm focus:ring-orange-600 focus:border-orange-600 placeholder:text-gray-500 block w-full p-2.5 px-0 outline-none"
						displayValue={(value) => value?.title}
						onChange={(event) => setValQuery(event.target.value)}
						placeholder="Select Company"
						autoComplete="off"
					/>
					<ComboboxButton className="group absolute inset-y-0 right-0">
              			<IoChevronDown className="size-4 focus:fill-orange-600 fill-white/60 group-data-[hover]:fill-orange-600" />
            		</ComboboxButton>
				</div>
				<Transition
					as={Fragment}
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
					afterLeave={() => setValQuery("")}
				>
					<Combobox.Options className="absolute z-[30] mt-1 max-h-40 w-full overflow-auto ounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
						{(displayCompanies || []).length === 0 && valQuery !== "" ? (
							<div className="relative cursor-default select-none px-4 py-2 text-gray-700">
								No Company Found.
							</div>
						) : (
							displayCompanies?.map((val) => (
									<Combobox.Option
										key={val?.id}
										className={({ active }) =>
											`relative cursor-default select-none py-2 pl-10 pr-4 ${
												active ? "bg-orange-600 text-white" : "text-gray-900"
											}`
										}
										value={val}
									>
										{({ selected, active }) => (
											<>
												<span
													className={`block truncate ${
														selected ? "font-medium" : "font-normal"
													}`}
												>
													{val?.title}
												</span>
												{selected ? (
													<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
														<FaCheckCircle
															className="h-5 w-5"
															aria-hidden="true"
														/>
													</span>
												) : null}
											</>
										)}
									</Combobox.Option>
								))
						)}
					</Combobox.Options>
				</Transition>
			</div>
		</Combobox>
	);
};
