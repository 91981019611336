import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPaymentList,
  fetchCompleteResponse,
} from "../../../redux/vouchers.ts";
import { Pagination } from "../../../components/pagination/index.js";
import { FilterBtn } from "../../../components/filterBtn/index.js";
import { FilterField } from "../../../components/filterField/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { Table } from "../../../components/table/index.jsx";

export const Processing = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useState({ status: "processing" });
  const { paymentList, isLoading } = useSelector((store) => store.voucher);
  const [list, setList] = useState([]);

  useEffect(() => {
    dispatch(fetchPaymentList({ params: params }));
  }, []);

  function handlePage(page) {
    dispatch(fetchPaymentList({ params: { ...params, page: page } }));
  }

  const handleClearDate = () => {
    let param = { ...params, endDate: "", startDate: "" };
    setParams(param);
    dispatch(fetchPaymentList({ params: param }));
  };

  const handleProcessCheck = (e, id) => {
    if (e.target.checked) {
      setList([...list, id]);
    } else {
      setList(
        list.filter((l) => {
          return l !== id;
        })
      );
    }
  }

  const tableData = [
		{type: "fullName", to: ``},
		{key: "Requested Amount", value: "pending_amount"},
		{key: "Paypal Email", value: "paypal_email"},
		{key: "Created", value: "created_at", type:"date"},
	];

	const actions = [
		{type: 'processCheck', func: handleProcessCheck, isLoading: false, text:'Mark as Complete'}
	];

  const handleSearch = () => dispatch(fetchPaymentList({ params: params }));

  return (
    <>
      <Breadcrumbs breadcrumbs={[{name: "Vouchers under processing", link: ""}]} />
      <div className="mx-12">
        <div className="flex justify-start items-center space-x-10 my-2">
          <FilterField
            param={params}
            setParam={setParams}
            type={"date range"}
          />
          <div className="mt-7">
            <FilterBtn
              func={handleClearDate}
              disabled={!params?.startDate && !params?.endDate}
              text={"Clear Date"}
              color={"red"}
            />
          </div>
          <div className="mt-7">
            <FilterBtn 
              func={handleSearch}
              disabled={!params?.startDate || !params?.endDate}
            />
          </div>
          <div className="mt-7">
            <button
              disabled={list?.length === 0}
              onClick={() => {
                dispatch(fetchCompleteResponse(list));
                setList([]);
              }}
              className="bg-white px-3 py-1 border-2 border-blue-600 rounded-full text-blue-600 hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out flex justify-center items-center"
            >
              <div>Mark as Complete</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 12.75 6 6 9-13.5"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="h-[calc(100vh-270px)] overflow-auto hide-scroll">
          <Table data={tableData} table={paymentList} actions={actions} list={list} isLoading={isLoading?.paymentList} />
        </div>
        <Pagination
          total={paymentList?.last_page}
          current={paymentList?.current_page}
          func={handlePage}
        />
      </div>
    </>
  );
};
