import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Loader } from '../loader';
import { Upload } from '../upload';

export default function AddFormModel(props) {
    const { isOpen, closeModal, handleAdd, data, setData, isLoading, name, title, image, handleFileChange, imagePreview } = props;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                <form
                                    onSubmit={handleAdd}
                                    className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:my-8 sm:w-full sm:max-w-lg"
                                >
                                    {name &&
                                    <>
                                    <div className="bg-white px-4 py-2">
                                        <div className="flex flex-col justify-start items-start">
                                            <div className="w-full">
                                                <label className="block mb-2 text-sm font-medium text-gray-900">
                                                    Name
                                                </label>
                                                <input
                                                    value={data?.name}
                                                    onChange={(e) =>
                                                        setData({ ...data, name: e.target.value })
                                                    }
                                                    type="text"
                                                    className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5 outline-none"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    </>}
                                    {title &&
                                    <>
                                    <div className="bg-white px-4 py-2">
                                        <div className="flex flex-col justify-start items-start">
                                            <div className="w-full">
                                                <label className="block mb-2 text-sm font-medium text-gray-900">
                                                    Title
                                                </label>
                                                <input
                                                    value={data?.title}
                                                    onChange={(e) =>
                                                        setData({ ...data, title: e.target.value })
                                                    }
                                                    type="text"
                                                    className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5 outline-none"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    </>}
                                    {image &&
                                        <div className="bg-white px-4 py-2">
                                            <div className="flex flex-col justify-start items-start">
                                                <label className="block mb-2 text-sm font-medium text-gray-900">
                                                    Image
                                                </label>
                                                <Upload id={"add-form"}>
                                                    <input
                                                        id="add-form"
                                                        type="file"
                                                        hidden="true"
                                                        accept=".jpeg, .jpg, .png, .gif, image/jpeg, image/jpg, image/png, image/gif, video/*"
                                                        name="support_files"
                                                        onChange={handleFileChange}
                                                        max={1}
                                                    />
                                                </Upload>
                                            </div>
                                        </div>}
                                    <div className="px-6 py-2">
                                        {data?.image && imagePreview ? (
                                            <img
                                                src={imagePreview}
                                                alt="Uploaded Preview"
                                                className="mb-4 w-24 h-24 rounded"
                                            />
                                        ) : data?.old_image &&
                                            <img
                                                src={`${process.env.REACT_APP_SERVER_URL}/${data?.old_image}`}
                                                alt="no selected"
                                                className="mb-4 w-24 h-24 rounded"
                                            />
                                        }
                                    </div>
                                    <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="submit"
                                            className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                                        >
                                            {isLoading ? (
                                                <Loader w="5" h="5" color="orange" />
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                        <button
                                            onClick={closeModal}
                                            type="button"
                                            className="inline-flex w-full justify-center border-orange-600 border rounded-md bg-white px-3 py-2 text-sm font-semibold text-orange-600 shadow hover:opacity-90 sm:ml-3 sm:w-auto"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
