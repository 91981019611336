import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteEmail, fetchEmails } from "../../../redux/vouchers.ts";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/deleteModal/index.js";
import { Pagination } from "../../../components/pagination/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import { Table } from "../../../components/table/index.jsx";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { debounce } from "lodash";
import { FilterBtn } from "../../../components/filterBtn/index.js";

export const GenerateEmail = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useState({});
  const navigate = useNavigate();
  const { emails, isLoading } = useSelector((store) => store.voucher);
  const popupRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
      dispatch(fetchEmails({ params: params }));
  }, [params]);

  function handlePage (page) {
    setParams({...params, page: page})
  }

  const handleView = (id) => {
    navigate(`/payment-request/email/${id}`)
  }

  const handleDelete = (id) =>
  popupRef?.current?.open({
    id: id,
    deleteFun: deleteEmail,
    type: "E-mail",
  });

  const setSubject = debounce((value) => {
		setParams({ ...params, subject: value });
	}, 1500);

  const tableData = [
		{value: "subject", to: `/payment-request/email/{id}`},
    {key: "Created By", type: "fullName"},
    {key: "Status", value: "is_draft", type:'draft'},
		{key: "Total Emails Sent", value: "email_count"},
		{key: "Created", value: "created_at"},
	];

	const actions = [
		{type: 'view', func: handleView, isLoading: false},
		{type: 'delete', func: handleDelete, isLoading: false}
	];

  const clearInput = () => {
    if (inputRef.current) {
      setParams({ ...params, subject: '' });
      inputRef.current.value = '';
    }
  };

  return (
    <>
      <Breadcrumbs breadcrumbs={[{name: "Emails", link: ""}]} />
      <div className="relative mx-12">
        <DeleteModal ref={popupRef} />
        <div className="flex justify-start items-center space-x-10 my-2">
          <AddBtn 
            to={"/payment-request/all-emails/generate"}
            mt={6}
            text={"Generate"}
          />
          <div className="flex flex-col">
						<label className="font-light">Email Subject</label>
            <div className="flex justify-center items-center gap-x-4">
						<input
              ref={inputRef}
							onChange={(e) => {
								const inputValue = e.target.value;
								setSubject.cancel();
								setSubject(inputValue);
							}}
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						/>
            <FilterBtn text={"clear"} color={'red'} func={() => clearInput()} />
            </div>
					</div>
        </div>
        <div className="h-[calc(100vh-270px)] overflow-auto hide-scroll">
          <Table data={tableData} table={emails} actions={actions} isLoading={isLoading?.emails} />
        </div>
        <Pagination
          total={emails?.last_page}
          current={emails?.current_page}
          func={handlePage}
        />
      </div>
    </>
  );
};
