import { Link } from "react-router-dom";

export const AddBtn = ({ text, to, func, mt }) => {
	return (
		<>
			{to ? (
				<Link
					to={to}
					className={`shrink-0 py-2 transition-all duration-200 ease-in-out text-sm px-3 mt-${mt} text-white bg-orange-600 rounded-full flex justify-center hover:opacity-70 hover:scale-[1.02]`}
				>
					{text?.toLowerCase()?.includes("edit") ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="currentColor"
							className="mr-1 w-5 h-5"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
							/>
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="currentColor"
							className="mr-1 w-6 h-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
							/>
						</svg>
					)}
					<span className="mt-0.5">{text}</span>
				</Link>
			) : (
				<button
					type="button"
					onClick={() => func()}
					className={`shrink-0 py-2 h-10 transition-all duration-200 ease-in-out text-sm px-3 mt-${mt} text-white bg-orange-600 rounded-full flex justify-center hover:opacity-70 hover:scale-[1.02]`}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth="1.5"
						stroke="currentColor"
						className="mr-1 w-6 h-6"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
						/>
					</svg>
					<span className="mt-0.5">{text}</span>
				</button>
			)}
		</>
	);
};
