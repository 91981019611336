import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchPages, deletePage, addPage } from "../../../redux/faqsSlice.ts";
import { Pagination } from "../../../components/pagination/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import AddFormModel from "../../../components/addFormModal/index.js";
import DeleteModal from "../../../components/deleteModal/index.js";
import { Table } from "../../../components/table/index.jsx";

export const Pages = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pages, isLoading } = useSelector((store) => store.faqs);
	const [id, setId] = useState("");
	const [params, setParams] = useState({ page: 1 });
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState();
	const deleteRef = useRef(null);

	function closeModal() {
		setIsOpen(false);
		setData({});
	}

	function openModal() {
		setIsOpen(true);
	}

	useEffect(() => {
		dispatch(fetchPages({ params: params }));
	}, []);

	function handlePage(page) {
		dispatch(fetchPages({ params: { ...params, page: page } }));
	}

	const handleSearch = () => {
		dispatch(fetchPages({ page: 1, params: params }));
	};

	const handleClear = () => {
		setParams({});
		dispatch(fetchPages({ params: { page: 1 } }));
	};

	const handleAdd = (e) => {
		e.preventDefault();
		const formData = new FormData();
		if (data?.title !== "") {
			Object.keys(data).forEach((key) => {
				formData.append(key, data[key]);
			});

			dispatch(addPage({ formData: { formData } }, closeModal));
		}
	};

	const handleView = (id) => navigate(`/contents/faqs/pages/${id}`);

	const handleDelete = (id) => {
		setId(id);
		deleteRef?.current?.open({
			id: id,
			deleteFun: deletePage,
			type: "Faq Page",
		});
	};

	const tableData = [
		{ value: "title", to: "/contents/faqs/pages/{id}" },
		{ key: "Created", value: "created_at" },
	];

	const actions = [
		{ type: "view", func: handleView, isLoading: false },
		{ type: "delete", func: handleDelete, isLoading: isLoading?.deletePage },
	];

	return (
		<>
			<div className="relative">
				<DeleteModal ref={deleteRef} />
				<div className="flex justify-between items-center space-x-10">
					<AddBtn func={openModal} mt={5} text={"Add Page"} />
				</div>
				<div className={`h-[calc(100vh-320px)] overflow-auto mt-5 hide-scroll`}>
					<Table
						data={tableData}
						table={pages}
						actions={actions}
						id={id}
						isLoading={isLoading?.fetchPages}
					/>
				</div>
				<Pagination
					total={pages?.last_page}
					current={pages?.current_page}
					func={handlePage}
				/>
			</div>
			<AddFormModel
				isOpen={isOpen}
				closeModal={closeModal}
				handleAdd={handleAdd}
				data={data}
				setData={setData}
				isLoading={isLoading?.addPage}
				title={true}
			/>
		</>
	);
};
