import { useSelector } from "react-redux";
import { Loader } from "../../../components/loader";
import { Unavailable } from "../../../components/noData";

export const TableReport = () => {
    const { reports, isLoading } = useSelector((store) => store.other);

	return (
		<div className="h-[calc(100vh-350px)] overflow-auto hide-scroll">
        	<table className="w-full text-sm text-left text-gray-500 border border-black">
				<thead className="text-sm text-orange-500 uppercase bg-gray-100 border border-black">
					<tr>
						<th scope="col" className="px-6 py-3 text-center">
							ID
						</th>
						<th scope="col" className="px-6 py-3 text-center">
							Name
						</th>
						<th scope="col" className="px-6 py-3 text-center">
							Email
						</th>
						<th scope="col" className="px-6 py-3 text-center">
							Company Name
						</th>
						<th scope="col" className="px-6 py-3 text-center">
							Title
						</th>
						<th scope="col" className="px-6 py-3 text-center">
							State/Country
						</th>
					</tr>
				</thead>
				{isLoading?.reports ? (
					<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
						<Loader w="20" h="20" color={"orange"} />
					</div>
				) : (
					<tbody>
						<Unavailable data={reports} />
						{reports?.data?.map((report, index) => {
							return (
								<tr key={index} className="bg-white hover:bg-gray-50">
									<th
										scope="row"
										className="px-6 py-4 font-medium bg-gray-100 border-r border-black text-gray-900 whitespace-nowrap text-center"
									>
										{report?.id}
									</th>
									<td className="px-6 py-4 text-center font-[700]">
										{report?.firstname + " " + report?.lastname}
									</td>
									<td className="px-6 py-4 text-center">{report?.email}</td>
									<td className="px-6 py-4 text-center">
										{report?.organization}
									</td>
									<td className="px-6 py-4 text-center">{report?.worktitle}</td>
									<td className="px-6 py-4 text-center">
										{report?.state + "/" + report?.country}
									</td>
								</tr>
							);
						})}
					</tbody>
				)}
			</table>
		</div>
	);
};
