export const removeEmptyString = (obj) => {
    let keysToKeep = Object.keys(obj || {})?.filter((key) => {
        return obj?.[key] !== '' && obj?.[key] !== null;
    });

    let newObj = {};
    (keysToKeep || [])?.map((key) => {
        newObj[key] = obj[key]
    })
    return newObj;
}