import { useImperativeHandle, forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom"

const TermModal = (props, ref) => {
    const [show, setShow] = useState(false);
    const [sign, setSign] = useState();
    const navigate = useNavigate();
    const {userInfo} = useSelector(store => store.login)

    useImperativeHandle(ref, () => ({
        open(data) {
            setShow(true);
            setSign(data);
        }
    }))

    return (
        <>
        {show && <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="flex flex-col justify-center items-center">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                        </div>
                        <div className="mt-3 text-center">
                            <h3 className="text-base text-center font-semibold leading-6 text-gray-900" id="modal-title">Signed Agreement</h3>
                            <div className="my-3">
                                <div className="text-gray-700">Admin User with the email address <span className="text-green-900">{userInfo?.email}</span> agreed to terms and conditions</div>
                            </div>
                            <img className="bg-black rounded-lg" src={sign} alt="sign" />
                        </div>
                    </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button 
                    onClick={() => {
                        setShow(false)
                        navigate('/courses/view-all')
                    }}
                    type="button" 
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Enter</button>
                    <button 
                    onClick={() => setShow(false)}
                    type="button" 
                    className="inline-flex w-full justify-center border-black rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200 sm:ml-3 sm:w-auto">Back</button>
                    </div>
                </div>
                </div>
            </div>
        </div>}
        </>
    )
}

export default forwardRef(TermModal);