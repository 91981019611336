import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginInfo } from "../../redux/loginSlice.ts";
import { Loader } from "../../components/loader/index.js";
import { useEffect } from "react";

export const SignInPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isLoading, token, error } = useSelector((store) => store.login);

	const currentDate = new Date();

	useEffect(() => {
		if (token) {
			navigate('/dashboard');
		}
	}, [])
	

	return (
		<Formik
			initialValues={{
				email: "",
				password: "",
			}}
			validate={(values) => {
				const errors = {};
				if (!values.email) {
					errors.email = "Email is required!";
				} else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				) {
					errors.email = "Invalid Email Address!";
				}

				if (!values.password) {
					errors.password = "Password is required!";
				}
				return errors;
			}}
			onSubmit={(values) => {
				// navigate('/policy');
				dispatch(
					fetchLoginInfo(
						{
							adminEmail: values.email,
							adminPassword: values.password,
						},
						navigate
					)
				);
			}}
		>
			{(props) => {
				return (
					<section className="bg-gray-200 h-screen -mt-16 flex justify-center">
						<div className="flex flex-col items-center justify-center">
							<div className="w-[400px] bg-white rounded-lg shadow-2xl">
								<div className="p-8 py-12">
									<h1 className="text-2xl tracking-relaxed text-left font-semibold  text-orange-600">
										Lets's get start
									</h1>
                                    <h2 className="text-lg tracking-relaxed text-left font-light text-orange-600">
										Welcome to PL's Admin Panel
									</h2>
									<Form
										className="space-y-4 md:space-y-6 mt-8"
										onSubmit={props.handleSubmit}
									>
										<div>
											<label
												htmlFor="email"
												className="block mb-1 text-lg font-light text-gray-900"
											>
												Your email
											</label>
											<input
												type="email"
												name="email"
												id="email"
												className="bg-transparent appearance-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
												placeholder="name@company.com"
												value={props.values.email}
												onBlur={props.handleBlur}
												onChange={props.handleChange}
											/>
											{props?.errors?.email && (
												<p className="text-[9px] text-red-500  h-[16px]">
													{props?.touched?.email && props?.errors?.email}
												</p>
											)}
										</div>
										<div>
											<label
												htmlFor="password"
												className="block mb-1 text-lg font-light text-gray-900"
											>
												Password
											</label>
											<input
												type="password"
												name="password"
												id="password"
												placeholder="••••••••"
												className="bg-transparent appearance-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
												value={props.values.password}
												onBlur={props.handleBlur}
												onChange={props.handleChange}
											/>
											{props?.errors?.password && (
												<p className="text-[9px] text-red-500  h-[16px]">
													{props?.touched?.password && props?.errors?.password}
												</p>
											)}
										</div>
										{error && (
											<p className="text-[12px] flex justify-center text-red-600 h-[16px]">
												Incorrect Email or Password
											</p>
										)}
										<button
											disabled={isLoading}
											type="submit"
											onSubmit={props.handleSubmit}
											className="w-full text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center flex justify-center items-center gap-x-1"
										>
											{isLoading ? (
												<Loader w="6" h="6" color="orange" />
											) : (
												"Login"
											)}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												fill="currentColor"
												className={`w-4 h-4 ${isLoading && 'hidden'}`}
											>
												<path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
											</svg>
										</button>
									</Form>
									<p className="text-center text-sm mt-8">
										{currentDate.getFullYear()} ©{" "}
										<a
											className="text-orange-500"
											href="https://web.procurementleague.com/"
											target="_blank"
											rel="noopener noreferrer"
										>
											Procurement League
										</a>
									</p>
								</div>
							</div>
						</div>
					</section>
				);
			}}
		</Formik>
	);
};
