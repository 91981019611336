export const Unavailable = ({ data }) => {
	return (
		<>
			{data?.data?.length === 0 && (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black opacity-35 text-lg">
					Nothing to show at the moment !
				</div>
			)}
		</>
	);
};
