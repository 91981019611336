import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "../../../components/modal";

const View = (props, ref) => {
	const [show, setShow] = useState(false);
	const [data, setData] = useState({});

	useImperativeHandle(ref, () => ({
		open(data) {
			setShow(true);
			setData(data);
		},
	}));

	return (
		<Modal size={"lg"} show={show} setShow={setShow}>
			<div className="flex justify-start flex-col items-start gap-y-2">
            <h1 className="text-lg font-semibold">General Info:</h1>

            <h1 className="text-lg font-light">
					Name: {data?.fullname}
				</h1>
				<h1 className="text-lg font-light">
					Email: {data?.email}
				</h1>
				<h1 className="text-lg font-light">
					Company: {data?.company_name}
				</h1>
				<h1 className="text-lg font-semibold">Query:</h1>
				<div className="text-lg font-light">
					{data?.query || "No info provided!"}
				</div>
			</div>
		</Modal>
	);
};

export default forwardRef(View);
