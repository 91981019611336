export const dateFormat = (Str) => Str?.split("-")?.join('/');
export const formatDate = (Str) => {
    if (typeof Str === "string") {
        return Str?.split("/")?.join('-');
    }
    return null;
}

export const yearLastFormat = (Str) => {
    let yearFirst = Str.split("-").join('/');

    return yearFirst?.slice(5, ) + '/' + yearFirst?.slice(0, 4)
}

export const convertDateToWords = (dateString) => {
    if (!dateString) {
        return null;
    }
    const months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'August', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dateParts = dateString.split('-');
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[2]);

    const monthWord = months[month - 1];

    let dayWord = day;

    return `${monthWord} ${dayWord}, ${year}`;
}

export const dateWord = (inputDate) => {
    if (!inputDate) {
        return '';
    }
    const [month, year] = inputDate.split('-');
    const date = new Date(`${month}-01-${year}`);
    const monthName = date.toLocaleString('default', { month: 'short' });
    return `${monthName} ${year}`;
}