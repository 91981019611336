import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface Others {
  contactUs: {};
  newsletters: any;
  newsCategories: [];
  errorLogs: any;
  reportAbuseList: any;
  reports: any;
  countries: any
  isLoading: any;
}

const initialState: Others = {
  contactUs: {},
  newsletters: {},
  newsCategories: [],
  errorLogs: {},
  reportAbuseList: {},
  reports: {},
  countries: {},
  isLoading: {},
};

export const othersSlice = createSlice({
  name: "other",
  initialState,
  reducers: {
    setContactUs: (state: Others, { payload }: PayloadAction<any>) => {
      state.contactUs = payload?.ContactUs;
    },
    setNewsletters: (state: Others, { payload }: PayloadAction<any>) => {
      state.newsletters = payload;
    },
    setNewsCategories: (state: Others, { payload }: PayloadAction<any>) => {
      state.newsCategories = payload;
    },
    handleAddNewsletter: (state: Others, { payload }: PayloadAction<any>) => {
      let update = false;
      state.newsletters = {
        ...state.newsletters,
        data: state.newsletters?.data?.map((news) => {
          if (news?.id === payload?.data?.id) {
            update = true;
          }
          return news?.id !== payload?.data?.id ? news : payload?.data;
        }),
      };
      if (!update) {
        state.newsletters = {
          ...state.newsletters,
          data: [...state.newsletters?.data, payload?.data],
        };
      }
    },
    handleDeleteNewsletter: (
      state: Others,
      { payload }: PayloadAction<any>
    ) => {
      state.newsletters = {
        ...state.newsletters,
        data: state.newsletters.data.filter((newsletter) => {
          return newsletter?.id !== payload;
        }),
      };
    },
    setReportAbuse: (state: Others, { payload }: PayloadAction<any>) => {
      state.reportAbuseList = payload?.reports;
    },
    setReports: (state: Others, {payload}: PayloadAction<any>) => {
      state.reports = payload?.data;
    },
    setCountries: (state: Others, {payload}: PayloadAction<any>) => {
      state.countries = {...state.countries ,[payload.type]: payload?.data};
    },
    setErrorLogs: (state: Others, {payload}: PayloadAction<any>) => {
      state.errorLogs = payload;
    },
    setDeleteErrorLog: (state: Others, {payload}: PayloadAction<any>) => {
      state.errorLogs = {...state.errorLogs, data: state.errorLogs.data.filter((log) => {
        return log?.id !== payload;
      })}
    },
    setLoader: (state: Others, { payload }: PayloadAction<any>) => {
      state.isLoading = { ...state.isLoading, ...payload };
    },
    reset: (state: Others) => initialState,
  },
});

export const fetchContactUs = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({actions: true}))
  const json = await Api.contactUs(data);
  dispatch(setLoader({actions: false}))
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setContactUs(json?.data));
    } else {
      dispatch(setContactUs(null));
    }
  }
};

export const fetchNewsletters = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({newsletters: true}))
  const json = await Api.newsletters(data);
  dispatch(setLoader({newsletters: false}))
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setNewsletters(json?.data?.data));
    } else {
      dispatch(setNewsletters(null));
    }
  }
};

export const fetchNewsCategories = () => async (dispatch: any) => {
  const json = await Api.newslettersCategories();
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setNewsCategories(json?.data?.data));
    }
  }
};

export const addNewsLetter = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ addNewsLetter: true }));
  const json = await Api.addNewsletter(data);
  dispatch(setLoader({ addNewsLetter: false }));
  if (json !== undefined) {
    if (201 === json?.status) {
      dispatch(handleAddNewsletter(json?.data));
      toastNotification({status: "success", message: "Success!"})
      data.setShow(false);
    } else if (422 === json?.response?.status) {
      toastNotification({status: "warning", message: "All Fields are required!"})
    }
  }
};

export const deleteNewsletter = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ deleteNewsLetter: true }));
  const json = await Api.deleteNewsletter(data);
  dispatch(setLoader({ deleteNewsLetter: false }));
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(handleDeleteNewsletter(data));
      toastNotification({status: "success", message: "Newsletter Deleted!"})
    }
  }
};

export const fetchReportAbuse = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ reportAbuse: true }));
  const json = await Api.reportAbuse(data);
  dispatch(setLoader({ reportAbuse: false }));
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setReportAbuse(json?.data));
    } else {
      dispatch(setReportAbuse(null))
    }
  }
};

export const fetchReports = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ reports: true }));
  const json = await Api.reports(data);
  dispatch(setLoader({ reports: false }));
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setReports(json?.data));
    } else {
      dispatch(setReports(null))
    }
  }
};

export const fetchCountries = (data: any) => async (dispatch: any) => {
  const json = await Api.countries(data);
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setCountries({data: json?.data, type: data?.params?.type}));
    }
  }
};

export const fetchErrorLogs = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({errorLogs: true}));
  const json = await Api.errorLogs(data);
  dispatch(setLoader({errorLogs: false}));
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setErrorLogs(json?.data?.data));
    }
  }
};

export const deleteErrorLog = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({deleteErrorLog: true}));
  const json = await Api.deleteErrorLog(data);
  dispatch(setLoader({deleteErrorLog: false}));
  if (json !== undefined) {
    if (200 === json?.status) {
      if (data) {
        dispatch(setDeleteErrorLog(data));
      } else {
        dispatch(setErrorLogs({}));
      }
      toastNotification({status: "success", message: json?.data?.message || "Error Log Deleted!"});
    }
  }
};

export const {
  setContactUs,
  setNewsletters,
  setNewsCategories,
  handleAddNewsletter,
  handleDeleteNewsletter,
  setReportAbuse,
  setReports,
  setCountries,
  setErrorLogs,
  setDeleteErrorLog,
  setLoader,
  reset: resetOthers,
} = othersSlice.actions;
export default othersSlice.reducer;
