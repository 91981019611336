import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface Tickets {
  ticketList: any;
  isLoading: any;
}

const initialState: Tickets = {
  ticketList: {},
  isLoading: {}
};

export const ticketsSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    setTickets: (state: Tickets, { payload }: PayloadAction<any>) => {
      state.ticketList = payload?.tickets;
      state.isLoading = {...state.isLoading, tickets: false}
    },
    setTicketRead: (state: Tickets, { payload }: PayloadAction<any>) => {
      state.ticketList = {
        ...state.ticketList,
        data: state.ticketList.data.filter((tic) => {
          return tic?.id !== payload;
        }),
      };
    },
    setLoader: (state: Tickets, {payload}: PayloadAction<any>) => {
      state.isLoading = {...state.isLoading, ...payload};
    },
    reset: (state: Tickets) => initialState,
  },
});

export const fetchTickets = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({tickets: true}));
  const json = await Api.tickets(data);
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setTickets(json?.data));
    } else {
      dispatch(setTickets(null))
    }
  }
};

export const fetchTicketRead = (data: any) => async (dispatch: any) => {
  dispatch(setLoader({ticketRead: true}));
  const json = await Api.ticketStatus(data);
  dispatch(setLoader({ticketRead: false}));
  if (json !== undefined) {
    if (200 === json?.status) {
      dispatch(setTicketRead(data));
      toastNotification({status: "success", message: "You have read this ticket!"})
    }
  }
};

export const {
  setTickets,
  setTicketRead,
  setLoader,
  reset: resetTickets,
} = ticketsSlice.actions;
export default ticketsSlice.reducer;
