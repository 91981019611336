import { configureStore, combineReducers } from "@reduxjs/toolkit";
import loginReducer from "../redux/loginSlice.ts";
import termReducer from "../redux/TermSlice.ts";
import courseReducer from "../redux/courses.ts";
import teamReducer from "../redux/teamSlice.ts";
import casestudiesReducer from "../redux/caseStudiesSlice.ts";
import voucherReducer from "../redux/vouchers.ts";
import blogsReducer from "../redux/blogsSlice.ts";
import faqsReducer from "../redux/faqsSlice.ts";
import jobsReducer from "../redux/jobsSlice.ts";
import ticketReducer from "../redux/tickets.ts";
import othersReducer from "../redux/others.ts";
import usersReducer from "../redux/users.ts";
import candidateReducer from "../redux/candidates.ts";
import buyerSlice from "../redux/buyerSlice.ts";
import influencersSlice from "../redux/influencersSlice.ts";
import influencerProfileSlice from "../redux/influencerProfileSlice.ts";
import policiesReducer from "../redux/policiesSlice.ts";
import trainerReducer from "../redux/trainerSlice.ts";
import hierarchyReducer from "../redux/hierarchySlice.ts";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import systemSettingsReducer from "../redux/systemSettingsSlice.ts";

const rootReducer = combineReducers({
	login: loginReducer,
	term: termReducer,
	course: courseReducer,
	team: teamReducer,
	caseStudies: casestudiesReducer,
	blogs: blogsReducer,
	faqs: faqsReducer,
	jobs: jobsReducer,
	voucher: voucherReducer,
	ticket: ticketReducer,
	other: othersReducer,
	user: usersReducer,
	candidate: candidateReducer,
	buyer: buyerSlice,
	influencer: influencersSlice,
	policies: policiesReducer,
	trainer: trainerReducer,
	hierarchy: hierarchyReducer,
	influencerprofile: influencerProfileSlice,
	systemsettings: systemSettingsReducer,
});

const rootPersistConfig = {
	key: "root",
	storage,
	whitelist: ["login"],
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
});

export const persistor = persistStore(store);
