import { TermAside } from "../../components/termsAside/index.js";
import { Content } from "./content.js";
import { termData } from "../../jsonData/termCondition.js";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setTab } from "../../redux/TermSlice.ts";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import { joinString } from "../../helper/join.js";
import emitter from "../../services/emitter-service.js";

export const TermCondition = () => {
  const items = 6;
  const { tab } = useSelector((store) => store.term);
  const [item, setItem] = useState(0);
  const [active, setActive] = useState(true);
  const [sig, setSig] = useState();
  const [agree, setAgree] = useState(false);
  const [currentTab, setCurrentTab] = useState(tab);
  const termTab = termData[Object.keys(termData)[currentTab]];
  const displayTerm = termTab.slice(item, item + items);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBtn = () => {
    setActive(false);
    setItem(item + items);
    setTimeout(() => {
      setActive(true);
    });
  };

  useEffect(
    () => navigate(`/policy/${joinString(Object.keys(termData)[currentTab])}`),
    []
  );

  const handleTab = () => {
    if (Object.keys(termData).length > currentTab + 1) {
      navigate(`/policy/${joinString(Object.keys(termData)[currentTab + 1])}`);
      setItem(0);
      setCurrentTab(currentTab + 1);
      if (currentTab >= tab) {
        dispatch(setTab(currentTab + 1));
      }
    }
  };

  return (
    <div className="flex gap-3 w-full">
      <TermAside
        Terms={Object.keys(termData)}
        activeTab={currentTab}
        setItem={setItem}
        setCurrentTab={setCurrentTab}
      />
      <div className="p-4 w-[calc(100%-256px)]">
        <div className="p-4 bg-gray-50 h-[calc(100vh-100px)] overflow-auto border-2 border-gray-200 border-dashed rounded-lg">
          <Content displayTerm={displayTerm} />
          <div className="flex justify-between items-center">
            {item !== 0 && (
              <button
                onClick={() => setItem(item - items)}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-black bg-white border border-gray-200 rounded-lg hover:bg-gray-400 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-black"
              >
                <svg
                  className="w-3 h-3 mr-2 rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
                Back
              </button>
            )}
            {!(
              Object.keys(termData).length === currentTab + 1 &&
              item + items >= termTab.length
            ) && (
              <button
                disabled={!active}
                onClick={item + items >= termTab.length ? handleTab : handleBtn}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-700 border border-gray-200 rounded-lg hover:bg-blue-600 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-white disabled:bg-slate-500"
              >
                {item + items >= termTab.length ? "I Agree" : "Next"}
                <svg
                  className="w-3 h-3 ms-2 rotate-270"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </button>
            )}
          </div>
          {Object.keys(termData).length === currentTab + 1 &&
            item + items >= termTab.length && (
              <div className="items-center mb-4">
                <div>
                  <SignatureCanvas
                    penColor="white"
                    canvasProps={{
                      width: 500,
                      height: 200,
                      className: "sigCanvas bg-gray-900 mb-3 mt-5 rounded-lg",
                    }}
                    ref={(ref) => {
                      setSig(ref);
                    }}
                  />
                  <button
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black border mb-4"
                    onClick={() => sig.clear()}
                  >
                    Clear
                  </button>
                </div>
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={agree}
                  onChange={(e) => setAgree(!agree)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 mb-4"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900"
                >
                  I agree to all terms and Conditions
                </label>
                <div>
                  <button
                    disabled={!agree}
                    onClick={() => {
                      //dispatch(setTab(Object.keys(termData)[currentTab].length))
                      emitter.emit("FinishPopUp", [
                        sig?.toDataURL("image/png"),
                      ]);
                    }}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-700 border border-gray-200 rounded-lg hover:bg-blue-600 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-white disabled:bg-slate-500"
                  >
                    Finish
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
