import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategory, editCategory } from "../../../redux/blogsSlice.ts";
import { Loader } from "../../../components/loader/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import AddFormModel from "../../../components/addFormModal/index.js";

export const CategoryInfo = () => {
  const { categoryInfo, isLoading } = useSelector((store) => store.blogs);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    setData(categoryInfo);
  }, [categoryInfo]);

  useEffect(() => {
    dispatch(getCategory(id));
  }, [dispatch, id]);

  if (isLoading?.getCategory) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader w="20" h="20" color="orange" />
      </div>
    );
  }

  const handleAdd = (e) => {
    e.preventDefault();
    const formData = new FormData();

    delete data.slug;
    delete data.created_at;
    delete data.updated_at;
    if (data?.name !== "") {
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
    }

    dispatch(editCategory({ formData: { formData } }, closeModal));
  };

  return (
    <>
    <div className="relative overflow-x-auto h-full">
      <div className="flex justify-between my-3 px-5">
      <div className="text-xl text-orange-500">Category Details</div>
        <AddBtn
          func={openModal}
          mt={5}
          text={"Edit Category"}
        />
        </div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <tbody>
          <tr
            className="bg-white border-b"
          >
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              Category ID
            </th>
            <td className="px-6 py-4">{categoryInfo?.id}</td>
          </tr>
          <tr
            className="bg-white border-b"
          >
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              Name
            </th>
            <td className="px-6 py-4">{categoryInfo?.name}</td>
          </tr>
          <tr
            className="bg-white border-b"
          >
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
            >
              Created At
            </th>
            <td className="px-6 py-4">{categoryInfo?.created_at?.slice(0, 10)}</td>
          </tr>
        </tbody>
      </table>
       
    </div>
      <AddFormModel isOpen={isOpen} closeModal={closeModal} handleAdd={handleAdd} data={data} setData={setData} isLoading={isLoading?.editCategory} name={true} />
    </>
  );
};