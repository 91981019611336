import { useEffect, useState } from "react";
import { UsersList } from "../../../components/usersList.js";
import DraftEditor from "../../../components/DraftEditor";
import { useDispatch, useSelector } from "react-redux";
import { generateEmails } from "../../../redux/vouchers.ts";
import { Loader } from "../../../components/loader/index.js";
import { useNavigate } from "react-router";
import sample from '../../../assets/emailFormat/email_template.csv'
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const CreateEmail = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [draft, setDraft] = useState(null);
  const [userList, setUserList] = useState([]);
  const [data, setData] = useState({submittype: 2});
  const [size, setSize] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isLoading} = useSelector((store) => store.voucher)

  useEffect(() => {
    if (draft !== null) {
      setData({ ...data, body: draft });
    }
  }, [draft]);

  useEffect(() => {
    if (userInfo?.userId !== undefined) {
      setUserList([...userList, userInfo]);
      setData({ ...data, users: [...(data?.users || []), userInfo?.userId] });
    }
  }, [userInfo]);

  const handleDeleteUser = (id) => {
    setUserList(userList?.filter((list) => list?.userId !== id));
    setData({ ...data, users: data?.users?.filter((user) => user !== id) });
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;

    if (files[0]?.size / 1024 > 2048) {
      setSize("Size exceeded limit of 2048 KB!");
    } else {
      setSize("");
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        setData({ ...data, [e.target.name]: file });
      }
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (
      size === "" &&
      data?.body !== "<p></p>\n" &&
      data?.body !== null &&
      (userList?.length !== 0 || userList?.imp_files !== null)
    ) {
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      dispatch(generateEmails({ formData, navigate }));
    }
  };

  return (
    <>
      <Breadcrumbs
				breadcrumbs={[
					{ name: "Emails", link: "/payment-request/all-emails" },
					{ name: "Generate Email", link: "" },
				]}
			/>
      <form
        onSubmit={handleAdd}
        className="flex flex-col mx-12 mb-2 shadow-2xl rounded-lg py-5 h-[calc(100vh-150px)] overflow-auto"
      >
        <div className="flex justify-between items-center mt-5 mx-5">
          <div className="flex flex-col space-y-10">
            <UsersList setUserInfo={setUserInfo} />
            <div className="grid grid-cols-3 items-center gap-2">
              {userList?.map((name, index) => {
                return (
                  <div key={index}>
                    {name && (
                      <div className="bg-gray-100 text-center rounded-lg text-xs py-2 px-1 border-gray-300 border">
                        {name?.userName}
                        <button
                          type="button"
                          onClick={() => handleDeleteUser(name?.userId)}
                          className="ml-3 text-red-600"
                        >
                          x
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mr-40">
            <input
              id="dropzone-file"
              type="file"
              accept=".csv"
              className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-72 p-2.5"
              name="imp_files"
              maxSize={2097152}
              onChange={handleFileChange}
              max={1}
            />
            <label className="text-sm">
              <span className="text-gray-700">Note!</span>
              <div className="w-96 text-gray-500">
                • Please download sample file to get import
                template
                <a href={sample} download={'sample.csv'} className="text-orange-600 ml-5">
                  Download
                </a>{" "}
              </div>
              <div className="w-96 text-gray-500">
                • Make sure all the information is according to the sample data{" "}
              </div>
            </label>
          </div>
        </div>
        <div className="ml-5">
          <label className="text-orange-500 text-lg">
            Email Subject *:
          </label>
          <input
            type="text"
            value={data?.subject}
            onChange={(e) => setData({ ...data, subject: e.target.value })}
            className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-96"
            minLength={10}
            maxLength={255}
            required
          />
        </div>
        <div className="ml-5 mt-5">
          <label className="text-orange-500 text-lg">
            Email Body: *
          </label>
          <div className="bg-transparent border border-black mr-5">
            <DraftEditor onChange={setDraft} />
          </div>
          <div className="text-red-600 text-sm">{data?.body?.length < 300 ? "Enter atleast 300 letters!": null}</div>
        </div>
        <div className="flex justify-between mx-5 mt-5">
          <div>
            <label className="text-orange-500 text-lg">Cupon Code</label>
            <input
              type="text"
              onChange={(e) => {
                setData({ ...data, coupon: e.target.value });
              }}
              value={data?.coupon}
              className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
            />
          </div>
          <div>
            <label className="text-orange-500 text-lg">
              Button Text: *
            </label>
            <input
              type="text"
              onChange={(e) => setData({ ...data, btn_text: e.target.value })}
              value={data?.btn_text}
              className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
              required
            />
          </div>
          <div>
            <label className="text-orange-500 text-lg">
              Button Link: *
            </label>
            <input
              type="link"
              value={data?.btn_link}
              onChange={(e) => setData({ ...data, btn_link: e.target.value })}
              className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
              required
            />
          </div>
        </div>
        <div className="flex justify-between mx-5 mt-5">
          <div>
            <label className="text-orange-500 text-lg">Upload Image</label>
            <input
              id="dropzone-file"
              type="file"
              accept=".jpeg, .jpg, .png, .gif, image/jpeg, image/jpg, image/png"
              className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-72 p-2.5"
              name="importimage"
              onChange={handleFileChange}
              maxSize={2097152} 
              max={1}
            />
            {size && <div className="text-xs text-red-600">{size}</div>}
          </div>
          <div>
            <label className="text-orange-500 text-lg">
              Email Sender Name (Optional){" "}
            </label>
            <input
              type="text"
              value={data?.sender_name}
              onChange={(e) =>
                setData({ ...data, sender_name: e.target.value })
              }
              className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block p-2.5 w-72"
            />
          </div>
          <div className="flex justify-start items-start mr-56">
            {/* <div className="col-start-2 col-span-2 relative inline-flex items-center cursor-pointer mb-3">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  onChange={(e) =>
                    setData({ ...data, submittype: e.target.checked ? 2 : 1 })
                  }
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div> */}
            <button
              type="submit"
              className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-transparent px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
            >
              {isLoading?.generateEmails ? <Loader w="6" h="6" color="blue" />: data?.submittype === 2 ? "Send" : "Draft"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
