import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { RxCross1 } from "react-icons/rx";

export const Modal = ({ children, show, setShow, size }) => {
	return (
		<>
			<Transition className="z-50" appear show={show} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={() => setShow(false)}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black/25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
									<div className="flex min-h-full items-end justify-center w-full p-6 text-center sm:items-center sm:p-0">
										<div
											className={`relative p-4 transform overflow-hidden w-full rounded-lg bg-white text-left shadow-xl transition-all my-8 ${
												size === "lg" ? "max-w-4xl" : "max-w-lg"
											}`}
										>
											<RxCross1
												onClick={() => setShow(false)}
												className="absolute w-5 cursor-pointer h-5 right-6 top-6"
											/>
											{children}
										</div>
									</div>
								</div>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};
