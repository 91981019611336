import { useLocation, Link } from "react-router-dom";
import emitter from "../../services/emitter-service";
import { useSelector } from "react-redux";
import { SideBar } from "../sidebar";

export const Navbar = ({ open, setOpen, token }) => {
  const location = useLocation();
  const { userInfo } = useSelector((store) => store.login);

  return (
    <>
      <nav
        className={`border-gray-200 z-40 fixed top-0 left-0 right-0 bg-gray-100 h-16`}
      >
        <div className=" flex flex-wrap items-center justify-between mx-auto p-4">
          <div className="flex justify-start items-center gap-x-5">
            {!location.pathname.includes("/signin") && (
              <button
                type="button"
                onClick={() => setOpen(!open)}
                className="inline-flex items-center p-2 ms-3 text-sm ring-2 ring-gray-300 text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-gray-300"
              >
                {!open ? (
                  <svg
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            )}
            <Link
              to={'/dashboard'}
              className=" space-x-3 rtl:space-x-reverse"
            >
              <img
                src="https://web.procurementleague.com/static/media/new_logo.b664e6f2.svg"
                className="h-8"
                alt="Pl Logo"
              />
            </Link>
          </div>
          <div className="flex justify-center items-center">
            <button
              data-collapse-toggle="navbar-solid-bg"
              type="button"
              className=" items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-solid-bg"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
            <div
              className="flex-row w-full"
              id="navbar-solid-bg"
            >
              <ul className="flex flex-col font-medium mt-0 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:border-0 md:bg-transparent ">
                {!location.pathname.includes("/signin") && (
                  <li>
                    <div className="hidden lg:block py-2 px-3 md:p-0 text-orange-500 bg-orange-500 rounded md:bg-transparent md:text-orange-500">
                      {`Welcome ${userInfo?.firstname} ${userInfo?.lastname} !`}
                      <span className="text-green-600 ml-2 text-sm">
                        (
                        {userInfo?.ability === "Super-Admin"
                          ? "Super Admin"
                          : "Admin"}
                        )
                      </span>
                    </div>
                  </li>
                )}
                <li>
                  {!location.pathname.includes("/signin") && (
                    <Link
                      to="/policy"
                      className={
                        location.pathname.includes("/policy")
                          ? "hidden lg:block py-2 px-3  md:p-0 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700"
                          : "hidden lg:block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700"
                      }
                    >
                      Terms & Conditions
                    </Link>
                  )}
                </li>
                <li>
                  <button
                    onClick={() =>
                      location.pathname !== "/signin"
                        ? emitter.emit("SignOutPopUp")
                        : null
                    }
                    className={
                      location.pathname === "/signin"
                        ? "block py-2 px-3 text-blue-500 rounded bg-transparent"
                        : "block py-2 px-3 md:p-0 text-gray-900 rounded md:border-0 hover:text-red-500"
                    }
                  >
                    {location.pathname.includes("/signin")
                      ? ""
                      : "Log out"}
                  </button>
                </li>
              </ul>
            </div>
            {!location.pathname.includes("/signin") && (
              <div
                to="/"
                className="ml-7 flex items-center space-x-3 rtl:space-x-reverse"
              >
                <img src={userInfo?.profile_photo} className="h-10 w-10 rounded-full" alt="Profile" />
              </div>
            )}
          </div>
        </div>
      </nav>
      <SideBar open={open} token={token} />
    </>
  );
};
