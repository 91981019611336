import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../../components/pagination/index.js";
import { Table } from "../../../components/table/index.jsx";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { fetchPostedContents } from "../../../redux/influencersSlice.ts";
import { removeEmptyString } from "../../../helper/removeEmpty.js";

export const PostedContent = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { contents, isLoading } = useSelector((store) => store.influencer);
	const [param, setParam] = useState({ first: 12, page: 1 });

	useEffect(() => {
		dispatch(fetchPostedContents(removeEmptyString({ ...param, page: 1 })));
	}, [param, dispatch]);

	function handlePage(page) {
		setParam({ ...param, page });
	}

	const setSearch = debounce((value) => {
		setParam({ ...param, title: value });
	}, 1500);

	const handleView = (id) => navigate(`/influencers/posted-content/${id}`);

	const tableData = [
		{ type: "fullName", to: ``, nested: true },
		{ key: "Title", value: "title" },
		{ key: "Type", value: "type" },
		{ key: "Current Status", value: "status", type: "contentStatus" },
		{ key: "Last Updated", value: "updated_at", type: "date" },
		{ key: "Posted On", value: "created_at", type: "date" },
	];

	const actions = [{ type: "view", func: handleView, isLoading: false }];

	return (
		<>
			<Breadcrumbs breadcrumbs={[{ name: "Posted Content", link: "" }]} />
			<div className="relative px-12">
				<div className="flex justify-start items-center space-x-10 my-2">
					<div className="flex flex-col min-w-[300px]">
						<label className="font-light">Content Title</label>
						<input
							onChange={(e) => {
								const inputValue = e.target.value;
								setSearch.cancel();
								setSearch(inputValue);
							}}
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						/>
					</div>
					<div className="flex flex-col min-w-[300px]">
						<label className="font-light">Status</label>
						<select
							onChange={(e) => setParam({ ...param, status: e.target.value })}
							type="select"
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						>
							<option value={""}>Select</option>
							<option value={0}>To Review</option>
							<option value={1}>Published</option>
						</select>
					</div>
					<div className="flex flex-col min-w-[300px]">
						<label className="font-light">Content Type</label>
						<select
							onChange={(e) => setParam({ ...param, type: e.target.value })}
							type="select"
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						>
							<option value={""}>Select</option>
							<option>White Paper</option>
							<option>Case Study</option>
							<option>Blog</option>
							<option>Video</option>
						</select>
					</div>

				</div>
				<div className="h-[calc(100vh-270px)] overflow-auto hide-scroll">
					<Table
						data={tableData}
						table={contents}
						actions={actions}
						isLoading={isLoading?.contents}
					/>
				</div>
				<Pagination
					total={contents?.last_page}
					current={contents?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
